import { ChangeEvent, FC, MouseEventHandler, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';

import { Spacing, Theme, getScrollStyles, getSizeRem } from '../../Theme';
import { MultiSelectOptions, TagsGroup, TextField, Icon, TagColorVariant, Text } from '@/components/Core';

type Option = { id: string | number; name: string };
export interface MultiSelectProps {
  // array with ids
  value: string[];
  setValue: (value: string[]) => void;
  options: Option[];
  placeholder?: string;
  hasSelectAll?: boolean;
  hasOptionsFilter?: boolean;
  isOpen: boolean;
  children?: ReactNode;
  tagsTopSpace?: Spacing;
  gap?: Spacing;
  isAccordion?: boolean;
  tagColor?: ((id: string | number) => TagColorVariant) | TagColorVariant;
  hasTags?: boolean;
  hasUncheck?: boolean;
  py?: Spacing;
  optionsMaxHeight?: number;
  pyLabel?: Spacing;
}
const getStylesAccordion = (isOpen: boolean, theme: Theme) => {
  return `
  overflow: hidden;
  transition: all ${theme.transitionOptions};
  border: 1px solid;
  ${
    isOpen
      ? `max-height: 100vh; border-color: ${theme.palette.primary[50]}; overflow: auto`
      : 'max-height: 0; border-color: transparent'
  };
  padding-inline: ${getSizeRem(theme.sizes.s)};
  border-top: none;
  border-bottom-left-radius: ${getSizeRem(theme.sizes.xxs)};
  border-bottom-right-radius: ${getSizeRem(theme.sizes.xxs)};
  ${getScrollStyles(theme)}
  `;
};
const StyledWrapper = styled.div<{ isOpen: boolean; isAccordion: boolean }>(
  ({ theme, isOpen, isAccordion }) => `
  width: 100%;
  ${isAccordion ? getStylesAccordion(isOpen, theme) : ''}
`
);

const DELAY_FILTER = 500;

export const MultiSelect: FC<MultiSelectProps> = ({
  value,
  setValue,
  options,
  hasSelectAll = true,
  hasOptionsFilter = false,
  isOpen,
  placeholder,
  children = null,
  tagsTopSpace = 's',
  gap,
  isAccordion = true,
  tagColor = 'primary25',
  hasTags = true,
  hasUncheck = true,
  py = 's',
  optionsMaxHeight,
  pyLabel,
}) => {
  const [selectedValue, setSelectedValue] = useState<string[]>([]);
  const [filter, setFilter] = useState('');
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const selectedOptions = useMemo(
    () => options.filter((el) => selectedValue.includes(el.id.toString())),
    [options, selectedValue]
  );

  const visibleOptions = useMemo(
    () => options.filter((el) => el.name.toLowerCase().includes(filter)),
    [filter, options]
  );

  const isAllSelected = visibleOptions.every((el) => selectedValue.includes(el.id.toString()));

  const handleCheck = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    if (checked) {
      setValue([...selectedValue, value]);
    } else {
      setValue(selectedValue.filter((el) => el !== value));
    }
  };

  const handleUncheck = (id: string) => {
    setValue(selectedValue.filter((el) => el !== id));
  };

  const handleToggle = (value: boolean) => {
    if (value) {
      const select = visibleOptions.filter((option) => !selectedValue.includes(option.id.toString()));
      const selectIds = select.map((el) => el.id.toString());
      setValue([...selectedValue, ...selectIds]);
    } else {
      const uncheckedIds = visibleOptions.map((el) => el.id.toString());
      const checkedIds = selectedValue.filter((id) => !uncheckedIds.includes(id));
      setValue(checkedIds);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFilter = useCallback(
    debounce(async (value: string) => {
      setFilter(value);
    }, DELAY_FILTER),
    []
  );
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputValue(value);
    debouncedFilter(value.toLowerCase());
  };

  const handleClear: MouseEventHandler = (event) => {
    event.stopPropagation();
    if (!inputValue) return;
    setFilter('');
    setInputValue('');
  };

  return (
    <>
      <StyledWrapper isOpen={isOpen} isAccordion={isAccordion}>
        {children}
        {hasOptionsFilter && (
          <TextField
            name="filter"
            value={inputValue}
            onChange={handleChange}
            placeholder={placeholder}
            variant="filled"
            py="s"
            px="xs"
            startAdornment={<Icon type="search" size="m" color="grey300" />}
            endAdornment={
              <span onClick={handleClear} hidden={!inputValue}>
                <Icon type="close" size="m" />
              </span>
            }
          />
        )}
        {!!options.length && (
          <MultiSelectOptions
            selectedValue={selectedValue}
            options={visibleOptions}
            handleCheck={handleCheck}
            handleToggle={handleToggle}
            isAllSelected={isAllSelected}
            hasSelectAll={hasSelectAll}
            py={py}
            maxHeight={optionsMaxHeight}
            pyLabel={pyLabel}
          />
        )}
        {filter && !visibleOptions.length && (
          <Text className="py-m" color="secondary">
            {t('noMatchesFound')}
          </Text>
        )}
      </StyledWrapper>
      {hasTags && !!selectedOptions.length && (
        <TagsGroup
          options={selectedOptions}
          onClick={handleUncheck}
          gap={gap}
          tagsTopSpace={tagsTopSpace}
          tagColor={tagColor}
          hasClose={hasUncheck}
        />
      )}
    </>
  );
};
