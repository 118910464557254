import axios, { AxiosError } from 'axios';

import { reduxStore, userActions, userSelectors } from '@/store';
import { clearLocalStorage } from '@/store/slices/user/thunks';
import { toastActions } from '@/store/slices/toast';
import { ToastVariantBasic } from '@/components/Core';
import { AxiosConfigWithoutToasts } from '@/api';

const headers: Record<string, string> = {
  'Content-Type': 'application/json; charset=utf-8',
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers,
});

instance.interceptors.request.use(async function (config) {
  const token = userSelectors.getToken(reduxStore.getState());
  if (token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    config.headers = { ...config.headers, ...headers };
  }

  return config;
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error: AxiosError) {
    const axiosConfig = error?.config as AxiosConfigWithoutToasts;
    if (error.response) {
      switch (error.response.status) {
        case 401:
        case 403:
          clearLocalStorage();
          reduxStore.dispatch(userActions.logout());
          break;
      }
    } else if (error.message === 'Network Error') {
      if (!axiosConfig.disableToast)
        reduxStore.dispatch(
          toastActions.create({
            type: ToastVariantBasic.NETWORK_ERROR,
          })
        );
    } else if (error.code !== 'ERR_CANCELED') {
      if (!axiosConfig.disableToast)
        reduxStore.dispatch(
          toastActions.create({
            type: ToastVariantBasic.UNKNOWN_ERROR,
          })
        );
    }
    return Promise.reject(error);
  }
);

export default instance;
