import { CSSProperties, FC, ReactElement, ReactNode, memo } from 'react';
import styled from '@emotion/styled/macro';

import { FontSizes, Theme, getSizeRem } from '../Theme';

export type TooltipsPositions =
  | 'bottom-center'
  | 'bottom-left'
  | 'bottom-right'
  | 'right-center'
  | 'left-center'
  | 'top-left';
type TooltipVariant = 'dark' | 'light';

export interface TooltipProps {
  position: TooltipsPositions;
  title: string | ReactNode;
  counter?: string;
  display?: CSSProperties['display'];
  children?: ReactElement;
  isVisible?: boolean;
  variant?: TooltipVariant;
  fontSize?: FontSizes;
}

const getPositionStyles = (
  position: TooltipsPositions = 'bottom-left',
  theme: Theme,
  variant: TooltipVariant | undefined
) => {
  const arrowBase = `${theme.sizes.base / 2}px solid`;
  const borderColor = variant === 'light' ? theme.palette.white : theme.palette.grey[600];
  switch (position) {
    case 'bottom-center':
      return `
        top: 100%;
        left: 50%;
        transform: translate(-50%, ${theme.sizes.base / 2}px);

        & .arrow {
          top: -${theme.sizes.base / 2 - 1}px;
          left: 50%;
          transform: translateX(-50%);
          border-left: ${arrowBase} transparent;
          border-right: ${arrowBase} transparent;
          border-bottom: ${arrowBase} ${borderColor};
          border-top: none;
        }
      `;
    case 'bottom-left':
      return `
        top: 100%;
        left: 0;
        transform: translate(0%, ${theme.sizes.base / 2}px);

        & .arrow {
          top: -${theme.sizes.base / 2 - 1}px;
          left:  ${theme.sizes.s}px;
          border-left: ${arrowBase} transparent;
          border-right: ${arrowBase} transparent;
          border-bottom: ${arrowBase} ${borderColor};
          border-top: none;
        }
      `;
    case 'bottom-right':
      return `
        top: 100%;
        right: 0;
        transform: translate(0%, ${theme.sizes.base / 2}px);

        & .arrow {
          top: -${theme.sizes.base / 2 - 1}px;
          right:  ${theme.sizes.s}px;
          border-left: ${arrowBase} transparent;
          border-right: ${arrowBase} transparent;
          border-bottom: ${arrowBase} ${borderColor};
          border-top: none;
        }
      `;
    case 'right-center':
      return `
        top: 50%;
        left: 100%;
        transform: translate(${theme.sizes.base / 2}px, -50%);

        & .arrow {
          left: -${theme.sizes.base / 2 - 2}px;
          top:  50%;
          transform: translateY(-50%);
          border-top: ${arrowBase} transparent;
          border-bottom: ${arrowBase} transparent;
          border-right: ${arrowBase} ${borderColor};
          border-left: none;
        }
      `;
    case 'left-center':
      return `
        top: 50%;
        right: 100%;
        transform: translate(-${theme.sizes.base / 2}px, -50%);

        & .arrow {
          right: -${theme.sizes.base / 2 - 2}px;
          top:  50%;
          transform: translateY(-50%);
          border-top: ${arrowBase} transparent;
          border-bottom: ${arrowBase} transparent;
          border-left: ${arrowBase} ${borderColor};
          border-right: none;
        }
      `;
    case 'top-left':
      return `
        bottom: 100%;
        left: 0;
        transform: translateY(-${theme.sizes.base / 2}px);

        & .arrow {
          bottom: -${theme.sizes.base / 2 - 1}px;
          left:  ${theme.sizes.s}px;
          border-left: ${arrowBase} transparent;
          border-right: ${arrowBase} transparent;
          border-top: ${arrowBase} ${borderColor};
          border-bottom: none;
        }
        `;
  }
};

const getColorStyles = (theme: Theme, variant: TooltipVariant | undefined) => {
  switch (variant) {
    case 'light':
      return `
      color: ${theme.palette.grey[900]};
      background-color: ${theme.palette.white};
      box-shadow: 0px 0px 6px 0px #00000040;
      &:after {
        background-color: ${theme.palette.white};
      }
      `;
    default:
      return `
      color: ${theme.palette.white};
      background-color: ${theme.palette.grey[600]};
      &:after {
        background-color: ${theme.palette.grey[700]};
      }
  `;
  }
};

const getSizeStyles = (theme: Theme) => `
    gap: ${theme.sizes.xs}px;
    border-radius: ${theme.sizes.xxs}px;
    padding: ${theme.sizes.xs}px;
`;

const getCounterStyles = (counter: string, theme: Theme) => `
    &:after {
      content: '${counter}';
      display: inline-block;
      border-radius: ${theme.sizes.xxs}px;
      padding: ${theme.sizes.xxs}px ${theme.sizes.xs}px;
      font-size: ${getSizeRem(theme.fontSizes.xs)};
    }
`;

const Wrapper = styled.div<Pick<TooltipProps, 'display'>>(
  ({ display }) => `
      position: relative;
      display: ${display};

      &:hover ${StyledSpan} {
        display: flex;
      }
`
);

const StyledSpan = styled.span<Omit<TooltipProps, 'children' | 'title'>>(
  ({ position, counter, theme, isVisible, variant, fontSize = 'm' }) => `
    display: ${isVisible ? 'flex' : 'none'};
    z-index: 100;
    position: absolute;
    align-items: center;
    width: max-content;
    max-width: 300px;
    animation: fadeIn 0.5s ease-out forwards;
    font-size: ${getSizeRem(theme.fontSizes[fontSize])};


    ${getSizeStyles(theme)}
    ${getPositionStyles(position, theme, variant)}
    ${getColorStyles(theme, variant)}
    ${counter ? getCounterStyles(counter, theme) : ''}

    & .arrow {
      position: absolute;
      width: 0;
      height: 0;
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 0.9;
      }
    }
  `
);

export const Tooltip: FC<TooltipProps> = memo(function Tooltip({
  title,
  display = 'inline-block',
  children,
  ...props
}) {
  return (
    <Wrapper display={display}>
      {children}
      <StyledSpan {...props}>
        <span className="arrow" />
        {title}
      </StyledSpan>
    </Wrapper>
  );
});
