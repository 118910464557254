import { ToastConfig, ToastVariant, ToastVariantBasic, ToastVariantWithText } from './types';

const DEFAULT_TOAST_DURATION = 5000;

export const ToastConfigs = new Map<ToastVariant, ToastConfig>([
  [
    ToastVariantBasic.NETWORK_ERROR,
    {
      icon: 'alert',
      color: 'error',
      disabledClose: false,
      duration: DEFAULT_TOAST_DURATION,
    },
  ],
  [
    ToastVariantBasic.UNKNOWN_ERROR,
    {
      icon: 'alert',
      color: 'error',
      disabledClose: false,
      duration: DEFAULT_TOAST_DURATION,
    },
  ],
  [
    ToastVariantWithText.SUCCESS_ACTION,
    {
      icon: 'check-circle',
      color: 'success',
      disabledClose: false,
      duration: DEFAULT_TOAST_DURATION,
    },
  ],
  [
    ToastVariantWithText.WARNING_ACTION,
    {
      icon: 'alert',
      color: 'warning',
      disabledClose: false,
      duration: DEFAULT_TOAST_DURATION,
    },
  ],
  [
    ToastVariantWithText.ERROR_ACTION,
    {
      icon: 'alert',
      color: 'error',
      disabledClose: false,
      duration: DEFAULT_TOAST_DURATION,
    },
  ],
  [
    ToastVariantBasic.NEW_VERSION,
    {
      icon: 'beta',
      color: 'primary',
      disabledClose: false,
      align: 'flex-start',
    },
  ],
  [
    ToastVariantBasic.SOCKET_DISCONNECT,
    {
      icon: 'wifi-off',
      color: 'error',
      disabledClose: false,
      align: 'flex-start',
    },
  ],
  [
    ToastVariantBasic.SOCKET_RECONNECT,
    {
      icon: 'wifi',
      color: 'success',
      disabledClose: false,
      duration: DEFAULT_TOAST_DURATION,
      align: 'flex-start',
    },
  ],
]);
