import { Global, css } from '@emotion/react';

import { toCapitalize } from '@/utils/toCapitalize';
import { useAppTheme } from '@/hooks';
import { FLYOUT_CLASS_ORDER } from '@/utils/constants';

import { SPACING, Spacing, Theme } from './Core';

export const GlobalStyle = () => {
  const theme = useAppTheme();
  const spacingStyles = SPACING.reduce(
    (styles, size) => ({
      ...styles,
      ...getSpaceStyleObject(size, theme, '.m', 'margin'),
      ...getSpaceStyleObject(size, theme, '.p', 'padding'),
    }),
    {}
  );
  return (
    <>
      <Global
        styles={css`
          body {
            background-color: ${theme.palette.white};
          }

          .leaflet-fullscreen {
            position: fixed !important;
            z-index: 99999;
            top: 0;
            left: 0;
          }
          .leaflet-top.leaflet-left .leaflet-custom-control-container {
            display: flex;
            gap: 10px;

            @media (max-width: 1180px) {
              flex-direction: column;
            }
          }
          .leaflet-label-marker {
            z-index: 999 !important;
          }
          .leaflet-control-point_config_open .leaflet-bottom.leaflet-left {
            z-index: 1001;
          }
          .leaflet-bottom.leaflet-left {
            margin-bottom: 16.8px;
          }
          .leaflet-control-offset {
            & {
              ${FLYOUT_CLASS_ORDER.map((num) => `&_left_${num} .leaflet-control-container .leaflet-left`).join(',')} {
                ${theme.mediaRules.up(theme.breakpoints.sm)} {
                  left: 300px;
                }
                @media print {
                  & {
                    left: 0px;
                  }
                }
              }
              ${FLYOUT_CLASS_ORDER.map((num) => `&_right_${num} .leaflet-control-container .leaflet-right`).join(',')} {
                ${theme.mediaRules.up(theme.breakpoints.sm)} {
                  right: 300px;
                }

                @media print {
                  & {
                    right: 0px;
                  }
                }
              }
            }
          }

          @keyframes pulse-icon {
            50% {
              opacity: 1;
              fill-opacity: 0.2;
            }
          }

          .icon-pulse {
            animation: pulse-icon 2s infinite ease-in-out;
          }
          /* END PULSE MAP NEW POINT */
        `}
      />
      <Global
        styles={{
          '*': {
            boxSizing: 'border-box',
            padding: 0,
            margin: 0,
          },
          body: {
            lineHeight: 1.2,
            color: theme.palette.grey[900],
            fontFamily: "'Nunito Sans', sans-serif;",
          },
          a: {
            color: 'inherit',
            textDecoration: 'none',
          },
          '.leaflet-custom-control-container': {
            lineHeight: 1.2,
            fontFamily: "'Montserrat', sans-serif;",
          },
          ...spacingStyles,
        }}
      />
    </>
  );
};

const getSpaceStyleObject = (size: Spacing, theme: Theme, prefix: string, baseProperty: string) =>
  SPACE_VARIANTS.reduce(
    (styles, variant) => ({
      ...styles,
      [`${prefix}${variant.class}-${size}`]: {
        [`${baseProperty}${toCapitalize(variant.property)}`]: theme.sizes[size],
      },
    }),
    {}
  );

const SPACE_VARIANTS = [
  {
    class: 'l',
    property: 'left',
  },
  {
    class: 'r',
    property: 'right',
  },
  {
    class: 't',
    property: 'top',
  },
  {
    class: 'b',
    property: 'bottom',
  },
  {
    class: 'x',
    property: 'inline',
  },
  {
    class: 'y',
    property: 'block',
  },
];
