import { css } from '@emotion/css';
import { clsx } from 'clsx';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { FlexGroup, Icon, Text, ToastVariantWithText } from '@/components/Core';
import { useDispatch, useSelector, userActions, userSelectors } from '@/store';
import { WelcomeLayout } from '@/components/Layouts/WelcomeLayout';
import { useHideLoader, useIsMobile, useTitle, useWindowSize } from '@/hooks';
import { toastActions } from '@/store/slices/toast';

type SignInParams = {
  inviteToken?: string;
};

export default function SignIn() {
  useHideLoader();
  const { t, i18n } = useTitle('page.signIn.title');

  const { inviteToken } = useParams<SignInParams>();
  const isMobile = useIsMobile();
  const { width } = useWindowSize();

  const errorMessage = useSelector(userSelectors.getError);

  const dispatch = useDispatch();
  const handleLogin = useCallback(
    (response: CredentialResponse) => {
      const { credential } = response;
      if (credential) {
        const data: Payloads.Login = {
          token: credential,
        };
        if (inviteToken) {
          data.invite_token = inviteToken;
        }
        dispatch(userActions.loginAsync(data));
      }
    },
    [dispatch, inviteToken]
  );

  return (
    <WelcomeLayout>
      <div>
        <Text size="xl" weight="bold" element="h1" className="mb-s" align={isMobile ? 'center' : 'start'}>
          {t('page.signIn.title')}
        </Text>
        <Text
          className={clsx(
            'mb-l',
            css(`
              max-width: 380px;
            `)
          )}
        >
          {t('page.signIn.description')}
        </Text>
        <GoogleLogin
          locale="uk"
          onSuccess={handleLogin}
          onError={() => {
            dispatch(
              toastActions.create({
                type: ToastVariantWithText.ERROR_ACTION,
                text: t('toast.content.invalid_authentication_with_google'),
              })
            );
          }}
          text={inviteToken ? 'signup_with' : 'continue_with'}
          width={isMobile ? width - 84 : 380}
          size="medium"
          theme="filled_blue"
        />
        {!!errorMessage && i18n.exists(`errorMessages.${errorMessage}`) && (
          <FlexGroup gap="xxs" className="mt-m" align="center">
            <Icon type="info-circle" color="error" size="m" />
            <Text>{t(`errorMessages.${errorMessage}`)}</Text>
          </FlexGroup>
        )}
      </div>
    </WelcomeLayout>
  );
}
