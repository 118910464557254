import { createBrowserRouter, RouteObject } from 'react-router-dom';
import { wrapCreateBrowserRouter } from '@sentry/react';

import { Dashboard } from '@/pages/dashboard';
import { SignIn } from '@/pages/sign-in';
import { NotFound } from '@/pages/not-found';
import { SignOut } from '@/pages/sign-out';
import { AuthRequired } from '@/components/Layouts/AuthRequired';
import { AuthenticationLayout } from '@/components/Layouts/AuthenticationLayout';
import { Users } from '@/pages/users';
import { EventsMap } from '@/pages/events-map';
import { UserDetails } from '@/pages/user-details';
import { Devices } from '@/pages/devices';
import { DeviceDetails } from '@/pages/device-details';
import { DevicesMap } from '@/pages/devices-map';
import { Invites } from '@/pages/invites';
import { RegionsMap } from '@/pages/regions-map';
import { Demo } from '@/pages/demo';
import { ErrorElement } from '@/components/ErrorFallback';
import { PermissionRequired } from '@/components/PermissionRequired';
import { PermissionDenied } from '@/components/PermissionDenied';
import { Role } from '@/hooks/usePermission';
import { DeviceManagers } from '@/pages/device-managers';
import { PredictionsMap } from '@/pages/predictions-map';

import { Routes } from './types';

const routes: RouteObject[] = [
  {
    path: Routes.HOME,
    element: <AuthRequired />,
    errorElement: <ErrorElement />,
    children: [
      {
        path: '',
        element: <Dashboard />,
      },
      {
        path: Routes.MAP,
        element: (
          <PermissionRequired
            roles={[Role.ADMIN, Role.REALTIME, Role.MODERATOR, Role.SUB_MODERATOR, Role.VERIFICATOR]}
            fallback={<PermissionDenied />}
          >
            <EventsMap />
          </PermissionRequired>
        ),
      },
      {
        path: Routes.DEVICES_MAP,
        element: (
          <PermissionRequired roles={[Role.ADMIN, Role.SOUND_MASTER, Role.NETWORK]} fallback={<PermissionDenied />}>
            <DevicesMap />
          </PermissionRequired>
        ),
      },
      {
        path: Routes.DEVICES,
        element: (
          <PermissionRequired roles={[Role.ADMIN, Role.SOUND_MASTER, Role.NETWORK]} fallback={<PermissionDenied />}>
            <Devices />
          </PermissionRequired>
        ),
      },
      {
        path: Routes.DEVICE_DETAILS,
        element: (
          <PermissionRequired roles={[Role.ADMIN, Role.SOUND_MASTER, Role.NETWORK]} fallback={<PermissionDenied />}>
            <DeviceDetails />
          </PermissionRequired>
        ),
      },
      {
        path: Routes.USERS,
        element: (
          <PermissionRequired roles={[Role.ADMIN]} fallback={<PermissionDenied />}>
            <Users />
          </PermissionRequired>
        ),
      },
      {
        path: Routes.DEVICE_MANAGERS,
        element: (
          <PermissionRequired roles={[Role.ADMIN, Role.NETWORK]} fallback={<PermissionDenied />}>
            <DeviceManagers />
          </PermissionRequired>
        ),
      },
      {
        path: Routes.USER_DETAILS,
        element: (
          <PermissionRequired currentUser roles={[Role.ADMIN, Role.NETWORK]} fallback={<PermissionDenied />}>
            <UserDetails />
          </PermissionRequired>
        ),
      },
      {
        path: Routes.INVITES,
        element: <Invites />,
      },
      {
        path: Routes.REGIONS,
        element: (
          <PermissionRequired roles={[Role.ADMIN]} fallback={<PermissionDenied />}>
            <RegionsMap />
          </PermissionRequired>
        ),
      },
      {
        path: Routes.DEMO,
        element: (
          <PermissionRequired roles={[Role.ADMIN, Role.DEMO]} fallback={<PermissionDenied />}>
            <Demo />
          </PermissionRequired>
        ),
      },
      {
        path: Routes.PREDICTIONS,
        element: (
          <PermissionRequired roles={[Role.ADMIN, Role.COMMAND_POST]} fallback={<PermissionDenied />}>
            <PredictionsMap />
          </PermissionRequired>
        ),
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
  {
    path: Routes.SIGN_IN,
    element: (
      <AuthenticationLayout>
        <SignIn />
      </AuthenticationLayout>
    ),
    children: [
      {
        path: Routes.SIGN_UP,
      },
    ],
  },
  {
    path: Routes.SIGN_OUT,
    element: <SignOut />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter(routes);
