import styled from '@emotion/styled';
import { FC } from 'react';

import { ColorVariant, Theme, getSizeRem } from '../Theme';

export interface LineProps {
  className?: string;
  color?: ColorVariant | string;
  width?: number;
}
const getColorStyles = (color: ColorVariant | string, theme: Theme) => {
  switch (color) {
    case 'primary':
      return `
      background-color: ${theme.palette.primary[25]};
      border-color: ${theme.palette.primary[25]};
      `;
    case 'default':
      return `
      background-color: currentColor; 
      border-color: currentColor;
      `;
    case 'secondary':
      return `
      background-color: ${theme.palette.grey[25]};
      border-color: ${theme.palette.grey[25]};
      `;
    default:
      return `
      background-color: ${color};
      border-color: ${color};
      `;
  }
};

const StyledSpan = styled.span<LineProps>(
  ({ color = 'default', theme, width }) => `
    display: block;
    ${getColorStyles(color, theme)};
    width: ${width ? getSizeRem(width) : '100%'};
    height: ${getSizeRem(1)};
    border-bottom-width: ${getSizeRem(1)};
    border-top-width: 0;
    border-style: solid;
    overflow: hidden;
  `
);

export const Line: FC<LineProps> = (props) => <StyledSpan {...props} />;
