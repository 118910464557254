import { createSelector } from '@reduxjs/toolkit';

import type { ReduxState } from '../../store';

export const getPoints = (state: ReduxState) => state.realtime.points;
export const getLandPoints = (state: ReduxState) => state.realtime.land;
export const getHeartBeats = (state: ReduxState) => state.realtime.heartbeats;
export const getEllipses = (state: ReduxState) => state.realtime.ellipses;
export const getSocketParams = (state: ReduxState) => state.realtime.socketQueryParams;
export const getStatistic = (state: ReduxState) => state.realtime.statistic;
const getTrackStatuses = (state: ReduxState) => state.realtime.trackStatuses;

export const getTrackStatusByLabel = (track_label: number): ((state: ReduxState) => Types.TrackStatus | null) =>
  createSelector([getTrackStatuses], (items) => {
    const trackStatus = Array.from(items.values()).find((item) => track_label === item.track_label);
    if (trackStatus) return trackStatus;

    return null;
  });
