import styled from '@emotion/styled';
import { FC } from 'react';

import { Theme, getSizeRem } from '../Theme';

type ToggleSizes = 's' | 'l';

export interface ToggleProps {
  disabled?: boolean;
  size?: ToggleSizes;
  value?: boolean;
  onChange?: (state: boolean) => void;
}

const getSizeStyles = (size: ToggleSizes, isActive: boolean, theme: Theme) => {
  const borderWidth = 2;
  switch (size) {
    case 's': {
      return `
        border-radius: ${getSizeRem(theme.sizes.m)};
        height:  ${getSizeRem(theme.sizes.m + 2)};
        width: ${getSizeRem(theme.sizes.m * 2)};

        &:before {
            height:  ${getSizeRem(theme.sizes.m - borderWidth * 2)};
            width: ${getSizeRem(theme.sizes.m - borderWidth * 2)};
            top: ${getSizeRem(borderWidth)};
            ${
              isActive
                ? `
                  left: calc( 100% - ${theme.sizes.m - borderWidth}px );
                  `
                : `
                  left: ${borderWidth}px;
                  `
            }
        }
      `;
    }
    case 'l': {
      return `
        border-radius: ${getSizeRem(theme.sizes.base)};
        height:  ${getSizeRem(theme.sizes.base + 2)};
        width: ${getSizeRem(theme.sizes.base * 2)};

        &:before{
            height:  ${getSizeRem(theme.sizes.base - borderWidth * 2)};
            width: ${getSizeRem(theme.sizes.base - borderWidth * 2)};
            top: ${getSizeRem(borderWidth)};

            ${
              isActive
                ? `
                left: calc( 100% - ${theme.sizes.base - borderWidth}px );
                `
                : `
                left: ${borderWidth}px;
                `
            }
        }
      `;
    }
  }
};

const StyledToggle = styled.button<
  Pick<ToggleProps, 'size'> & {
    isActive: boolean;
  }
>`
  ${({ size = 's', isActive = false, theme }) => `
    cursor: pointer;
    position: relative;
    border: 1px solid;
    outline: none;
    transition: background-color ${theme.transitionOptions};
    ${getSizeStyles(size, isActive, theme)}

    ${
      isActive
        ? `
        background-color: ${theme.palette.white};
        border-color: ${theme.palette.primary[600]};
    `
        : `
        background-color: ${theme.palette.white};
        border-color: ${theme.palette.grey[100]};
    `
    }

    &:focus-visible {
        box-shadow: ${theme.palette.primary[900]} 0px 0px 1px 1px;
    }

    &:before {
        position: absolute;
        content: '';
        border-radius: 50%;
        display: block;
        transition: left ${theme.transitionOptions};
        ${
          isActive
            ? `
            background-color: ${theme.palette.primary[600]};
        `
            : `
            background-color: ${theme.palette.primary[25]};
        `
        }
    }
  `}
`;

export const Toggle: FC<ToggleProps> = ({ disabled, value, onChange, ...props }) => {
  const handleClick = () => {
    if (onChange) onChange(!value);
  };
  return <StyledToggle {...props} disabled={disabled} isActive={!!value} onClick={handleClick} />;
};
