import { FC } from 'react';
import styled from '@emotion/styled';

import { getSizeRem } from '../Theme';

export interface CounterProps {
  isVisible: boolean;
  text: number | string;
}

const StyledSpan = styled.span<{ isVisible: boolean }>(
  ({ theme, isVisible }) => `
    outline: 1px solid ${isVisible ? theme.palette.primary[50] : 'transparent'};
    border-radius: ${getSizeRem(theme.sizes.xxs)};
    background-color: ${isVisible ? theme.palette.primary[25] : 'transparent'};
    color: ${isVisible ? theme.palette.grey[500] : 'transparent'};
    padding-inline: ${getSizeRem(theme.sizes.xxs)};
  `
);

export const Counter: FC<CounterProps> = ({ text, isVisible }) => <StyledSpan isVisible={isVisible}>{text}</StyledSpan>;
