import { ROLES } from '@/utils/constants';

import { FieldBorderVariant, Theme } from './types';

export const getSizeRem = (size: number) => `${size / 16}rem`;

export const getBorderStylesByVariant = (
  variant: FieldBorderVariant,
  theme: Theme,
  isWrapper: boolean,
  isError = false
) => {
  switch (variant) {
    case 'outlined':
      return `
        border: 1px solid ${isError ? theme.palette.error[100] : theme.palette.primary[50]};
        border-radius: ${getSizeRem(theme.sizes.xxs)};
        &:focus-${isWrapper ? 'within' : 'visible'} {
          color: ${isError ? theme.palette.error[400] : theme.palette.grey[900]};
          border-color: ${isError ? theme.palette.error[100] : theme.palette.primary[200]};
          box-shadow: ${theme.palette.primary[800] + '0F'} 0px -2px 4px -2px, 
          ${theme.palette.primary[800] + '1A'} 0px 4px 8px -2px;
      }
        `;
    case 'filled':
      return `
        border: none;
        border-bottom: 1px solid ${isError ? theme.palette.error[100] : theme.palette.primary[50]};
        border-radius: ${getSizeRem(theme.sizes.none)};
        &:focus-${isWrapper ? 'within' : 'visible'} {
          color: ${isError ? theme.palette.error[400] : theme.palette.grey[900]};
          border-color: ${isError ? theme.palette.error[100] : theme.palette.grey[900]};
      }
        `;
    default:
      return `
        border: none;
        border-bottom: 1px solid ${theme.palette.primary[50]};
        border-radius: ${getSizeRem(theme.sizes.none)};
        &:focus-${isWrapper ? 'within' : 'visible'} {
          color: ${isError ? theme.palette.error[400] : theme.palette.grey[900]};
          border-color: ${isError ? theme.palette.error[100] : theme.palette.grey[900]};
      }
        `;
  }
};

export const getColorVariantByNameOrId = (value: string | number) => {
  switch (value) {
    case ROLES.ADMIN:
    case ROLES.VERIFICATOR:
    case 1:
    case 12:
      return 'error';
    case ROLES.NETWORK_MANAGER:
    case ROLES.MODERATOR_MANAGER:
    case ROLES.MODERATOR_MANAGER_SUB:
    case ROLES.DEMO_MANAGER:
    case 2:
    case 5:
    case 6:
    case 8:
      return 'warning';
    case ROLES.SOUND_MASTER:
    case ROLES.DEVICE_MANAGER:
    case 4:
    case 9:
      return 'primary';
    case ROLES.REALTIME:
    case ROLES.COMMAND_POST:
    case 7:
      return 'success';
    case ROLES.SOUND_APP:
    case 3:
      return 'secondary';
    case ROLES.BIRDWATCHER:
    case 11:
      return 'default';
    default:
      return 'primary25';
  }
};

export const getScrollStyles = (theme: Theme) => `

  &::-webkit-scrollbar {
    width: ${getSizeRem(4)};
    height: ${getSizeRem(4)};
  }
  &::-webkit-scrollbar-track {
    background-color: ${theme.palette.grey[25]};
    border-radius: ${getSizeRem(theme.sizes.base)};
    background-clip: content-box;
    border: ${getSizeRem(1)} solid transparent;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${theme.palette.grey[300]};
    border-radius: ${getSizeRem(theme.sizes.base)};
  }

`;
