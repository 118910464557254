import styled from '@emotion/styled';
import { ButtonHTMLAttributes, DetailedHTMLProps, FC, MouseEventHandler, ReactNode } from 'react';

import { FontSizes, getSizeRem } from '../Theme';

export interface FilterItemProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  className?: string;
  selected?: boolean;
  onClick: MouseEventHandler;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  text?: string;
  fontSize?: FontSizes;
  textMargin?: 'start' | 'end';
}

const StyledButton = styled.button<FilterItemProps>(
  ({ selected, theme, textMargin, fontSize = 'm' }) => `
    display: flex;
    align-items: center;
    gap: ${getSizeRem(theme.sizes.xs)};
    font-family: inherit;
    font-weight: 400;
    font-size: ${getSizeRem(theme.fontSizes[fontSize])};
    line-height: inherit;
    color: ${theme.palette.grey[900]};
    border: none;
    cursor: pointer;
    background-color: ${selected ? theme.palette.primary[25] : theme.palette.white};
    width: 100%;
    &>p{
      ${textMargin ? `margin-inline-${textMargin}: auto;` : ''}
    }
    
  `
);

export const FilterMenuItem: FC<FilterItemProps> = ({ text, startAdornment, endAdornment, ...props }) => (
  <StyledButton {...props}>
    {startAdornment}
    <p>{text}</p>
    {endAdornment}
  </StyledButton>
);
