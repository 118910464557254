import { CSSProperties } from 'react';

import { IconType } from '../Icon';
import { ColorVariant } from '../Theme';

export enum ToastVariantBasic {
  NETWORK_ERROR = 'NETWORK_ERROR',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  NEW_VERSION = 'NEW_VERSION',
  SOCKET_DISCONNECT = 'SOCKET_DISCONNECT',
  SOCKET_RECONNECT = 'SOCKET_RECONNECT',
}

export enum ToastVariantWithText {
  SUCCESS_ACTION = 'SUCCESS_ACTION',
  ERROR_ACTION = 'ERROR_ACTION',
  WARNING_ACTION = 'WARNING_ACTION',
}

export type ToastVariant = ToastVariantBasic | ToastVariantWithText;

interface InitialToast {
  id: string;
}
export interface ToastBasic extends InitialToast {
  type: ToastVariantBasic;
}

export interface ToastWithText extends InitialToast {
  type: ToastVariantWithText;
  text: string;
}

export type Toast = ToastBasic | ToastWithText;

export interface ToastConfig {
  icon: IconType;
  color: ColorVariant;
  disabledClose: boolean;
  duration?: number; //in milliseconds
  align?: CSSProperties['alignItems'];
}
