import { FC } from 'react';
import styled from '@emotion/styled';

import { Spacing, getSizeRem } from '../../Theme';
import { Tag, TagColorVariant } from '@/components/Core';

type Option = { id: string | number; name: string };

export interface TagsGroupProps {
  options: Option[];
  onClick: (id: string) => void;
  gap?: Spacing;
  tagsTopSpace?: Spacing;
  tagColor: ((id: string | number) => TagColorVariant) | TagColorVariant;
  hasClose?: boolean;
}

const StyledList = styled.ul<{ gap: Spacing; tagsTopSpace: Spacing }>(
  ({ theme, gap, tagsTopSpace }) => `
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: ${getSizeRem(theme.sizes[gap])};
    margin-top: ${getSizeRem(theme.sizes[tagsTopSpace])};
    & li {
        cursor: pointer;
        & div {
            transition: background-color ${theme.transitionOptions};
        }
        & div:hover {
            background-color: ${theme.palette.primary[50]};
        }
    }
`
);

export const TagsGroup: FC<TagsGroupProps> = ({
  hasClose = true,
  options,
  onClick,
  gap = 'xxs',
  tagsTopSpace = 'none',
  tagColor,
}) => {
  return (
    <StyledList gap={gap} tagsTopSpace={tagsTopSpace}>
      {options.map(({ id, name }) => (
        <li
          key={id}
          onClick={() => {
            onClick(id.toString());
          }}
        >
          <Tag
            color={tagColor instanceof Function ? tagColor(id) : tagColor}
            variant="outlined"
            icon={hasClose ? 'close' : undefined}
            iconAlign="right"
          >
            {name}
          </Tag>
        </li>
      ))}
    </StyledList>
  );
};
