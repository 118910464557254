import { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react';
import styled from '@emotion/styled';

import { FieldBorderVariant, FontSizes, Spacing, getBorderStylesByVariant, getSizeRem } from '../../Theme';

export interface NumberFieldProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  variant?: FieldBorderVariant;
  paddingBlock?: Spacing;
  fontSize?: FontSizes;
  isError?: boolean;
  fullWidth?: boolean;
}

const StyledTextWrapper = styled.label<{
  variant: FieldBorderVariant;
  paddingBlock: Spacing;
  fontSize: FontSizes;
  isError: boolean;
  fullWidth: boolean;
}>(
  ({ theme, variant, paddingBlock, fontSize, isError, fullWidth }) => `
    position: relative;
    display: flex;
    flex-direction: column;
    gap: ${getSizeRem(theme.sizes.s)};
    cursor: pointer;
    font-size: ${getSizeRem(theme.fontSizes[fontSize])};
    font-weight: 800;
    font-family: inherit;
    color: ${theme.palette.primary[600]};

    & input {
        outline: none;
        ${getBorderStylesByVariant(variant, theme, false, isError)}
        padding-block: ${getSizeRem(theme.sizes[paddingBlock] / 2)};
        padding-inline: ${getSizeRem(theme.sizes.s)};
        font-size: inherit;
        line-height: inherit;
        font-weight: 400;
        font-family: inherit;
        color: ${isError ? theme.palette.error[300] : theme.palette.grey[300]};
        transition: color ${theme.transitionOptions}, border-color ${theme.transitionOptions};
        ${fullWidth && 'width: 100%;'}

        &:disabled {
          color: ${theme.palette.grey[200]};
          border-color: ${theme.palette.grey[100]};
          background-color: ${theme.palette.white};
      }
    }
`
);

export const NumberField: FC<Omit<NumberFieldProps, 'type'>> = ({
  variant = 'outlined',
  label,
  paddingBlock = 's',
  fontSize = 'm',
  isError = false,
  fullWidth = false,
  ...props
}) => {
  return (
    <StyledTextWrapper
      variant={variant}
      paddingBlock={paddingBlock}
      fontSize={fontSize}
      isError={isError}
      fullWidth={fullWidth}
    >
      {label}
      <input {...props} type="number" />
    </StyledTextWrapper>
  );
};
