import { DetailedHTMLProps, FC, HTMLAttributes, PropsWithChildren } from 'react';
import styled from '@emotion/styled';

import { ColorVariant, FontSizes, Theme, getSizeRem } from '../Theme';
import { IconAlign } from '../Button';
import { IconType } from '../Icon';
import { icons } from '@/assets/icons';

export const TAG_VARIANTS = ['filled', 'outlined'] as const;

type TagVariant = (typeof TAG_VARIANTS)[number];
export type TagColorVariant = ColorVariant | 'primary25';

export interface TagProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  color?: TagColorVariant;
  variant?: TagVariant;
  icon?: IconType;
  iconAlign?: IconAlign;
  fontSize?: FontSizes;
}

const getColorStylesFilled = (color: TagColorVariant, theme: Theme) => {
  switch (color) {
    case 'secondary':
      return `
          background: ${theme.palette.primary[50]};
          color: ${theme.palette.primary[600]};
          border-color: ${theme.palette.primary[50]};

          & svg {
            fill: ${theme.palette.primary[600]};
          }
        `;
    case 'primary':
      return `
          color: ${theme.palette.white};
          border-color: ${theme.palette.primary[600]};
          background: ${theme.palette.primary[600]};

          & svg {
            fill: ${theme.palette.white};
          }

        `;
    case 'error':
      return `
          color: ${theme.palette.white};
          border-color: ${theme.palette.error[500]};
          background: ${theme.palette.error[500]};

          & svg {
            fill: ${theme.palette.white};
          }
        `;
    case 'warning':
      return `
          color: ${theme.palette.white};
          border-color: ${theme.palette.warning[700]};
          background: ${theme.palette.warning[700]};

          & svg {
            fill: ${theme.palette.white};
          }
        `;
    case 'success':
      return `
          color: ${theme.palette.white};
          border-color: ${theme.palette.success[500]};
          background: ${theme.palette.success[500]};

          & svg {
            fill: ${theme.palette.white};
          }
        `;
    default:
      return `
          color: ${theme.palette.grey[900]};
          background: ${theme.palette.grey[50]};
          border-color: ${theme.palette.grey[50]};

          & svg {
            fill: ${theme.palette.grey[900]};
          }
        `;
  }
};

const getColorStylesOutline = (color: TagColorVariant, theme: Theme) => {
  switch (color) {
    case 'secondary':
      return `
          background: ${theme.palette.primary[50]};
          color: ${theme.palette.primary[600]};
          border-color: ${theme.palette.primary[600]};

          & svg {
            fill: ${theme.palette.primary[600]};
          }
        `;
    case 'primary':
      return `
          color: ${theme.palette.primary[600]};
          border-color: ${theme.palette.primary[600]};
          background: ${theme.palette.primary[25]};

          & svg {
            fill: ${theme.palette.primary[600]};
          }
        `;
    case 'error':
      return `
          color: ${theme.palette.error[700]};
          border-color: ${theme.palette.error[700]};
          background: ${theme.palette.error[25]};

          & svg {
            fill: ${theme.palette.error[700]};
          }
        `;
    case 'warning':
      return `
          color: ${theme.palette.warning[800]};
          border-color: ${theme.palette.warning[800]};
          background: ${theme.palette.warning[25]};

          & svg {
            fill: ${theme.palette.warning[800]};
          }
        `;
    case 'success':
      return `
          color: ${theme.palette.success[700]};
          border-color: ${theme.palette.success[700]};
          background: ${theme.palette.success[25]};

          & svg {
            fill: ${theme.palette.success[700]};
          }
        `;
    case 'primary25':
      return `
          color: ${theme.palette.grey[900]};
          border-color: ${theme.palette.primary[50]};
          background: ${theme.palette.primary[25]};

          & svg {
            fill: ${theme.palette.grey[900]};
          }
        `;
    default:
      return `
          color: ${theme.palette.grey[900]};
          background: ${theme.palette.grey[25]};
          border-color: ${theme.palette.grey[900]};

          & svg {
            fill: ${theme.palette.grey[900]};
          }
        `;
  }
};

const getColorStylesByVarian = (variant: TagVariant) => {
  switch (variant) {
    case 'filled':
      return getColorStylesFilled;
    case 'outlined':
      return getColorStylesOutline;
  }
};

const getAlignStyles = (align: IconAlign) => {
  switch (align) {
    case 'left':
      return `
          flex-direction: row;
        `;
    case 'right':
      return `
          flex-direction: row-reverse;
        `;
  }
};

const StyledTag = styled.div<TagProps>(
  ({ color = 'default', variant = 'filled', iconAlign = 'left', theme, fontSize = 'm' }) => `
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: ${getSizeRem(theme.sizes.xxs)};
    padding-inline: ${getSizeRem(theme.sizes.xs)};
    padding-block: ${getSizeRem(theme.sizes.xxs)};
    font-size: ${getSizeRem(theme.fontSizes[fontSize])};
    gap: ${getSizeRem(theme.sizes.xs)};
    border: 1px solid;
    font-family: inherit;
    font-weight: 600;
    line-height: inherit;
    ${getColorStylesByVarian(variant)(color, theme)}
    ${getAlignStyles(iconAlign)}

    & svg {
      width: ${getSizeRem(theme.sizes.m)};
      height: ${getSizeRem(theme.sizes.m)};
    }
    `
);

export const Tag: FC<PropsWithChildren<TagProps>> = ({ children, icon, ...props }) => {
  const IconComponent = icon ? icons[icon] : null;

  return (
    <StyledTag {...props}>
      {!!IconComponent && <IconComponent />}
      <span>{children}</span>
    </StyledTag>
  );
};
