import styled from '@emotion/styled';
import { FunctionComponent, MouseEvent, TouchEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Text } from '../../Text';
interface RangeFieldProps {
  min?: number;
  max?: number;
  value: [number, number];
  ticks?: number[];
  setValue: (data: [number, number]) => void;
  labelFormat?: (value: number) => string;
}

const Container = styled.div`
  position: relative;
  height: 12px;
  min-width: 0;
  width: 100%;
  cursor: pointer;
`;

const Thumb = styled.button<{
  position: number;
}>(
  ({ position, theme }) => `
  position: absolute;
  top: 0;
  border: none;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: ${theme.palette.primary[200]};
  left: ${position - 6}px;
  z-index: 2;
  cursor: pointer;
`
);

const Tick = styled.button<{
  position: number;
}>(
  ({ position, theme }) => `
    position: absolute;
    left: ${position - 2}px;
    z-index: 2;
    cursor: pointer;
    top: 0;
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;


    &:before {
      content: '';
      display: block;
      height: 8px;
      width: 2px;
      background: ${theme.palette.grey[200]};
    }

    &:hover {
      &:before {
        background: ${theme.palette.primary[200]};
      }
    }
  `
);

const TouchableZone = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const TouchableTrack = styled.div`
  position: absolute;
  top: 6px;
  height: 24px;
  left: 0;
  right: 0;
  z-index: 2;
  transform: translateY(-50%);
`;

const Track = styled.div<{
  left?: number;
  right?: number;
  type: 'active' | 'disabled';
}>(
  ({ left, right, type, theme }) => `
    top: 4px;
    position: absolute;
    border-radius: 2px;
    height: 4px;
    background: ${type === 'active' ? theme.palette.primary[200] : theme.palette.grey[100]};
    left: ${left || 0}px;
    right: ${right || 0}px;
`
);

const TicksContainer = styled.div`
  position: absolute;
  top: -9px;
  left: 0;
  right: 0;
  z-index: 2;
  height: 8px;
`;

const Label = styled(Text)<{ position: 'left' | 'right' }>(
  ({ position }) => `
        position: absolute;
        top: 14px;
        ${position}: 0;
    `
);

export const RangeField: FunctionComponent<RangeFieldProps> = ({
  min = 0,
  max = 100,
  value,
  ticks,
  setValue,
  labelFormat,
}) => {
  const frameAnimation = useRef<number | null>(null);
  const [start, end] = value;
  const [width, setWidth] = useState<number>(0);
  const [position, setPosition] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const touchableZoneRef = useRef<HTMLDivElement | null>(null);
  const drag = useRef<'start' | 'end' | null>(null);

  const range = useMemo(() => max - min, [min, max]);

  const getPosition = useCallback(
    (value: number) => {
      const stepInPx = width / range;
      return (value - min) * stepInPx;
    },
    [min, range, width]
  );

  const roundedTicks = useMemo<[number, number][]>(
    () =>
      ticks
        ? Array.from(
            new Map(
              ticks
                .map((tick) => {
                  const point = Math.floor(getPosition(tick));
                  return {
                    value: tick,
                    position: point - (point % 4), //filter duplicate tick what be in range 2px
                  };
                })
                .map(({ value, position }) => [position, value])
            )
          )
        : [],
    [getPosition, ticks]
  );

  const { left, right } = useMemo(() => {
    return {
      left: getPosition(start),
      right: getPosition(end),
    };
  }, [getPosition, start, end]);

  useEffect(() => {
    let animationId = frameAnimation.current;

    const animate = () => {
      if (containerRef.current && containerRef.current.offsetWidth && containerRef.current.offsetWidth !== width) {
        setWidth(containerRef.current.offsetWidth);
        setPosition(containerRef.current.getBoundingClientRect().x);
      }
      frameAnimation.current = animationId = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      if (animationId) {
        cancelAnimationFrame(animationId);
      }
    };
  }, [width]);

  const checkDuration = useCallback(
    (duration: number) => (duration < min ? min : duration > max ? max : duration),
    [min, max]
  );

  const handleMove = (e: MouseEvent<HTMLDivElement> | TouchEvent<HTMLButtonElement>) => {
    if (drag.current && position) {
      const clientX =
        'touches' in e
          ? (e as TouchEvent<HTMLButtonElement>).changedTouches[0].clientX
          : (e as MouseEvent<HTMLDivElement>).clientX;
      const duration = Math.ceil(((clientX - position) / width) * range + min);

      if (drag.current === 'start') {
        setValue([checkDuration(duration), end]);
      }
      if (drag.current === 'end') {
        setValue([start, checkDuration(duration)]);
      }
    }
  };

  const handleClick = (e: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => {
    if (position) {
      const clientX =
        'changedTouches' in e
          ? (e as TouchEvent<HTMLDivElement>).changedTouches[0].clientX
          : (e as MouseEvent<HTMLDivElement>).clientX;
      const duration = Math.floor(((clientX - position) / width) * range + min);

      if (duration > start) {
        setValue([start, checkDuration(duration)]);
      }
      if (duration < start) {
        setValue([checkDuration(duration), end]);
      }
    }
  };

  const handleTick = (duration: number) => {
    if (duration > start) {
      setValue([start, checkDuration(duration)]);
    }
    if (duration < start) {
      setValue([checkDuration(duration), end]);
    }
  };

  const handleEnd = () => {
    if (touchableZoneRef.current) {
      touchableZoneRef.current.style.width = '0';
      touchableZoneRef.current.style.height = '0';
    }
    drag.current = null;
  };

  const handleStart = (type: 'start' | 'end') => () => {
    if (touchableZoneRef.current) {
      touchableZoneRef.current.style.width = '100vw';
      touchableZoneRef.current.style.height = '100vh';
    }
    drag.current = type;
  };

  return (
    <Container ref={containerRef} onMouseDown={(e) => e.stopPropagation()} onDoubleClick={(e) => e.stopPropagation()}>
      <TouchableTrack
        onMouseMove={handleMove}
        onClick={handleClick}
        onMouseUp={handleEnd}
        onTouchStart={handleClick}
        onTouchEnd={handleEnd}
      />
      <TouchableZone ref={touchableZoneRef} onMouseMove={handleMove} onMouseUp={handleEnd} onTouchEnd={handleEnd} />
      <Thumb
        position={left}
        onMouseDown={handleStart('start')}
        onMouseUp={handleEnd}
        onTouchStart={handleStart('start')}
        onTouchMove={handleMove}
        onTouchEnd={handleEnd}
      />
      <Track type="disabled" />
      <Track type="active" left={left} right={width - right} />
      {labelFormat && (
        <>
          <Label size="xxs" position="left">
            {labelFormat(start)}
          </Label>
          <Label size="xxs" position="right">
            {labelFormat(end)}
          </Label>
        </>
      )}
      <Thumb
        position={right}
        onMouseDown={handleStart('end')}
        onMouseUp={handleEnd}
        onTouchStart={handleStart('end')}
        onTouchMove={handleMove}
        onTouchEnd={handleEnd}
      />
      <TicksContainer onMouseMove={handleMove} onMouseUp={handleEnd} onTouchEnd={handleEnd}>
        {roundedTicks.map(([position, value], index) => (
          <Tick key={`${position}-${index}`} position={position} onClick={() => handleTick(value)} />
        ))}
      </TicksContainer>
    </Container>
  );
};
