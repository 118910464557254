import { PropsWithChildren, ReactNode } from 'react';
import styled from '@emotion/styled';

import { getScrollStyles } from '../Theme/utils';

const SIDEBAR_ALIGNS = ['left', 'right'] as const;

type SideBarAlign = (typeof SIDEBAR_ALIGNS)[number];

export type SideBarProps = PropsWithChildren<{
  align: SideBarAlign;
  collapsed?: boolean;
  buttonContent?: ReactNode;
  onCollapse?: () => void;
}>;

export const SideBar = ({ children, collapsed = false, onCollapse, ...props }: SideBarProps) => {
  const renderCollapseButton = () => {
    if (collapsed) {
      const { align, buttonContent } = props;

      if (onCollapse)
        return (
          <StyledCollapseButton align={align} type="button" onClick={onCollapse}>
            {buttonContent}
          </StyledCollapseButton>
        );
    }

    return null;
  };

  return (
    <>
      <StyledContainer {...props} collapsed={collapsed}>
        <WrapperSafeArea>{!collapsed && children}</WrapperSafeArea>
      </StyledContainer>
      {renderCollapseButton()}
    </>
  );
};

const WrapperSafeArea = styled.div(
  ({ theme }) => `
    height: 100dvh;
    @supports(height: 100dvh) {
      height: 100dvh;
    }
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-block: ${theme.sizes.l}px;
    gap: ${theme.sizes.s}px;
    transition: height 0.2s linear;

    ${getScrollStyles(theme)}
  `
);

const StyledContainer = styled.div<
  Omit<SideBarProps, 'buttonContent'> & {
    collapsed: boolean;
  }
>(
  ({ align, theme }) => `
    position: fixed;
    z-index: 10002;
    top: 0;
    ${align}: 0;
    height: 100vh;
    background-color: ${theme.palette.primary[900]};
  `
);

const StyledCollapseButton = styled.button<{
  align: SideBarAlign;
}>(
  ({ align, theme }) => `
        position: fixed;
        ${align}: 0;
        top: 50%;
        transform: translateY(-50%);
        padding: ${theme.sizes.s}px;
        background-color: ${theme.palette.primary[900]};
        border-radius: 6px;
        border-top-${align}-radius: 0px;
        border-bottom-${align}-radius: 0px;
        border: none;
        outline: none;
        cursor: pointer;
        z-index: 1001;

        & span {
            color: ${theme.palette.white};
            margin-inline: ${theme.sizes.base}px;
            writing-mode: vertical-${align === 'left' ? 'rl' : 'lr'};
            text-orientation: mixed;
            ${align === 'right' ? 'transform: rotate(180deg);' : ''}
        }

        & svg {
          fill: ${theme.palette.white}!important;
          width: ${theme.sizes.base}px;
          height: ${theme.sizes.base}px;
        }

        @media (max-height: ${theme.breakpoints.xs}px) {
          top: 100%;
          transform: translateY(-100%);
          transform: translateY(calc(-100% - 10px));
        }

    `
);
