import { ReactComponent as AccountGroup } from './account-group.svg'
import { ReactComponent as AccountRound } from './account-round.svg'
import { ReactComponent as Account } from './account.svg'
import { ReactComponent as AddPeople } from './add people.svg'
import { ReactComponent as Add } from './add.svg'
import { ReactComponent as AlarmLight } from './alarm-light.svg'
import { ReactComponent as Alert } from './alert.svg'
import { ReactComponent as AllInclusive } from './all-inclusive.svg'
import { ReactComponent as Archive } from './archive.svg'
import { ReactComponent as ArrowDown } from './arrow-down.svg'
import { ReactComponent as ArrowLeft } from './arrow-left.svg'
import { ReactComponent as ArrowRightThick } from './arrow-right-thick.svg'
import { ReactComponent as ArrowRight } from './arrow-right.svg'
import { ReactComponent as BellOff } from './bell-off.svg'
import { ReactComponent as BellRing } from './bell-ring.svg'
import { ReactComponent as Bell } from './bell.svg'
import { ReactComponent as Beta } from './beta.svg'
import { ReactComponent as Bpla } from './BPLA.svg'
import { ReactComponent as CalendarBlank } from './calendar-blank.svg'
import { ReactComponent as CalendarClock } from './calendar-clock.svg'
import { ReactComponent as CellphoneMarker } from './cellphone-marker.svg'
import { ReactComponent as ChartLine } from './chart-line.svg'
import { ReactComponent as ChartTimeline } from './chart-timeline.svg'
import { ReactComponent as CheckCircle } from './check-circle.svg'
import { ReactComponent as Check } from './check.svg'
import { ReactComponent as ClockTimeFiveOutline } from './clock-time-five-outline.svg'
import { ReactComponent as Close } from './close.svg'
import { ReactComponent as ContentCopy } from './content-copy.svg'
import { ReactComponent as Coordinate } from './coordinate.svg'
import { ReactComponent as Delete } from './delete.svg'
import { ReactComponent as Detected } from './detected.svg'
import { ReactComponent as Devices } from './devices.svg'
import { ReactComponent as DoneModeration } from './done_moderation.svg'
import { ReactComponent as Down } from './down.svg'
import { ReactComponent as Dron1 } from './dron 1.svg'
import { ReactComponent as Dron216 } from './dron 2_16.svg'
import { ReactComponent as Dron } from './dron.svg'
import { ReactComponent as Dron16 } from './dron_16.svg'
import { ReactComponent as Dron20 } from './dron_20.svg'
import { ReactComponent as Email } from './email.svg'
import { ReactComponent as Energy } from './energy.svg'
import { ReactComponent as Exit } from './exit.svg'
import { ReactComponent as Export } from './export.svg'
import { ReactComponent as EyeOff } from './eye-off.svg'
import { ReactComponent as Eye } from './eye.svg'
import { ReactComponent as False1 } from './false-1.svg'
import { ReactComponent as False } from './false.svg'
import { ReactComponent as Filter } from './filter.svg'
import { ReactComponent as Frame } from './Frame.svg'
import { ReactComponent as FullscreenOn } from './fullscreen-on.svg'
import { ReactComponent as Google } from './Google.svg'
import { ReactComponent as Hight } from './hight.svg'
import { ReactComponent as Highway } from './highway.svg'
import { ReactComponent as Home } from './home.svg'
import { ReactComponent as Import } from './import.svg'
import { ReactComponent as InfoCircleOutlined } from './info-circle-outlined.svg'
import { ReactComponent as InfoCircle } from './info-circle.svg'
import { ReactComponent as InformationOutline1 } from './information-outline-1.svg'
import { ReactComponent as InformationOutline } from './information-outline.svg'
import { ReactComponent as InviteGroup } from './invite-group.svg'
import { ReactComponent as Layers } from './layers.svg'
import { ReactComponent as Live } from './live.svg'
import { ReactComponent as Live1 } from './live_1.svg'
import { ReactComponent as Loading } from './loading.svg'
import { ReactComponent as Magic } from './magic.svg'
import { ReactComponent as Magic1 } from './magic_1.svg'
import { ReactComponent as MapClock } from './map-clock.svg'
import { ReactComponent as MapMarkerCheck } from './map-marker-check.svg'
import { ReactComponent as MapMarkerPath } from './map-marker-path.svg'
import { ReactComponent as MapMarkerRemoveVariant } from './map-marker-remove-variant.svg'
import { ReactComponent as Measure } from './measure.svg'
import { ReactComponent as MenuDown } from './menu-down.svg'
import { ReactComponent as MenuRight } from './menu-right.svg'
import { ReactComponent as MenuUpDown } from './menu-up-down.svg'
import { ReactComponent as MenuUp } from './menu-up.svg'
import { ReactComponent as Minus } from './minus.svg'
import { ReactComponent as Missile } from './missile.svg'
import { ReactComponent as Missiles } from './missiles.svg'
import { ReactComponent as Missile16 } from './missile_16.svg'
import { ReactComponent as Moderation } from './moderation.svg'
import { ReactComponent as OnModeration } from './on moderation.svg'
import { ReactComponent as Our } from './our.svg'
import { ReactComponent as PaginArrowLeft } from './pagin-arrow-left.svg'
import { ReactComponent as PaginArrowRight } from './pagin-arrow-right.svg'
import { ReactComponent as Path } from './path.svg'
import { ReactComponent as Pause } from './pause.svg'
import { ReactComponent as Pencil } from './pencil.svg'
import { ReactComponent as Pin } from './pin.svg'
import { ReactComponent as PlayCircle } from './play-circle.svg'
import { ReactComponent as PlayReset } from './play-reset.svg'
import { ReactComponent as Play } from './play.svg'
import { ReactComponent as PlusCircle } from './plus-circle.svg'
import { ReactComponent as Plus } from './plus.svg'
import { ReactComponent as Poll } from './poll.svg'
import { ReactComponent as PowerOff } from './power off.svg'
import { ReactComponent as PowerOn } from './power on.svg'
import { ReactComponent as Prediction } from './prediction.svg'
import { ReactComponent as Print } from './print.svg'
import { ReactComponent as Refresh } from './refresh.svg'
import { ReactComponent as RegionsMap } from './regions-map.svg'
import { ReactComponent as Regions } from './regions.svg'
import { ReactComponent as Road } from './road.svg'
import { ReactComponent as RocketOff } from './rocket off.svg'
import { ReactComponent as Rocket } from './rocket.svg'
import { ReactComponent as RoundLogo } from './round-logo.svg'
import { ReactComponent as Search } from './search.svg'
import { ReactComponent as Settings2 } from './settings-2.svg'
import { ReactComponent as Settings } from './settings.svg'
import { ReactComponent as Simulator } from './simulator.svg'
import { ReactComponent as SkipPreviousCircle } from './skip-previous-circle.svg'
import { ReactComponent as TimelineClock } from './timeline-clock.svg'
import { ReactComponent as Trajectory } from './trajectory.svg'
import { ReactComponent as TrajectoryDetected } from './trajectory_detected.svg'
import { ReactComponent as TrajectoryRefresh } from './trajectory_refresh.svg'
import { ReactComponent as TrashCanOutline } from './trash-can-outline.svg'
import { ReactComponent as TrashCan } from './trash-can.svg'
import { ReactComponent as TypeRocket } from './type-rocket.svg'
import { ReactComponent as Unknown } from './unknown.svg'
import { ReactComponent as VolumeHigh } from './volume-high.svg'
import { ReactComponent as VolumeOff } from './volume-off.svg'
import { ReactComponent as WifiOff } from './wifi-off.svg'
import { ReactComponent as Wifi } from './wifi.svg'

export const icons = {
	"account-group": AccountGroup,
	"account-round": AccountRound,
	"account": Account,
	"add people": AddPeople,
	"add": Add,
	"alarm-light": AlarmLight,
	"alert": Alert,
	"all-inclusive": AllInclusive,
	"archive": Archive,
	"arrow-down": ArrowDown,
	"arrow-left": ArrowLeft,
	"arrow-right-thick": ArrowRightThick,
	"arrow-right": ArrowRight,
	"bell-off": BellOff,
	"bell-ring": BellRing,
	"bell": Bell,
	"beta": Beta,
	"bpla": Bpla,
	"calendar-blank": CalendarBlank,
	"calendar-clock": CalendarClock,
	"cellphone-marker": CellphoneMarker,
	"chart-line": ChartLine,
	"chart-timeline": ChartTimeline,
	"check-circle": CheckCircle,
	"check": Check,
	"clock-time-five-outline": ClockTimeFiveOutline,
	"close": Close,
	"content-copy": ContentCopy,
	"coordinate": Coordinate,
	"delete": Delete,
	"detected": Detected,
	"devices": Devices,
	"done_moderation": DoneModeration,
	"down": Down,
	"dron 1": Dron1,
	"dron 2_16": Dron216,
	"dron": Dron,
	"dron_16": Dron16,
	"dron_20": Dron20,
	"email": Email,
	"energy": Energy,
	"exit": Exit,
	"export": Export,
	"eye-off": EyeOff,
	"eye": Eye,
	"false-1": False1,
	"false": False,
	"filter": Filter,
	"frame": Frame,
	"fullscreen-on": FullscreenOn,
	"google": Google,
	"hight": Hight,
	"highway": Highway,
	"home": Home,
	"import": Import,
	"info-circle-outlined": InfoCircleOutlined,
	"info-circle": InfoCircle,
	"information-outline-1": InformationOutline1,
	"information-outline": InformationOutline,
	"invite-group": InviteGroup,
	"layers": Layers,
	"live": Live,
	"live_1": Live1,
	"loading": Loading,
	"magic": Magic,
	"magic_1": Magic1,
	"map-clock": MapClock,
	"map-marker-check": MapMarkerCheck,
	"map-marker-path": MapMarkerPath,
	"map-marker-remove-variant": MapMarkerRemoveVariant,
	"measure": Measure,
	"menu-down": MenuDown,
	"menu-right": MenuRight,
	"menu-up-down": MenuUpDown,
	"menu-up": MenuUp,
	"minus": Minus,
	"missile": Missile,
	"missiles": Missiles,
	"missile_16": Missile16,
	"moderation": Moderation,
	"on moderation": OnModeration,
	"our": Our,
	"pagin-arrow-left": PaginArrowLeft,
	"pagin-arrow-right": PaginArrowRight,
	"path": Path,
	"pause": Pause,
	"pencil": Pencil,
	"pin": Pin,
	"play-circle": PlayCircle,
	"play-reset": PlayReset,
	"play": Play,
	"plus-circle": PlusCircle,
	"plus": Plus,
	"poll": Poll,
	"power off": PowerOff,
	"power on": PowerOn,
	"prediction": Prediction,
	"print": Print,
	"refresh": Refresh,
	"regions-map": RegionsMap,
	"regions": Regions,
	"road": Road,
	"rocket off": RocketOff,
	"rocket": Rocket,
	"round-logo": RoundLogo,
	"search": Search,
	"settings-2": Settings2,
	"settings": Settings,
	"simulator": Simulator,
	"skip-previous-circle": SkipPreviousCircle,
	"timeline-clock": TimelineClock,
	"trajectory": Trajectory,
	"trajectory_detected": TrajectoryDetected,
	"trajectory_refresh": TrajectoryRefresh,
	"trash-can-outline": TrashCanOutline,
	"trash-can": TrashCan,
	"type-rocket": TypeRocket,
	"unknown": Unknown,
	"volume-high": VolumeHigh,
	"volume-off": VolumeOff,
	"wifi-off": WifiOff,
	"wifi": Wifi,
};
