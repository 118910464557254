import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { State } from './types';
import { getPoints } from './thunks';

const initialState: State = {
  points: [],
  soundMaster: [],
  regions: [],
  agents: [],
  statistic: {},
  isLoadingDownload: false,
  isLoadingDownloadGrouped: false,
};

const slice = createSlice({
  name: 'devices-map',
  initialState,
  reducers: {
    clear: ({ isLoadingDownload, isLoadingDownloadGrouped }) => ({
      ...initialState,
      isLoadingDownload,
      isLoadingDownloadGrouped,
    }),
    setLoaderDownload: (state, action: PayloadAction<boolean>) => {
      state.isLoadingDownload = action.payload;
    },
    setLoaderGroupedDownload: (state, action: PayloadAction<boolean>) => {
      state.isLoadingDownloadGrouped = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getPoints.fulfilled, (state, action) => {
      if (action.payload) {
        if (action.payload.heartbeats) state.points = action.payload.heartbeats;
        if (action.payload.available_regions) {
          state.regions = action.payload.available_regions as Types.AvailableRegion[];
        }
        if (action.payload.sound_masters) {
          state.soundMaster = action.payload.sound_masters as Types.SoundMaster[];
        }
        if (action.payload.agent_stats) {
          state.agents = Object.entries(action.payload.agent_stats).map(([name, count]) => ({ name, count }));
        }
        if (action.payload.agent_stats) {
          state.statistic = action.payload.agent_stats;
        }
      }
    });
  },
});

export const actions = {
  ...slice.actions,
  getPoints,
};

export default slice.reducer;
