import styled from '@emotion/styled';
import { CSSProperties, PropsWithChildren } from 'react';

import { Spacing, getSizeRem } from '../Theme';

import { hasProperty } from './util';

export type Border = 'border' | 'border-top' | 'border-bottom' | 'border-left' | 'border-right';
interface FlexGroupProps extends PropsWithChildren {
  direction?: CSSProperties['flexDirection'];
  justify?: CSSProperties['justifyContent'];
  align?: CSSProperties['alignItems'];
  wrap?: CSSProperties['flexWrap'];
  border?: Border;
  gap?: Spacing;
  minheight?: number;
  className?: string;
  borderradius?: Spacing;
}

export const FlexGroup = styled.div<FlexGroupProps>(
  ({ direction = 'row', justify, align, wrap, gap = 'none', border, theme, minheight, borderradius }) => `
          display: flex;
          flex-direction: ${direction};
          ${hasProperty(justify, `justify-content: ${justify};`)}
          ${hasProperty(align, `align-items: ${align};`)}
          ${hasProperty(wrap, `flex-wrap: ${wrap};`)}
          ${hasProperty(border, `${border}: 1px solid ${theme.palette.primary[25]};`)}
          ${hasProperty(borderradius, `border-radius: ${getSizeRem(theme.sizes[borderradius as Spacing])};`)}
          ${hasProperty(minheight, `min-height: ${getSizeRem(minheight as number)};`)}
          gap: ${theme.sizes[gap]}px;
      `
);
