import styled from '@emotion/styled';
import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { Text, getSizeRem, FlexGroup, Icon } from '../../Core';

import { ReactComponent as TeamWorkImage } from './team-work.svg';

export const WelcomeLayout: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  return (
    <LayoutPanel>
      <SidebarPanel>
        <FlexGroup align="center">
          <Icon type="round-logo" size="xl" className="mr-s" />
          <Text size="xxl" weight="bold" element="span">
            {t('WelcomeLayout.name')}
          </Text>
        </FlexGroup>
        <TitleText size="xxxl" weight="bold" element="h1">
          {t('WelcomeLayout.sub')}
          <TitleText2>{t('WelcomeLayout.main')}</TitleText2>
        </TitleText>
        <TeamWorkImage />
      </SidebarPanel>
      <PagePanel>{children}</PagePanel>
    </LayoutPanel>
  );
};

const SidebarPanel = styled.div(
  ({ theme }) => `
        flex: 0 1 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 40%;
        max-width: 595px;
        background-color: ${theme.palette.primary[25]};
        padding-inline: 80px;
        padding-block: 48px;

        @media only screen and (max-width: 991px) {
            display: none;
        }
    `
);

const PagePanel = styled.div(
  ({ theme }) => `
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${theme.palette.white};
    padding-inline: 32px;
    padding-block: 48px;
  `
);

const LayoutPanel = styled.div(
  ({ theme }) => `
    display: flex;
    height: 100%;
    min-height: 100vh;
    @supports (min-height: 100dvh) {
      min-height: 100dvh;
    }
    background-color: ${theme.palette.white};
`
);

const TitleText = styled(Text)`
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  margin-top: 32px;
  color: ${({ theme }) => theme.palette.primary[600]};
`;

const TitleText2 = styled.span`
  font-size: ${getSizeRem(56)};
  align-self: flex-end;
  font-weight: ${({ theme }) => theme.fontWeights.extrabold};
  color: ${({ theme }) => theme.palette.primary[300]};
`;
