import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { State } from './types';
import { getReport, getHeartbeat } from './thunks';

const initialState: State = {
  report: null,
  heartbeat: null,
  report_id: null,
  heartbeat_id: null,
};

const slice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    clear: () => initialState,
    setReport: (state, action: PayloadAction<number>) => ({
      ...state,
      report_id: action.payload,
    }),
    setHeartbeat: (state, action: PayloadAction<number>) => ({
      ...state,
      heartbeat_id: action.payload,
    }),
  },
  extraReducers(builder) {
    builder.addCase(getReport.fulfilled, (state, action) => {
      if (action.payload && action.payload.report) state.report = action.payload.report;
    });

    builder.addCase(getHeartbeat.fulfilled, (state, action) => {
      if (action.payload && action.payload.heartbeat) state.heartbeat = action.payload.heartbeat;
    });
  },
});

export const actions = {
  ...slice.actions,
  getHeartbeat,
  getReport,
};

export default slice.reducer;
