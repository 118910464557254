import { createSlice } from '@reduxjs/toolkit';

import { State } from './types';
import { getEventsList } from './thunks';

const initialState: State = {
  items: [],
};

const slice = createSlice({
  name: 'event-statistic',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(getEventsList.fulfilled, (state, action) => {
      if (action.payload) state.items = action.payload;
    });
  },
});

export const actions = {
  ...slice.actions,
  getEventsList,
};

export default slice.reducer;
