import { useTitle } from '@/hooks';
import { PageCenteredContent } from '../Layouts/PageCenteredContent';
import { FlexGroup, Text } from '../Core';

export const PermissionDenied = () => {
  const { t } = useTitle('page.permissionDenied.title');
  return (
    <PageCenteredContent>
      <FlexGroup gap="xs" direction="column" align="center">
        <Text weight="bold" size="xl" element="h1">
          {t('page.permissionDenied.title')}
        </Text>
        <Text color="secondary" size="m">
          {t('page.permissionDenied.note')}
        </Text>
      </FlexGroup>
    </PageCenteredContent>
  );
};
