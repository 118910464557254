import { ChangeEvent, FC, useState } from 'react';
import styled from '@emotion/styled';

import { FieldBorderVariant, FontSizes, Spacing, getBorderStylesByVariant, getSizeRem } from '../../Theme';
import { Icon, IconType } from '../../Icon';
import { containsOnlyDigitsDotsMinus } from '@/utils/containsOnlyDigitsDotsMinus';

enum CoordinateFieldsName {
  left = 'firstField',
  right = 'secondField',
}

export type CoordinateFieldsValues = {
  [key in CoordinateFieldsName]: number;
};

export interface CoordinateFieldProps {
  value: CoordinateFieldsValues;
  onChange: (value: CoordinateFieldsValues) => void;
  label?: string;
  iconType?: IconType;
  variant?: FieldBorderVariant;
  paddingBlock?: Spacing;
  fontSize?: FontSizes;
}

const StyledWrapper = styled.div<{ variant: FieldBorderVariant; paddingBlock: Spacing; fontSize: FontSizes }>(
  ({ theme, variant, paddingBlock, fontSize }) => `
    display: flex;
    flex-direction: column;
    gap: ${getSizeRem(theme.sizes.s)};

    & label {
      color: ${theme.palette.primary[600]};
      cursor: pointer;
      font-size: ${getSizeRem(theme.fontSizes[fontSize])};
      font-weight: 800;
      font-family: inherit;
    }

    & svg {
      display: block;
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: ${getSizeRem(theme.sizes.base / 2)};
      overflow: hidden;
    }
    
    & input {
      ${getBorderStylesByVariant(variant, theme, false)}
        width: 100%;
        outline: none;
        padding-block: ${getSizeRem(theme.sizes[paddingBlock] / 2)};
        padding-inline: ${getSizeRem(theme.sizes.s / 2)};
        font-size: inherit;
        line-height: inherit;
        font-weight: 400;
        font-family: inherit;
        color: ${theme.palette.grey[900]};
        transition: color ${theme.transitionOptions}, border-color ${theme.transitionOptions};

        &:focus-visible {
          color: ${theme.palette.grey[900]};
        }

        &::placeholder {
          color: ${theme.palette.grey[200]};
        }

        &:disabled {
          color: ${theme.palette.grey[200]};
          border-color: ${theme.palette.grey[100]};
          background-color: ${theme.palette.white};
        }

        
    }
`
);

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;

export const CoordinateField: FC<CoordinateFieldProps> = ({
  value,
  onChange,
  variant = 'outlined',
  label,
  iconType = 'minus',
  paddingBlock = 's',
  fontSize = 'm',
}) => {
  const [coordinates, setCoordinates] = useState(value);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: input, name } = event.target;
    const notValid = (!containsOnlyDigitsDotsMinus(input) && input) || +input > 180 || +input < -180;
    if (notValid) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    setCoordinates((prev) => ({ ...prev, [name]: input }));
    const numericValue = Number(input);
    /* if property value of inside state equal '-' 
    or changes inside state didn`t change number value (example, string to number of '0' or '0.'  or '' is 0
    shouldn`t run outside handler */
    if (isNaN(numericValue) || value[name as CoordinateFieldsName] === numericValue) return;
    onChange({
      ...value,
      [name]: numericValue,
    });
  };

  return (
    <StyledWrapper variant={variant} paddingBlock={paddingBlock} fontSize={fontSize}>
      <label htmlFor={CoordinateFieldsName.left}>{label}</label>
      <div>
        <input
          id={CoordinateFieldsName.left}
          type="number"
          name={CoordinateFieldsName.left}
          value={coordinates[CoordinateFieldsName.left]}
          onChange={handleChange}
          placeholder="00.00"
        />
        <StyledIcon type={iconType} />
        <input
          type="number"
          name={CoordinateFieldsName.right}
          value={coordinates[CoordinateFieldsName.right]}
          onChange={handleChange}
          placeholder="00.00"
        />
      </div>
    </StyledWrapper>
  );
};
