import { PropsWithChildren } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { Routes } from '@/routes';
import { useSelector, userSelectors } from '@/store';

const REDIRECT_SEARCH_PARAM = 'redirect';
export const AuthenticationLayout = ({ children }: PropsWithChildren): JSX.Element => {
  const isAuthenticated = useSelector(userSelectors.getAuthenticate);
  const [searchParams] = useSearchParams();

  if (isAuthenticated) {
    let redirectUrl: string = Routes.HOME;
    const isRedirect = searchParams.get(REDIRECT_SEARCH_PARAM);
    if (isRedirect) {
      const newSearchParams = new URLSearchParams(window.location.search);
      const redirect = newSearchParams.get(REDIRECT_SEARCH_PARAM);
      newSearchParams.delete(REDIRECT_SEARCH_PARAM);
      const params = newSearchParams.toString() ? '&' + newSearchParams.toString() : '';
      redirectUrl = redirect + params;
    }

    return <Navigate to={redirectUrl} replace />;
  }

  return <>{children}</>;
};
