import styled from '@emotion/styled';
import { CSSProperties, FC, PropsWithChildren, createElement } from 'react';

import { ColorVariant, FontSizes, Theme, WEIGHT_VARIANTS, getSizeRem } from '../Theme';

export const ELEMENT_VARIANTS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'div', 'span'] as const;

export type TextElementVariants = (typeof ELEMENT_VARIANTS)[number];

export interface TextProps extends PropsWithChildren {
  size?: FontSizes;
  weight?: keyof typeof WEIGHT_VARIANTS;
  element?: TextElementVariants;
  className?: string;
  color?: ColorVariant | string;
  align?: CSSProperties['textAlign'];
  multiline?: boolean;
}

const Basic: FC<TextProps> = ({ element = 'p', ...props }) => {
  delete props.multiline;
  return createElement(element, props);
};

const StyledText = styled(Basic)<TextProps>(
  ({ size = 'm', weight = 'regular', color = 'default', multiline = false, theme, align }) => `
      font-size: ${getSizeRem(theme.fontSizes[size])};
      font-weight: ${WEIGHT_VARIANTS[weight]};
       ${align ? `text-align: ${align};` : ''}
      ${getColorStyles(color, theme)}
      ${multiline ? 'white-space: pre-wrap;' : ''}
  `
);

const getColorStyles = (color: ColorVariant | string, theme: Theme) => {
  switch (color) {
    case 'default':
      return `color: inherit;`;
    case 'secondary':
      return `color: ${theme.palette.grey[300]};`;
    case 'primary':
      return `color: ${theme.palette.primary[600]};`;
    case 'error':
      return `color: ${theme.palette.error[400]};`;
    case 'warning':
      return `color: ${theme.palette.warning[500]};`;
    case 'success':
      return `color: ${theme.palette.success[500]};`;
    default:
      return `color: ${color};`;
  }
};

export const Text: FC<TextProps> = (props) => <StyledText {...props} />;
