import { createSlice } from '@reduxjs/toolkit';

import { State } from './types';
import { getRoles } from './thunks';

const initialState: State = {
  items: [],
  isLoading: true,
};

const slice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    clear: (state) => {
      state.items = [];
      state.isLoading = true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getRoles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.items = action.payload;
        state.isLoading = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  getRoles,
};

export default slice.reducer;
