import { FC, MouseEvent, ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';

import { getSizeRem } from '../Theme';
import { Button } from '../Button';

export interface ModalProps {
  isOpen: boolean;
  close: () => void;
  children: JSX.Element | ReactNode;
}

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10003;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.4);
`;

const ModalWindow = styled.div(
  ({ theme }) => `
position: absolute;
top: 50%;
left: 50%;
overflow: hidden;
transform: translate(-50%, -50%);
& > button {
  position: absolute;
  top: ${getSizeRem(theme.sizes.base)};
  right: ${getSizeRem(theme.sizes.l)};
}
`
);

const modalRoot = document.querySelector('#modal-root') as HTMLElement;

export const Modal: FC<ModalProps> = ({ isOpen, close, children }) => {
  useEffect(() => {
    const onEscapeClose = (e: KeyboardEvent): void => {
      if (e.code === 'Escape') {
        close();
      }
    };

    document.addEventListener('keydown', onEscapeClose);
    return () => document.removeEventListener('keydown', onEscapeClose);
  }, [close]);

  const handleCloseModal = (e: MouseEvent<HTMLDivElement>) => {
    const { target, currentTarget } = e;
    if (target === currentTarget) {
      close();
    }
  };

  const modal = (
    <Backdrop onMouseDown={handleCloseModal}>
      <ModalWindow>
        <Button icon="close" variant="empty" onClick={close} />
        {children}
      </ModalWindow>
    </Backdrop>
  );

  return isOpen ? createPortal(modal, modalRoot) : null;
};
