import { api } from '@/api';
import { createAppAsyncThunk } from '@/store/createAppAsyncThunk';
import { toastActions } from '../toast';
import { ToastVariantWithText } from '@/components/Core';
import i18n from '@/i18next';

import { getCurrentId as getCurrentIdSelector } from './selectors';
import { userActions } from '.';

export const loginAsync = createAppAsyncThunk('user/loginAsync', async (payload: Payloads.Login, thunkApi) => {
  try {
    const { data } = await api.login(payload);
    localStorage.setItem('token', data.token);
    localStorage.setItem('user_id', String(data.user_id));

    return data;
  } catch (error) {
    const { response } = error as Errors.LoginError;
    if (response?.status == 403 && response?.data) {
      return thunkApi.rejectWithValue(response.data.error_code);
    } else {
      thunkApi.dispatch(
        toastActions.create({
          type: ToastVariantWithText.ERROR_ACTION,
          text: i18n.t('toast.content.invalid_authentication_with_google'),
        })
      );
      return thunkApi.rejectWithValue('error');
    }
  }
});

export const logoutAsync = createAppAsyncThunk('user/logoutAsync', async (_, thunkApi) => {
  try {
    const { data } = await api.logout();
    clearLocalStorage();
    thunkApi.dispatch(userActions.logout());

    return data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getCurrent = createAppAsyncThunk('user/getCurrent', async (_, thunkApi) => {
  try {
    const userId = getCurrentIdSelector(thunkApi.getState());
    if (userId) {
      const {
        data: {
          data: { user, roles },
        },
      } = await api.getUser({ user_id: userId, disableToast: true });
      return { ...user, roles };
    } else {
      throw 'User ID is null';
    }
  } catch (error: any) {
    const { response } = error;
    if (response?.status == 403 && response?.data) {
      return thunkApi.rejectWithValue(response.data.error_code);
    } else {
      return thunkApi.rejectWithValue(error);
    }
  }
});

export const clearLocalStorage = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user_id');
};
