import styled from '@emotion/styled';
import { FC } from 'react';

import { ColorVariant, Spacing, Theme, getSizeRem } from '../Theme';

export interface CircleProps {
  className?: string;
  color?: ColorVariant | string;
  size?: Spacing;
}

const StyledSpan = styled.span<CircleProps>(
  ({ color = 'default', theme, size = 'xs' }) => `
    display: inline-block;
    ${getBgColorStyles(color, theme)};
    border-radius: 50%;
    width: ${getSizeRem(theme.sizes[size])};
    height: ${getSizeRem(theme.sizes[size])};
  `
);

const getBgColorStyles = (color: ColorVariant | string, theme: Theme) => {
  switch (color) {
    case 'default':
      return `background-color: currentColor;`;
    case 'secondary':
      return `background-color: ${theme.palette.grey[100]};`;
    case 'primary':
      return `background-color: ${theme.palette.primary[300]};`;
    case 'error':
      return `background-color: ${theme.palette.error[400]};`;
    case 'warning':
      return `background-color: ${theme.palette.warning[300]};`;
    case 'success':
      return `background-color: ${theme.palette.success[300]};`;
    default:
      return `background-color: ${color};`;
  }
};

export const Circle: FC<CircleProps> = (props) => <StyledSpan {...props} />;
