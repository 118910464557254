import styled from '@emotion/styled';

import { ColorVariant, Theme } from '../Theme';

const getBgColor = (theme: Theme, color?: ColorVariant, isEven?: boolean) => {
  if (!color && isEven) return `background-color: ${theme.palette.grey[25]};`;
  switch (color) {
    case 'success':
      return `background-color: ${theme.palette.success[25]}`;
    case 'warning':
      return `background-color: ${theme.palette.warning[25]}`;
    case 'error':
      return `background-color: ${theme.palette.error[25]}`;
    case 'primary':
      return `background-color: ${theme.palette.primary[25]}`;
    default:
      return '';
  }
};

export const Row = styled.tr<{
  isEven?: boolean;
  bg?: ColorVariant;
}>(
  ({ isEven, theme, bg }) => `
  ${getBgColor(theme, bg, isEven)}
  `
);
