import styled from '@emotion/styled';

import { NavMenuGroupProps } from './types';
import { NavMenuItem } from './NavMenuItem';

export const NavMenuGroup = ({ items }: NavMenuGroupProps) => {
  return (
    <StyledGroup>
      {items.map((item, key) => (
        <NavMenuItem key={`key-${key}`} {...item} />
      ))}
    </StyledGroup>
  );
};

const StyledGroup = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:nth-of-type(n + 2) {
    &:before {
      content: '';
      display: block;
      width: 40px;
      height: 0.5px;
      margin-block: ${({ theme }) => theme.sizes.m}px;
      background-color: ${({ theme }) => theme.palette.primary[600]};
    }
  }
`;
