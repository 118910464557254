import styled from '@emotion/styled';

import { getSizeRem } from '../Theme';

import { CardMenuItem } from './CardMenuItem';
import { CardMenuProps } from './types';

export const CardMenu = ({ items, ...props }: CardMenuProps) => {
  return (
    <StyledGroup {...props}>
      {items.map((item, key) => (
        <li key={`key-${key}`}>
          <CardMenuItem {...item} />
        </li>
      ))}
    </StyledGroup>
  );
};

const StyledGroup = styled.ul(
  ({ theme }) => `
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: ${getSizeRem(theme.sizes.s)};
  ${theme.mediaRules.up(theme.breakpoints.sm)} {
  gap: ${getSizeRem(theme.sizes.m)};
  }
  ${theme.mediaRules.up(theme.breakpoints.lg)} {
    gap: ${getSizeRem(theme.sizes.l)};
  }
  li {
    flex-basis: 100%;
    ${theme.mediaRules.up(theme.breakpoints.sm)} {
      flex-basis: calc((100% - ${getSizeRem(theme.sizes.m)}) / 2);
    }
    ${theme.mediaRules.up(theme.breakpoints.lg)} {
      flex-basis: calc((100% - ${getSizeRem(theme.sizes.l * 2)}) / 3);
    }
  }
`
);
