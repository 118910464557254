import { DetailedHTMLProps, HTMLAttributes, memo, useMemo } from 'react';
import styled from '@emotion/styled';

import { getSizeRem } from '../Theme';
import { Button } from '@/components/Core';

export interface PaginationProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  currentPage: number;
  handleChangePage: (page: number) => void;
  pages: number;
}

const StyledContainer = styled.div(
  ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${getSizeRem(theme.sizes.xs)};
  ul {
    list-style: none;
    display: flex;
    gap: ${getSizeRem(1)};
  }
  button {
    padding: ${getSizeRem(theme.sizes.xxs / 2)};
    min-width: ${getSizeRem(theme.sizes.l)};
    height: ${getSizeRem(theme.sizes.l)};
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    transition: border-color ${theme.transitionOptions};
    &:hover, &:focus {
      border-color: ${theme.palette.primary[50]};
    }
    &:disabled {
      border-color: transparent;
    }
  }
`
);

const StyledItem = styled.li<{ isCurrent: boolean }>(
  ({ theme, isCurrent }) => `
    &>button {
      background-color: ${isCurrent ? theme.palette.primary[100] : theme.palette.white};
      &:hover, &:focus {
        background-color: ${isCurrent ? theme.palette.primary[100] : theme.palette.primary[50]};;
      }
      &:disabled {
        background-color: ${isCurrent ? theme.palette.primary[100] : theme.palette.white};
        color: ${theme.palette.primary[600]};
      }
    }
  `
);

const PAGINATION_LIMIT = 9;
const DIFF = 2;
const VISIBLE_GROUPE = 5;
const STEP = 1;
const ELLIPSIS = '...';

export const Pagination = memo<PaginationProps>(function Pagination({
  currentPage,
  pages,
  handleChangePage,
  ...props
}) {
  const paginationItems: (number | string)[] = useMemo(() => {
    const items = [];
    if (pages <= PAGINATION_LIMIT) {
      for (let i = 1; i <= pages; i += 1) {
        items.push(i);
      }
    } else {
      const firstPage = 1;
      const lastPage = pages;
      items.push(firstPage);
      if (currentPage <= VISIBLE_GROUPE) {
        for (let i = 2; i <= VISIBLE_GROUPE + 2; i += 1) {
          items.push(i);
        }
        items.push(ELLIPSIS, lastPage);
      } else if (currentPage > VISIBLE_GROUPE && currentPage < lastPage - VISIBLE_GROUPE) {
        items.push(ELLIPSIS);
        for (let i = currentPage - DIFF; i <= currentPage + DIFF; i += 1) {
          items.push(i);
        }
        items.push(ELLIPSIS, lastPage);
      } else {
        items.push(ELLIPSIS);
        for (let i = lastPage - VISIBLE_GROUPE - 1; i <= lastPage; i += 1) {
          items.push(i);
        }
      }
    }
    return items;
  }, [currentPage, pages]);

  const isDisabled = (page: number | string) => typeof page === 'string' || page === currentPage;
  const onPaginationClick = (page: number | string) => {
    if (isDisabled(page)) return;
    handleChangePage(+page);
  };
  return (
    <StyledContainer {...props}>
      <Button
        icon="pagin-arrow-left"
        aria-label="previous page"
        variant="empty"
        color="primary"
        onClick={() => onPaginationClick(currentPage - STEP)}
        disabled={currentPage === 1}
      />
      <ul>
        {paginationItems.map((page, idx) => (
          <StyledItem isCurrent={currentPage === page} key={`pagination-${page}-${idx}`}>
            <Button color="secondary" onClick={() => onPaginationClick(page)} disabled={isDisabled(page)}>
              {page}
            </Button>
          </StyledItem>
        ))}
      </ul>
      <Button
        icon="pagin-arrow-right"
        aria-label="next page"
        variant="empty"
        color="primary"
        onClick={() => onPaginationClick(currentPage + STEP)}
        disabled={currentPage === pages || !pages}
      />
    </StyledContainer>
  );
});
