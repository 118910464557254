import { BREAKPOINTS, Theme, WEIGHT_VARIANTS } from './types';

export const theme: Theme = {
  palette: {
    white: '#FFFFFF',
    purple: '#9732FC',
    grey: {
      25: '#F5F7FA',
      50: '#ECF0F5',
      100: '#DDE4F0',
      200: '#BCC6D6',
      300: '#8E9AAD',
      400: '#626B7A',
      500: '#495361',
      600: '#2E3947',
      700: '#19222E',
      800: '#090E14',
      900: '#020305',
    },
    primary: {
      25: '#EBEFFF',
      50: '#CCD7FF',
      100: '#99AFFF',
      200: '#6688FF',
      300: '#0038FF',
      400: '#0034EB',
      500: '#002ED1',
      600: '#0028B8',
      700: '#00239E',
      800: '#001D85',
      900: '#000B31',
    },
    error: {
      25: '#FFEBEE',
      50: '#FFD9E0',
      100: '#FFB2C0',
      200: '#FF738C',
      300: '#FF4062',
      400: '#F8002D',
      500: '#DE0B31',
      600: '#C40A2B',
      700: '#99172E',
      800: '#781E2E',
      900: '#4A121C',
    },
    warning: {
      25: '#FEFFEB',
      50: '#FEFFD9',
      100: '#F8FAAF',
      200: '#F7FA7D',
      300: '#F5FA0C',
      400: '#EBEF0C',
      500: '#D5D90B',
      600: '#BCBF0A',
      700: '#969908',
      800: '#717306',
      900: '#353603',
    },
    success: {
      25: '#E1FAF8',
      50: '#AFFAF6',
      100: '#7DFAF3',
      200: '#49F2E8',
      300: '#16D9CC',
      400: '#00BFB3',
      500: '#08A69C',
      600: '#187A74',
      700: '#156661',
      800: '#0D403D',
      900: '#042826',
    },
  },
  sizes: {
    none: 0,
    xxs: 4,
    xs: 8,
    s: 12,
    m: 16,
    base: 20,
    l: 24,
    xl: 32,
    xxl: 48,
  },
  fontSizes: {
    xxs: 8,
    xs: 10,
    s: 12,
    m: 14,
    l: 16,
    xl: 20,
    xxl: 24,
    xxxl: 28,
  },
  fontWeights: WEIGHT_VARIANTS,
  transitionOptions: '0.15s ease-out',
  breakpoints: BREAKPOINTS,
  mediaRules: {
    up: (breakpoint: number, vertical = false) => `@media (min-${vertical ? 'height' : 'width'}: ${breakpoint}px)`,
    down: (breakpoint: number, vertical = false) =>
      `@media (max-${vertical ? 'height' : 'width'}: calc(${breakpoint}px - 0.02px))`,
    between: (breakpointMin: number, breakpointMax: number, vertical = false) =>
      `@media (max-${vertical ? 'height' : 'width'}: ${breakpointMax}px) and (min-${
        vertical ? 'height' : 'width'
      }: ${breakpointMin}px)`,
  },
};
