import { FunctionComponent, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Role, usePermission } from '@/hooks/usePermission';
import { userSelectors } from '@/store';

interface PermissionRequiredProps {
  currentUser?: boolean;
  roles: Role[];
  fallback: ReactElement | null;
  children: ReactElement | null;
}

export const PermissionRequired: FunctionComponent<PermissionRequiredProps> = ({
  currentUser,
  roles,
  fallback,
  children,
}) => {
  const { isValid } = usePermission(roles);
  const userId = useSelector(userSelectors.getCurrentId);
  const params = useParams<{
    id?: string;
  }>();

  if (isValid || (currentUser && userId && params?.id === String(userId))) {
    return children;
  }
  return fallback;
};
