import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHref, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/css';

import { Button, FlexGroup, Icon, Text } from '../Core';
import { useHideLoader } from '@/hooks';

export const ErrorFallback: FC = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const url = useHref(location.pathname);
  useHideLoader();
  const handleCLick = () => {
    window.location.href = url;
  };
  return (
    <StyledContainer direction="column" align="center" justify="center" gap="m">
      <Icon size="xl" type="alert" color="error" />
      <Text
        multiline
        align="center"
        size="m"
        className={css`
          max-width: 320px;
        `}
      >
        {t('errorFallback.note')}
      </Text>
      <Button color="secondary" onClick={handleCLick}>
        {t('errorFallback.reload')}
      </Button>
    </StyledContainer>
  );
};

const StyledContainer = styled(FlexGroup)`
  width: 100vw;
  height: 100vh;
`;
