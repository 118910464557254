import { createSlice } from '@reduxjs/toolkit';

import { State } from './types';
import { generateInvites, getItems } from './thunks';

const initialState: State = {
  items: [],
  page: 1,
  pages: 0,
  total: 0,
  availableInvites: 0,
  isLoading: true,
};

const slice = createSlice({
  name: 'invites',
  initialState,
  reducers: {
    clear: (state) => {
      state.items = [];
      state.page = 1;
      state.availableInvites = 0;
      state.isLoading = true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getItems.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getItems.fulfilled, (state, action) => {
        const { invites, available_invites, pages, total, page } = action.payload;
        state.items = invites;
        state.page = page;
        state.pages = pages;
        state.total = total;
        state.availableInvites = available_invites;
        state.isLoading = false;
      })
      .addCase(getItems.rejected, (state) => {
        state.items = [];
        state.page = 1;
        state.availableInvites = 0;
        state.isLoading = false;
      });
    builder.addCase(generateInvites.fulfilled, (state, action) => {
      const { invites, available_invites, pages, total, page } = action.payload;
      state.items = invites;
      state.availableInvites = available_invites;
      state.page = page;
      state.pages = pages;
      state.total = total;
      state.isLoading = false;
    });
  },
});

export const actions = {
  ...slice.actions,
  getItems,
  generateInvites,
};

export default slice.reducer;
