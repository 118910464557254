import { api } from '@/api';
import { createAppAsyncThunk } from '@/store/createAppAsyncThunk';
import { userActions, userSelectors } from '../user';

import { usersActions } from '.';

export const getItems = createAppAsyncThunk(
  'users/getItems',
  async (payload: Paths.AuthUsersList.Get.QueryParameters, thunkApi) => {
    try {
      const {
        data: { data },
      } = await api.getUsers(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const editById = createAppAsyncThunk('users/editById', async (payload: Payloads.UserEdit, thunkApi) => {
  try {
    await api.editUserById(payload);
    const {
      data: { data },
    } = await api.getUser({ user_id: payload.user_id });
    const authenticatedUserId = userSelectors.getCurrentId(thunkApi.getState());
    if (data.user.id === authenticatedUserId) {
      thunkApi.dispatch(userActions.update({ ...data.user, roles: data.roles }));
    }
    const {
      data: { data: userRegionsData },
    } = await api.getUserRegions({ ...payload, only_bind: true });
    return { userDetails: data, userRegionsData };
  } catch (error: any) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getById = createAppAsyncThunk('users/getById', async (payload: Payloads.UserPropertiesList, thunkApi) => {
  try {
    const {
      data: { data: userDetails },
    } = await api.getUser({ user_id: payload.user_id });
    const {
      data: { data: managedDevicesData },
    } = await api.getUserManagedDevices(payload);
    const {
      data: { data: invitedUsersData },
    } = await api.getUserInvited(payload);
    const {
      data: { data: userRegionsData },
    } = await api.getUserRegions({ ...payload, only_bind: true });
    return {
      userDetails,
      invitedUsersData,
      managedDevicesData,
      userRegionsData,
    };
  } catch (error: any) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getUserManagedDevices = createAppAsyncThunk(
  'users/getUserManagedDevices',
  async (payload: Payloads.UserPropertiesList, thunkApi) => {
    try {
      const {
        data: { data },
      } = await api.getUserManagedDevices(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getUserInvitedUsers = createAppAsyncThunk(
  'users/getUserInvitedUsers',
  async (payload: Payloads.UserPropertiesList, thunkApi) => {
    try {
      const {
        data: { data },
      } = await api.getUserInvited(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const addManagedDevices = createAppAsyncThunk(
  'users/addManagedDevices',
  async ({ devices_name_list, ...payload }: Payloads.AddManagedDevices, thunkApi) => {
    try {
      const { data } = await api.addUserManagedDevices({ user_id: payload.user_id, devices_name_list });
      thunkApi.dispatch(usersActions.getUserManagedDevices(payload));
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteManagedDevices = createAppAsyncThunk(
  'users/deleteManagedDevices',
  async ({ devices_id_list, ...payload }: Payloads.DeleteManagedDevices, thunkApi) => {
    try {
      await api.deleteUserManagedDevices({ user_id: payload.user_id, devices_id_list });
      const {
        data: { data },
      } = await api.getUserManagedDevices(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
