import { userReducer } from './slices/user';
import { devicesReducer } from './slices/devices';
import { devicesMapReducer } from './slices/devices-map';
import { invitesReducer } from './slices/invites';
import { realtimeReducer } from './slices/realtime-map';
import { regionsReducer } from './slices/regions';
import { rolesReducer } from './slices/roles';
import { usersReducer } from './slices/users';
import { demoReducer } from './slices/demo';
import { toastReducer } from './slices/toast';
import { eventStatisticReducer } from './slices/event-statistic';
import { deviceManagersReducer } from './slices/device-managers';
import { modalsReducer } from './slices/modals';

export const reducer = {
  user: userReducer,
  users: usersReducer,
  realtime: realtimeReducer,
  roles: rolesReducer,
  regions: regionsReducer,
  devices: devicesReducer,
  invites: invitesReducer,
  devicesMap: devicesMapReducer,
  demo: demoReducer,
  toast: toastReducer,
  eventStatistic: eventStatisticReducer,
  deviceManagers: deviceManagersReducer,
  modals: modalsReducer,
};
