const shortMonths = [2, 4, 6, 9, 11];

type Data = {
  day: number;
  month: number;
  year: number;
  currentDay: number;
  currentMonth: number;
  currentYear: number;
};
export const validateDay = ({ day, month, year, currentDay, currentMonth, currentYear }: Data): boolean => {
  return (
    (year >= currentYear && month >= currentMonth && day > currentDay) ||
    day > 31 ||
    (shortMonths.includes(month) && day > 30) ||
    (month === 2 && day > 29) ||
    (year % 4 !== 0 && month === 2 && day > 28)
  );
};

export const formatDate = (num: number): string => {
  return num.toString().padStart(2, '0');
};

export const getValidDay = ({
  month,
  year,
  currentDay,
  currentMonth,
  currentYear,
  dayFirstChart,
}: Omit<Data, 'day'> & { dayFirstChart: number }): string => {
  if (year === currentYear && month === currentMonth) {
    return formatDate(dayFirstChart > currentDay ? currentDay : dayFirstChart);
  } else if (dayFirstChart > 3) {
    return formatDate(dayFirstChart);
  } else if (month === 2) {
    return '28';
  } else {
    return '30';
  }
};
