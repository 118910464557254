import { useEffect } from 'react';
const elementLoader = document.getElementById('loader');

export const hiddenLoader = () => {
  if (elementLoader) {
    elementLoader.style.zIndex = '-1';
    elementLoader.style.opacity = '0';
  }
};

export const Loader = () => {
  useEffect(() => {
    if (elementLoader) {
      elementLoader.style.zIndex = '10004';
      elementLoader.style.opacity = '1';
    }

    return () => {
      if (elementLoader) {
        elementLoader.style.zIndex = '-1';
        elementLoader.style.opacity = '0';
      }
    };
  }, []);

  return null;
};
