import { FC, PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';

import { Role } from '@/hooks';
import { modalsActions } from '@/store/slices/modals';
import { PermissionRequired } from '../PermissionRequired';
import { Button, ButtonProps } from '../Core';

type MetaReportButtonProps = { report_id?: number };
type MetaHeartbeatButtonProps = { heartbeat_id?: number };

type MetaButtonProps = MetaReportButtonProps | MetaHeartbeatButtonProps;

export const MetaButton: FC<PropsWithChildren<MetaButtonProps & ButtonProps>> = ({
  color = 'secondary',
  px = 'xxs',
  py = 'xxs',
  textSize = 'xs',
  children = '?',
  ...props
}) => {
  const dispatch = useDispatch();

  const handleClickMeta = () => {
    const reportId = (props as MetaReportButtonProps).report_id;
    const heartbeatId = (props as MetaHeartbeatButtonProps).heartbeat_id;
    if (reportId) dispatch(modalsActions.setReport(reportId));
    else if (heartbeatId) dispatch(modalsActions.setHeartbeat(heartbeatId));
  };

  return (
    <PermissionRequired
      roles={[Role.ADMIN, Role.MODERATOR, Role.SUB_MODERATOR, Role.NETWORK, Role.DEMO]}
      fallback={null}
    >
      <Button {...props} color={color} textSize={textSize} px={px} py={py} onClick={handleClickMeta}>
        {children}
      </Button>
    </PermissionRequired>
  );
};
