import { KeyboardEvent } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

import { Text, Icon, FlexGroup, getSizeRem, Theme, Tooltip } from '@/components/Core';
import { usePermission } from '@/hooks/usePermission';
import { useIsOpenControl } from '@/hooks';

import { CardItemProps } from './types';

export const CardMenuItem = ({ to, icon, title, description, access, infoTooltip }: CardItemProps) => {
  const { isValid } = usePermission(access);
  const { isOpen: isVisible, open, close } = useIsOpenControl();
  const navigate = useNavigate();
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      navigate(to);
    }
  };

  if (!access || isValid) {
    return (
      <StyledLink onClick={() => navigate(to)} tabIndex={0} onKeyDown={handleKeyDown}>
        <FlexGroup align="center" gap="s" className="mb-xs">
          <IconWrapper>
            <Icon type={icon} size="base" color="secondary" />
          </IconWrapper>
          <Text weight="bold" size="l">
            {title}
          </Text>
        </FlexGroup>
        <Text>{description}</Text>
      </StyledLink>
    );
  }
  return (
    <StyledWrapper onMouseEnter={open} onMouseLeave={close}>
      <FlexGroup align="center" gap="s" className="mb-xs">
        <Tooltip
          isVisible={isVisible}
          variant="light"
          position="top-left"
          title={
            <FlexGroup>
              <Icon type="info-circle" color="error" size="xs" />
              <Text element="p" size="xxs" className="ml-xxs">
                {infoTooltip}
              </Text>
            </FlexGroup>
          }
        >
          <IconWrapper className="disabled">
            <Icon type={icon} size="base" color="grey300" />
          </IconWrapper>
        </Tooltip>
        <Text weight="bold" size="l">
          {title}
        </Text>
      </FlexGroup>
      <Text>{description}</Text>
    </StyledWrapper>
  );
};

const getBaseStyles = (theme: Theme) => {
  return `
    padding-top: ${getSizeRem(theme.sizes.base)};
    padding-bottom: ${getSizeRem(theme.sizes.s)};
    padding-inline: ${getSizeRem(theme.sizes.l)};
    border-radius: ${getSizeRem(theme.sizes.s / 2)};
    border-width: ${getSizeRem(1)};
    border-style: solid;
    height: 100%;
    `;
};
const StyledLink = styled.div(
  ({ theme }) => `
  ${getBaseStyles(theme)}
  cursor: pointer;
  color: ${theme.palette.grey[900]};
  border-color: ${theme.palette.primary[50]};
  transition: box-shadow ${theme.transitionOptions}, border-color ${theme.transitionOptions};
  &:hover, &:focus {
    box-shadow: 2px -6px 6px 0px #001D850D, 0px 12px 16px 0px #001D8514;
    border-color: ${theme.palette.primary[100]};
  }
`
);

const StyledWrapper = styled.div(
  ({ theme }) => `
  ${getBaseStyles(theme)}
    border-color: ${theme.palette.grey[100]};
    color: ${theme.palette.grey[300]};
  `
);

const IconWrapper = styled.span(
  ({ theme }) => `
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: ${getSizeRem(36)};
    height: ${getSizeRem(36)};
    background-color: ${theme.palette.primary[25]};
    border-radius: ${getSizeRem(theme.sizes.xxs)};
    &.disabled {
        background-color: ${theme.palette.grey[25]};
    }
`
);
