import styled from '@emotion/styled';
import { ChangeEventHandler, FC, useEffect, useRef, useState } from 'react';

import { Theme } from '../Theme';
import { icons } from '@/assets/icons';

const getTrackStyles = (theme: Theme) => `
  background: ${theme.palette.primary[50]};
  height: 4px;
  border-radius: 30px;
  background-image: linear-gradient(${theme.palette.primary[800]}, ${theme.palette.primary[800]});
  background-repeat: no-repeat;
  background-size: var(--progressSize) 100%;

`;

const getThumbStyles = (theme: Theme) => `
  width: 20px;
  height: 12px;
  border-radius: 30px;
  background: ${theme.palette.white};
  box-shadow: -2px 1px 3px 0px rgba(0, 29, 133, 0.10), 1px -1px 2px 0px rgba(0, 29, 133, 0.06);
`;

const StyledInput = styled.input<{ isShort?: boolean }>(({ value, theme, isShort }) => {
  return `
    --progressSize: ${value === undefined ? 50 : value}%;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: ${isShort ? '62px' : '124px'};
    outline: none;
    border-radius: 30px;



    &::-webkit-slider-runnable-track {
      ${getTrackStyles(theme)}
    }

    &::-moz-range-track {
      ${getTrackStyles(theme)}
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      margin-top: -4px;
      ${getThumbStyles(theme)}
    }

    &::-moz-range-thumb {
      border: none;
      ${getThumbStyles(theme)}
    }

    //TODO: add focus styles
  `;
});

const Wrapper = styled.div(
  ({ theme }) => `
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.sizes.m}px;
`
);
const Button = styled.div(
  ({ theme }) => `
  display: inline-flex;
  outline: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  //TODO: add focus styles

  & svg {
    width: ${theme.sizes.base}px;
    height: ${theme.sizes.base}px;
    fill: ${theme.palette.grey[900]};
  }
`
);

export interface SoundControlProps {
  value: number;
  onChange?: (v: number) => void;
  hasMuteBtn?: boolean;
  className?: string;
}

export const SoundControl: FC<SoundControlProps> = ({ value, onChange, hasMuteBtn, className }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isMute, setMute] = useState(false);
  const [volume, setVolume] = useState(value || 0);

  useEffect(() => {
    const element = inputRef.current;
    const handleChangeProgress = (e: Event) => {
      const target = e.target as HTMLInputElement;
      if (element && element.style) {
        element.style.setProperty('--progressSize', `${target.value}%`);
      }
    };
    if (element) {
      // element.style.setProperty('--progressSize', `${element.value}%`);
      element.addEventListener('input', handleChangeProgress);
    }

    return () => element?.removeEventListener('input', handleChangeProgress);
  }, []);

  useEffect(() => {
    if (onChange) {
      onChange(isMute ? 0 : volume);
    }
  }, [volume, isMute, onChange]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const vol = Math.max(0, Math.min(100, Number(e.target.value)));
    setVolume(vol);
  };

  const handleMute = () => {
    setMute((state) => !state);
  };

  const Icon = isMute || volume === 0 ? icons['volume-off'] : icons['volume-high'];

  return (
    <Wrapper className={className}>
      {hasMuteBtn && (
        <Button onClick={handleMute}>
          <Icon />
        </Button>
      )}
      <StyledInput ref={inputRef} type="range" value={volume} onChange={handleChange} isShort={hasMuteBtn} />
    </Wrapper>
  );
};
