import { FC, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { FontSizes, Spacing, getColorVariantByNameOrId, getSizeRem } from '../Theme';
import { Tag, Tooltip, TooltipsPositions } from '@/components/Core';

type Role = { id: number; name: string };

export interface RolesProps {
  data: Role[];
  gap?: Spacing;
  fontSize?: FontSizes;
  position?: TooltipsPositions;
}

const StyledList = styled.ul<{ gap: Spacing }>(
  ({ theme, gap }) => `
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: ${getSizeRem(theme.sizes[gap])};
    & li {
        cursor: help;
    }
`
);

export const Roles: FC<RolesProps> = ({ data, gap = 'xs', fontSize, position = 'right-center' }) => {
  const { t, i18n } = useTranslation();
  const [visibleTooltip, setVisibleTooltip] = useState('');
  const onMouse = (name: string) => {
    setVisibleTooltip(name);
  };
  return (
    <StyledList gap={gap}>
      {data.map(({ id, name }) => (
        <li key={id} onMouseEnter={() => onMouse(name)} onMouseLeave={() => setVisibleTooltip('')}>
          <Tooltip
            position={position}
            title={t(`roles.${name}.description`)}
            isVisible={name === visibleTooltip && i18n.exists(`roles.${name}.description`)}
            fontSize={fontSize}
          >
            <Tag color={getColorVariantByNameOrId(name)} variant="outlined" fontSize={fontSize}>
              {t(`roles.${name}.name`)}
            </Tag>
          </Tooltip>
        </li>
      ))}
    </StyledList>
  );
};
