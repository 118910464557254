import { api } from '@/api';
import { createAppAsyncThunk } from '@/store/createAppAsyncThunk';

export const getPoints = createAppAsyncThunk(
  'devices-map/getPoints',
  async (payload: Paths.PointsHeartbeatMap.Get.QueryParameters, thunkApi) => {
    try {
      const {
        data: { data: points },
      } = await api.getPointsHeartbeat(payload, thunkApi.signal);
      return points;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
