import { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react';
import styled from '@emotion/styled';

import { getSizeRem } from '../../Theme';
import { Icon } from '../../Icon';

export interface CheckboxFieldProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  children?: string | string[];
}

const StyledCheckboxWrapper = styled.label(
  ({ theme }) => `
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: ${getSizeRem(theme.sizes.s)};
    cursor: pointer;
    font-size: ${getSizeRem(theme.fontSizes.m)};

    &:hover {
        & input {
            & + span {
                background-color: ${theme.palette.primary[25]};
            }
         }
    }

    & input {
        appearance: none;
        border: none;
        outline: none;
        position: absolute;
        top: 0;
        left: 0;

        &:focus-visible + span { 
            box-shadow: ${theme.palette.primary[900]} 0px 0px 1px 1px;
        }

        & + span {
            display: inline-flex;
            width: ${getSizeRem(theme.sizes.m)};
            height: ${getSizeRem(theme.sizes.m)};
            border: 2px solid ${theme.palette.primary[50]};
            border-radius: 4px;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
        }

        &:checked + span {
          background-color: ${theme.palette.primary[300]};
          border-color: ${theme.palette.primary[300]};
        }

        &:disabled + span {
          background-color: ${theme.palette.grey[100]};
          border-color: ${theme.palette.grey[100]};
        }
    }

`
);

export const CheckboxField: FC<Omit<CheckboxFieldProps, 'type'>> = ({ children, checked, ...props }) => {
  return (
    <StyledCheckboxWrapper>
      <input {...props} checked={checked} type="checkbox" />
      <span>{checked && <Icon type="check" color="white" size="xs" />}</span>
      {children}
    </StyledCheckboxWrapper>
  );
};
