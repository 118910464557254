import { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react';
import styled from '@emotion/styled';

import { Spacing, getSizeRem } from '../../Theme';

export interface RadioFieldProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  children?: string | string[];
  py?: Spacing;
  px?: Spacing;
}

const StyledRadioWrapper = styled.label<{ py: Spacing; px: Spacing }>(
  ({ theme, py, px }) => `
    position: relative;
    display: inline-flex;
    align-items: center;
    padding-block: ${getSizeRem(theme.sizes[py])};
    padding-inline: ${getSizeRem(theme.sizes[px])};
    gap: ${getSizeRem(theme.sizes.s)};
    cursor: pointer;
    font-size: ${getSizeRem(theme.fontSizes.m)};

    &:hover {
        & input {
            & + span {
                background-color: ${theme.palette.primary[25]};
            }
         }
    }

    & input {
        appearance: none;
        border: none;
        outline: none;
        position: absolute;
        top: 0;
        left: 0;

        &:focus-visible + span {
            box-shadow: ${theme.palette.primary[900]} 0px 0px 1px 1px;
        }

        & + span {
            display: inline-flex;
            width: ${getSizeRem(theme.sizes.m)};
            height: ${getSizeRem(theme.sizes.m)};
            border: 2px solid ${theme.palette.primary[50]};
            border-radius: 50%;
            align-items: center;
            justify-content: center;

            &:after {
                content: '';
                display: inline-block;
                width: ${getSizeRem(theme.sizes.xs)};
                height: ${getSizeRem(theme.sizes.xs)};
                border-radius: 50%;
            }
        }

        &:checked + span {
            &:after {
                background: ${theme.palette.primary[300]};
            }
        }
    }

`
);

export const RadioField: FC<Omit<RadioFieldProps, 'type'>> = ({ children, py = 'none', px = 'none', ...props }) => {
  return (
    <StyledRadioWrapper py={py} px={px}>
      <input {...props} type="radio" />
      <span />
      {children}
    </StyledRadioWrapper>
  );
};
