import { api } from '@/api';
import { createAppAsyncThunk } from '@/store/createAppAsyncThunk';

export const getItems = createAppAsyncThunk(
  'device-managers/getItems',
  async (payload: Paths.AuthUsersList.Get.QueryParameters, thunkApi) => {
    try {
      const {
        data: { data },
      } = await api.getDevicesManager(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
