import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const resource = require(`./locales/${process.env.REACT_APP_LOCALE}`);

const language = process.env.REACT_APP_LOCALE;

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      [language]: {
        translation: resource,
      },
    },
    fallbackLng: language,
    supportedLngs: [language],
    keySeparator: '.',
    interpolation: {
      escapeValue: true,
    },
  });

export default i18n;
