import { createSlice } from '@reduxjs/toolkit';

import { State } from './types';
import { getItems } from './thunks';

const initialState: State = {
  items: [],
  pages: 0,
  total: 0,
  isLoading: true,
};

const slice = createSlice({
  name: 'device-managers',
  initialState,
  reducers: {
    clear: (state) => {
      state.items = [];
      state.isLoading = true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getItems.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getItems.fulfilled, (state, action) => {
        const {
          payload: { users, pages, total },
        } = action;
        state.isLoading = false;
        state.items = users;
        state.pages = pages;
        state.total = total;
      });
  },
});

export const actions = {
  ...slice.actions,
  getItems,
};

export default slice.reducer;
