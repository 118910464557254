import type { ReduxState } from '../../store';

export const getItems = (state: ReduxState) => state.users.items;
export const getLoading = (state: ReduxState) => state.users.isLoading;
export const getUserDetails = (state: ReduxState) => state.users.userDetails;
export const getPages = (state: ReduxState) => state.users.pages;
export const getTotalUsers = (state: ReduxState) => state.users.total;
export const getUserManagedDevicesData = (state: ReduxState) => state.users.managedDevices;
export const getUserInvitedData = (state: ReduxState) => state.users.invitedUsers;
export const getUserRegions = (state: ReduxState) => state.users.userRegions;
