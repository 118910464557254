import { createContext, Dispatch, SetStateAction } from 'react';

import { Spacing } from '@/components/Core';
import { DEFAULT_SIGMA_MOVING_AVERAGE } from '../Points/EventPoint/helper';

type HeartBeatConfig = {
  radius: number;
  stroke: number;
};

export type TrackConfig = {
  isShowPathTrajectories: boolean;
  isOnlyLiveTrajectories: boolean;
  isShowedLabel: boolean;
  isShowAudioPoint: boolean;
  isShowNotAudioPoint: boolean;
  isUseFilterSpyDrones: boolean;
  altitudeLevel: number;
  connectionDistance: number;
  maxTimeAlive: number;
  sigmaMovingAverage: Types.SigmaMovingAverage;
  trackIconSize: Spacing;
  predictionLifeTime: {
    drone: number | null;
    rocket: number | null;
    spy_drone: number | null;
  };
};

type OtherConfig = {
  isShowBattleLine: boolean;
};

export type MapConfigForm = {
  heartbeat: HeartBeatConfig;
  track: TrackConfig;
  other: OtherConfig;
};

interface MapConfigContextType extends MapConfigForm {
  setConfig: Dispatch<SetStateAction<MapConfigForm>>;
}

const DEFAULT_HEARTBEAT_CONFIG: HeartBeatConfig = {
  radius: 500,
  stroke: 0,
};

const DEFAULT_TRACK_CONFIG: TrackConfig = {
  isShowPathTrajectories: false,
  isOnlyLiveTrajectories: true,
  isShowedLabel: false,
  isShowAudioPoint: true,
  isShowNotAudioPoint: false,
  isUseFilterSpyDrones: true,
  altitudeLevel: 12,
  connectionDistance: 0,
  maxTimeAlive: 0,
  sigmaMovingAverage: DEFAULT_SIGMA_MOVING_AVERAGE,
  trackIconSize: 'xl',
  predictionLifeTime: {
    rocket: null,
    drone: null,
    spy_drone: null,
  },
};

const DEFAULT_OTHER_CONFIG: OtherConfig = {
  isShowBattleLine: false,
};

export const DEFAULT_MAP_CONFIG = {
  heartbeat: DEFAULT_HEARTBEAT_CONFIG,
  track: DEFAULT_TRACK_CONFIG,
  other: DEFAULT_OTHER_CONFIG,
};

export const MapConfigContext = createContext<MapConfigContextType>({
  ...DEFAULT_MAP_CONFIG,
  setConfig: () => null,
});
