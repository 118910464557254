import { api } from '@/api';
import { createAppAsyncThunk } from '@/store/createAppAsyncThunk';

export const getItems = createAppAsyncThunk(
  'invites/getItems',
  async (payload: Paths.AuthInvitesList.Get.QueryParameters, thunkApi) => {
    try {
      const { data } = await api.getInvites(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const generateInvites = createAppAsyncThunk(
  'invites/generateInvites',
  async (
    { invites_number, ...payload }: Payloads.GenerateInvites & Paths.AuthInvitesList.Get.QueryParameters,
    thunkApi
  ) => {
    try {
      await api.generateInvites({ invites_number });
      const { data } = await api.getInvites(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
