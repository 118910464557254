import { Navigate, Outlet } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Routes } from '@/routes';
import { useDispatch, useSelector, userActions, userSelectors } from '@/store';
import { PageLayout, StyledPageContainer } from '../PageLayout';
import { PageLoader, ToastVariantBasic, ToastVariantWithText } from '@/components/Core';
import { roleSelectors, rolesActions } from '@/store/slices/roles';
import { regionsActions, regionsSelectors } from '@/store/slices/regions';
import { Role, usePermission } from '@/hooks/usePermission';
import { toastActions } from '@/store/slices/toast';

const TIMEOUT = 5000;

export const AuthRequired = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(userSelectors.getAuthenticate);
  const isUserLoading = useSelector(userSelectors.getLoading);
  const isRoleLoading = useSelector(roleSelectors.getIsLoading);
  const isRegionLoading = useSelector(regionsSelectors.getIsLoading);
  const isLoading = isUserLoading || isRoleLoading || isRegionLoading;
  const { isValid } = usePermission([Role.ADMIN]);
  const { t } = useTranslation();

  const timeOutId = useRef<NodeJS.Timeout | null>(null);
  const count = useRef<number>(1);

  useEffect(() => {
    const retry = async () => {
      if (timeOutId.current) {
        clearTimeout(timeOutId.current);
        timeOutId.current = null;
      }
      try {
        await dispatch(userActions.getCurrent()).unwrap();
        dispatch(rolesActions.getRoles());
      } catch (error: any) {
        if (error.response?.status != 401 && error.response?.status != 403) {
          timeOutId.current = setTimeout(retry, TIMEOUT * count.current);
          count.current += 1;
          if (error.message !== 'Network Error') {
            dispatch(
              toastActions.create({ type: ToastVariantWithText.ERROR_ACTION, text: t('toast.content.server_error') })
            );
          } else {
            dispatch(toastActions.create({ type: ToastVariantBasic.NETWORK_ERROR }));
          }
        }
      }
    };
    if (isAuthenticated) {
      retry();
    } else {
      dispatch(userActions.logout());
    }
    return () => {
      if (timeOutId.current) clearTimeout(timeOutId.current);
    };
  }, [dispatch, isAuthenticated, t]);

  useEffect(() => {
    if (isAuthenticated && isValid) {
      dispatch(regionsActions.getRegions());
    }
  }, [dispatch, isAuthenticated, isValid]);

  if (isAuthenticated)
    return isLoading ? (
      <StyledPageContainer>
        <PageLoader />
      </StyledPageContainer>
    ) : (
      <PageLayout>
        <Outlet />
      </PageLayout>
    );

  const redirectUrl = `${window.location.pathname}${window.location.search}`;
  const navigateTo = `${Routes.SIGN_IN}/?redirect=${redirectUrl}`;

  return <Navigate to={navigateTo} replace />;
};
