import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { FC } from 'react';

import { Theme } from '../Theme';
import { Icon } from '../Icon';
import { Popper } from '../Popper';
import { useIsOpenControl } from '@/hooks';

import { NavButtonMenuItemProps, NavLinkMenuItemProps, NavMenuItemProps, NavPopperMenuItemProps } from './types';

const NavMenuPopperItem: FC<Omit<NavPopperMenuItemProps, 'access'>> = ({ icon, children, ...props }) => {
  const { isOpen, open, close } = useIsOpenControl();

  return (
    <Popper
      {...props}
      isOpen={isOpen}
      anchor={
        <StyledButton onClick={open}>
          <Icon type={icon} color="white" />
        </StyledButton>
      }
      close={() => close()}
    >
      {children}
    </Popper>
  );
};

export const NavMenuItem = ({ icon, ...props }: NavMenuItemProps) => {
  if (typeof (props as NavPopperMenuItemProps).children !== 'undefined') {
    return <NavMenuPopperItem {...(props as NavPopperMenuItemProps)} icon={icon} />;
  }

  if (typeof (props as NavLinkMenuItemProps).to !== 'undefined') {
    return (
      <StyledNav {...(props as NavLinkMenuItemProps)} className={({ isActive }) => (isActive ? 'active' : '')}>
        <Icon type={icon} color="white" />
      </StyledNav>
    );
  }
  return (
    <StyledButton {...(props as NavButtonMenuItemProps)} type="button">
      <Icon type={icon} color="white" />
    </StyledButton>
  );
};

const baseStyles = (theme: Theme) => `
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${theme.sizes.xl * 2}px;
  padding: ${theme.sizes.xs}px;
  transition: background 0.2s ease-in;
  border: none;
  outline: none;

  &:hover {
    background-color: ${theme.palette.primary[800]};
  }

 
`;

const StyledNav = styled(NavLink)(
  ({ theme }) => `
  ${baseStyles(theme)}

  &.active {
      background-color: ${theme.palette.primary[400]};
  }
`
);

const StyledButton = styled.button(
  ({ theme }) => `
  ${baseStyles(theme)}

  background-color: transparent;
  cursor: pointer;
`
);
