import { createSelector } from '@reduxjs/toolkit';

import type { ReduxState } from '../../store';

export const getItems = (state: ReduxState) => state.devices.items;
export const getLoading = (state: ReduxState) => state.devices.isLoading;
export const getPages = (state: ReduxState) => state.devices.pages;
export const getTotalDevices = (state: ReduxState) => state.devices.total;
export const getDeviceDetails = (state: ReduxState) => state.devices.deviceDetails;
export const getDeviceManagers = (state: ReduxState) => state.devices.deviceManagers;
export const getDeviceReportsData = (state: ReduxState) => state.devices.deviceReports;
export const getDeviceHeartbeatsData = (state: ReduxState) => state.devices.deviceHeartbeats;
export const getDeviceUserAgentsData = (state: ReduxState) => state.devices.deviceUserAgents;

export const getDeviceRealID = createSelector(
  [getDeviceHeartbeatsData],
  (data) => data?.heartbeats?.find((heartbeat) => heartbeat)?.device_id || null
);
