import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import { stackoverflowLight as style } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { Loader, ToastVariantBasic } from '../Core';
import { useDispatch, useSelector } from '@/store';
import { modalsActions, modalsSelectors } from '@/store/slices/modals';
import { toastActions } from '@/store/slices/toast';

export const HeartbeatModalContent: FC<{
  heartbeat_id: number;
  close: () => void;
}> = ({ heartbeat_id, close }) => {
  const dispatch = useDispatch();
  const currentAction = useRef<any>(null);
  const data = useSelector(modalsSelectors.getHeartbeat);
  const [isLoading, setLoading] = useState<boolean>(!!data);

  const handleLoadData = useCallback(async () => {
    try {
      setLoading(true);
      const action = (currentAction.current = dispatch(modalsActions.getHeartbeat({ heartbeat_id })));
      await action.unwrap();
    } catch (e: any) {
      if (e.name !== 'AbortError') {
        close();
        dispatch(
          toastActions.create({
            type: ToastVariantBasic.UNKNOWN_ERROR,
          })
        );
      }
    } finally {
      setLoading(false);
    }
  }, [close, dispatch, heartbeat_id]);

  useEffect(() => {
    if (!data) {
      handleLoadData();
    }

    return () => {
      if (currentAction.current) {
        currentAction.current.abort();
      }
    };
  }, [handleLoadData, data]);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <SyntaxHighlighter language="json" wrapLines wrapLongLines style={style}>
      {JSON.stringify(data, null, 2)}
    </SyntaxHighlighter>
  );
};
