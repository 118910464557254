import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { useDispatch, useSelector } from '@/store';
import { toastActions, toastSelectors } from '@/store/slices/toast';
import { Text } from '../Text';
import { getScrollStyles, getSizeRem } from '../Theme';
import { FlexGroup } from '../Flex';
import { Button } from '../Button';

import { Toast, ToastVariantBasic, ToastVariantWithText } from './types';
import { ToastConfigs } from './config';
import { ToastCard } from './Toast';

export const ToastProvider = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const timersRef = useRef(new Map<string, NodeJS.Timer>());

  const toasts = useSelector(toastSelectors.getItems);

  useEffect(() => {
    toasts
      .filter(({ id }) => !timersRef.current.get(id))
      .forEach(({ id, type }) => {
        const config = ToastConfigs.get(type);
        if (config && config.duration) {
          const timerId = setTimeout(() => {
            dispatch(toastActions.remove(id));
            timersRef.current.delete(id);
          }, config.duration);

          timersRef.current.set(id, timerId);
        }
      });
  }, [toasts, timersRef, dispatch]);

  useEffect(() => {
    if (containerRef.current && containerRef.current.lastChild) {
      const lastItem = containerRef.current.lastChild as HTMLDivElement;
      lastItem.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [toasts]);

  const handleClose = useCallback(
    (id: string) => {
      dispatch(toastActions.remove(id));
    },
    [dispatch]
  );

  // useEffect(() => {
  //   dispatch(
  //     toastActions.create({
  //       type: ToastVariantBasic.NEW_VERSION,
  //     })
  //   );
  // }, [dispatch]);

  const handleReload = useCallback(() => {
    window.location.replace(window.location.href);
  }, []);

  const renderContentToast = useCallback(
    (data: Toast) => {
      const { type } = data;
      switch (type) {
        case ToastVariantBasic.UNKNOWN_ERROR:
        case ToastVariantBasic.NETWORK_ERROR:
        case ToastVariantBasic.SOCKET_DISCONNECT:
        case ToastVariantBasic.SOCKET_RECONNECT:
          return <Text size="s">{t(`toast.content.${type}`)}</Text>;
        case ToastVariantBasic.NEW_VERSION:
          return (
            <FlexGroup gap="xs" direction="column">
              <Text size="s">{t(`toast.content.${type}`)}</Text>
              <Button color="secondary" px="xs" textSize="s" py="s" onClick={handleReload}>
                {t('toast.button.reload')}
              </Button>
            </FlexGroup>
          );
        case ToastVariantWithText.SUCCESS_ACTION:
        case ToastVariantWithText.WARNING_ACTION:
        case ToastVariantWithText.ERROR_ACTION:
          return <Text size="s">{data.text}</Text>;
      }
    },
    [t, handleReload]
  );

  if (toasts.length === 0) return null;

  return (
    <ToastListContainer>
      {toasts.map((toast) => {
        const { type, id } = toast;
        const config = ToastConfigs.get(type);

        return config ? (
          <ToastCard
            icon={config.icon}
            onClose={config.disabledClose ? null : () => handleClose(id)}
            color={config.color}
            align={config.align}
            key={id}
          >
            {renderContentToast(toast)}
          </ToastCard>
        ) : null;
      })}
    </ToastListContainer>
  );
};

const ToastListContainer = styled.div(
  ({ theme }) => `
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 0;
    max-height: calc(100vh - ${getSizeRem(100)});
    position: fixed;
    padding-right: ${getSizeRem(10)};
    padding-bottom: ${getSizeRem(15)};
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column-reverse;
    gap: ${getSizeRem(theme.sizes.m)};
    z-index: 10001;
    ${getScrollStyles(theme)}
  `
);
