import { AnchorHTMLAttributes, ButtonHTMLAttributes, DetailedHTMLProps, FC, PropsWithChildren } from 'react';
import styled from '@emotion/styled';

import { IconType } from '../Icon';
import { ColorVariant, FontSizes, FontWeightsVariant, Spacing, Theme, getSizeRem } from '../Theme';
import { icons } from '@/assets/icons';

export const BUTTON_ICON_ALIGNS = ['left', 'right'] as const;
export const BUTTON_VARIANTS = ['filled', 'empty', 'outlined'] as const;

export type IconAlign = (typeof BUTTON_ICON_ALIGNS)[number];
type ButtonVariant = (typeof BUTTON_VARIANTS)[number];

export type RadiusVariant = 'none' | 'all' | 'top' | 'right' | 'bottom' | 'left' | 'round';
export interface ButtonBaseProps {
  loading?: boolean;
  variant?: ButtonVariant;
  color?: ColorVariant;
  icon?: IconType;
  iconAlign?: IconAlign;
  collapsed?: boolean;
  px?: Spacing;
  py?: Spacing;
  border?: string;
  radiusVariant?: RadiusVariant;
  radiusSize?: Spacing;
  textSize?: FontSizes;
  fullWidth?: boolean;
  weight?: FontWeightsVariant;
  growOnMobile?: boolean;
}

export type ButtonAsButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> &
  ButtonBaseProps & {
    element?: 'button';
  };

export type ButtonAsAnchorProps = DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> &
  ButtonBaseProps & {
    element: 'a';
  };

export type ButtonProps = ButtonAsButtonProps | ButtonAsAnchorProps;

const getColorStylesEmpty = (color: ColorVariant, theme: Theme) => {
  const baseVariantStyles = `
    padding: 0;
    background: none;
    border: none;
  `;
  switch (color) {
    case 'secondary':
    case 'primary':
      return `
        color: ${theme.palette.primary[600]};
        ${baseVariantStyles};

        & svg {
          fill: ${theme.palette.primary[600]};
        }

        &:hover {
          color: ${theme.palette.primary[700]};

          & svg {
            fill: ${theme.palette.primary[700]};
          }
        }

        &:active {
          color: ${theme.palette.primary[800]};

          & svg {
            fill: ${theme.palette.primary[800]};
          }
        }
      `;
    case 'error':
      return `
          color: ${theme.palette.error[400]};
          ${baseVariantStyles};

          & svg {
            fill: ${theme.palette.error[400]};
          }

          &:hover {
            color: ${theme.palette.error[400]};

            & svg {
              fill: ${theme.palette.error[400]};
            }
          }

          &:active {
            color: ${theme.palette.error[500]};

            & svg {
              fill: ${theme.palette.error[500]};
            }
          }
        `;
    default:
      return `
        color: ${theme.palette.grey[900]};
        ${baseVariantStyles};

        & svg {
          fill: ${theme.palette.grey[300]};
        }

        &:hover {
          color: ${theme.palette.grey[800]};

          & svg {
            fill: ${theme.palette.grey[800]};
          }
        }

        &:active {
          color: ${theme.palette.grey[700]};

          & svg {
            fill: ${theme.palette.grey[700]};
          }
        }
      `;
  }
};

const getColorStylesFilled = (color: ColorVariant, theme: Theme) => {
  switch (color) {
    case 'secondary':
      return `
        background: ${theme.palette.white};
        color: ${theme.palette.primary[600]};
        border-color: ${theme.palette.primary[50]};

        & svg {
          fill: ${theme.palette.primary[600]};
        }

        &:hover {
          background: ${theme.palette.primary[25]};
        }

        &:active {
          background: ${theme.palette.primary[50]};
          border-color: ${theme.palette.primary[200]};
        }
      `;
    case 'primary':
      return `
        color: ${theme.palette.white};
        border-color: ${theme.palette.primary[500]};
        background: ${theme.palette.primary[500]};

        & svg {
          fill: ${theme.palette.white};
        }

        &:hover {
          border-color: ${theme.palette.primary[600]};
          background: ${theme.palette.primary[600]};
        }

        &:active {
          border-color: ${theme.palette.primary[700]};
          background: ${theme.palette.primary[700]};
        }
      `;
    case 'error':
      return `
        color: ${theme.palette.white};
        border-color: ${theme.palette.error[500]};
        background: ${theme.palette.error[500]};

        & svg {
          fill: ${theme.palette.white};
        }

        &:hover {
          border-color: ${theme.palette.error[600]};
          background: ${theme.palette.error[600]};
        }

        &:active {
          border-color: ${theme.palette.error[700]};
          background: ${theme.palette.error[700]};
        }
      `;
    case 'warning':
      return `
        color: ${theme.palette.grey[900]};
        border-color: ${theme.palette.warning[300]};
        background: ${theme.palette.warning[300]};

        & svg {
          fill: ${theme.palette.grey[900]};
        }

        &:hover {
          border-color: ${theme.palette.warning[400]};
          background: ${theme.palette.warning[400]};
        }

        &:active {
          border-color: ${theme.palette.warning[600]};
          background: ${theme.palette.warning[600]};
        }
      `;
    case 'success':
      return `
        color: ${theme.palette.white};
        border-color: #00b000;
        background: #00b000;

        & svg {
          fill: ${theme.palette.white};
        }

        &:hover {
          border-color: #00a300;
          background: #00a300;
        }

        &:active {
          border-color: #019b01;
          background: #019b01;
        }
      `;
    default:
      return `
        color: ${theme.palette.grey[900]};
        background: ${theme.palette.white};
        border-color: ${theme.palette.grey[50]};

        & svg {
          fill: ${theme.palette.grey[900]};
        }

        &:hover {
          background: ${theme.palette.grey[25]};
        }

        &:active {
          background: ${theme.palette.grey[50]};
          border-color: ${theme.palette.grey[200]};
        }
      `;
  }
};

const getColorStylesOutlined = (color: ColorVariant, theme: Theme) => {
  switch (color) {
    case 'secondary':
      return `
        background: ${theme.palette.primary[25]};
        color: ${theme.palette.grey[600]};
        border-color: ${theme.palette.grey[100]};

        & svg {
          fill:${theme.palette.grey[600]};
        }

        &:hover {
          background: ${theme.palette.primary[100]};
        }

        &:active {
          background: ${theme.palette.primary[50]};
        }
      `;
    case 'primary':
      return `
        color: ${theme.palette.primary[600]};
        border-color: ${theme.palette.primary[600]};
        background: ${theme.palette.primary[25]};

        & svg {
          fill: ${theme.palette.primary[600]};
        }

        &:hover {
          background: ${theme.palette.primary[100]};
        }

        &:active {
          border-color: ${theme.palette.primary[900]};
          background: ${theme.palette.primary[100]};
        }
      `;
    case 'error':
      return `
        color: ${theme.palette.error[700]};
        border-color: ${theme.palette.error[700]};
        background: ${theme.palette.error[50]};

        & svg {
          fill: ${theme.palette.error[700]};
        }

        &:hover {
          background: ${theme.palette.error[100]};
        }

        &:active {
          background: ${theme.palette.error[200]};
        }
      `;
    case 'warning':
      return `
        color: ${theme.palette.warning[700]};
        border-color: ${theme.palette.warning[700]};
        background: ${theme.palette.warning[50]};

        & svg {
          fill: ${theme.palette.warning[700]};
        }

        &:hover {
          background: ${theme.palette.warning[100]};
        }

        &:active {
          background: ${theme.palette.warning[200]};
        }
      `;
    case 'success':
      return `
        color: ${theme.palette.success[800]};
        border-color: ${theme.palette.success[400]};
        background: ${theme.palette.success[50]};

        & svg {
          fill: ${theme.palette.grey[800]};
        }

        &:hover {
          background: ${theme.palette.success[100]};
        }

        &:active {
          background: ${theme.palette.success[200]};
        }
      `;
    default:
      return `
      color: ${theme.palette.grey[900]};
      border-color: ${theme.palette.white};
      background: ${theme.palette.white};

      & svg {
        fill: ${theme.palette.grey[900]};
      }

      &:hover {
        background: ${theme.palette.primary[100]};
        border-color: ${theme.palette.primary[100]};
      }

      &:active {
        border-color: ${theme.palette.primary[900]};
        background: ${theme.palette.primary[100]};
      }
    `;
  }
};

const getColorStylesByVarian = (variant: ButtonVariant) => {
  switch (variant) {
    case 'empty':
      return getColorStylesEmpty;
    case 'outlined':
      return getColorStylesOutlined;
    default:
      return getColorStylesFilled;
  }
};

const getSizeStyles = (theme: Theme, px: Spacing, py: Spacing, textSize: FontSizes) => {
  return `
    padding-inline: ${getSizeRem(theme.sizes[px])};
    padding-block: ${getSizeRem(theme.sizes[py] / 2)};
    font-size: ${getSizeRem(theme.fontSizes[textSize])};
    gap: ${getSizeRem(theme.sizes.xs)};
  `;
};

const getBorderRadiusStyles = (theme: Theme, radiusVariant: RadiusVariant, radiusSize: Spacing) => {
  const size = getSizeRem(theme.sizes[radiusSize]);
  switch (radiusVariant) {
    case 'all':
      return `border-radius: ${size};`;
    case 'top':
      return `
      border-top-left-radius: ${size};
      border-top-right-radius: ${size};
    `;
    case 'right':
      return `
      border-top-right-radius: ${size};
      border-bottom-right-radius: ${size};
    `;
    case 'bottom':
      return `
      border-bottom-left-radius: ${size};
      border-bottom-right-radius: ${size};
    `;
    case 'left':
      return `
      border-top-left-radius: ${size};
      border-bottom-left-radius: ${size};
    `;
    case 'round':
      return 'border-radius: 50%;';
    default:
      return '';
  }
};

const getAlignStyles = (align: IconAlign) => {
  switch (align) {
    case 'left':
      return `
        flex-direction: row;
      `;
    case 'right':
      return `
        flex-direction: row-reverse;
      `;
  }
};

const BaseStyle = ({
  color = 'default',
  variant = 'filled',
  iconAlign = 'left',
  theme,
  px = 'base',
  py = 'base',
  border = '1px solid',
  radiusVariant = 'all',
  radiusSize = 'xxs',
  textSize = 'm',
  weight = 'semibold',
  fullWidth = false,
  $disabled,
  growOnMobile = false,
}: Omit<StylesBaseProps<ButtonProps>, 'onClick'> & { theme: Theme }) => `
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background ${theme.transitionOptions}, border-color ${theme.transitionOptions};
  border: ${border};
  font-family: inherit;
  font-weight: ${theme.fontWeights[weight]};
  line-height: inherit;
  overflow: hidden;
  ${fullWidth ? `width: 100%;` : ''}
  ${getBorderRadiusStyles(theme, radiusVariant, radiusSize)}
  ${getSizeStyles(theme, px, py, textSize)}
  ${getColorStylesByVarian(variant)(color, theme)}
  ${getAlignStyles(iconAlign)}

  & svg {
    width: ${getSizeRem(theme.sizes.m)};
    height: ${getSizeRem(theme.sizes.m)};
    flex-shrink: 0;
  }

  ${theme.mediaRules.down(theme.breakpoints.sm)} {
    ${growOnMobile ? `width: 100%;` : ''}
  }

  ${
    $disabled &&
    `& {
      color: ${theme.palette.grey[300]};
      background: ${theme.palette.grey[50]};
      border-color: ${theme.palette.grey[50]};
      cursor: auto;
      transition: background 0s, border-color 0s;

      & svg {
        fill: ${theme.palette.grey[300]};
      }
    }`
  }
  
`;

type StylesBaseProps<T = unknown> = Omit<T, 'loading'> & {
  $disabled?: boolean;
};

const StyledButton = styled.button<StylesBaseProps<ButtonAsButtonProps>>(BaseStyle);
const StyledAnchor = styled.a<StylesBaseProps<ButtonAsAnchorProps>>(BaseStyle);

const LoaderIcon = styled(icons['loading'])`
  @keyframes loading {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  animation: loading 0.8s linear infinite;
`;

export const Button: FC<PropsWithChildren<ButtonProps>> = ({
  children,
  icon,
  loading = false,
  collapsed,
  element = 'button',
  ...props
}) => {
  const IconComponent = icon ? icons[icon] : null;

  const renderChildren = () => (
    <>
      {!!IconComponent && !loading && <IconComponent />}
      {loading && <LoaderIcon />}
      {(!IconComponent || !collapsed) && !!children && <span>{children}</span>}
    </>
  );

  if (element === 'a') return <StyledAnchor {...(props as ButtonAsAnchorProps)}>{renderChildren()}</StyledAnchor>;

  return (
    <StyledButton $disabled={(props as ButtonAsButtonProps).disabled && !loading} {...(props as ButtonAsButtonProps)}>
      {renderChildren()}
    </StyledButton>
  );
};
