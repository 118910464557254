import styled from '@emotion/styled';
import { CSSProperties, FC, ReactNode } from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

import { ColorVariant, FontSizes, Theme, WEIGHT_VARIANTS, getSizeRem } from '../Theme';

export interface RouterLinkProps extends LinkProps {
  size?: FontSizes;
  weight?: keyof typeof WEIGHT_VARIANTS;
  children: ReactNode;
  className?: string;
  color?: ColorVariant | string;
  decoration?: CSSProperties['textDecoration'];
  display?: CSSProperties['display'];
}

const StyledLink = styled(RouterLink)<RouterLinkProps>(
  ({ size = 'm', weight = 'semibold', display = 'flex', color = 'default', theme, decoration = 'underline' }) => `
      display: ${display};
      gap: ${getSizeRem(theme.sizes.s)};
      align-items: center;
      font-size: ${getSizeRem(theme.fontSizes[size])};
      font-weight: ${WEIGHT_VARIANTS[weight]};
      text-decoration: ${decoration};
      color: ${getColor(color, theme)}!important;
      transition: color ${theme.transitionOptions};
      svg {
        fill: currentColor;
      }
      &:hover, &:focus {
        color: ${theme.palette.primary[200]}!important;
      }
  `
);

const getColor = (color: ColorVariant | string, theme: Theme) => {
  switch (color) {
    case 'default':
      return `inherit`;
    case 'secondary':
      return ` ${theme.palette.grey[300]}`;
    case 'primary':
      return ` ${theme.palette.primary[600]}`;
    case 'error':
      return ` ${theme.palette.error[400]}`;
    case 'warning':
      return ` ${theme.palette.warning[300]}`;
    case 'success':
      return ` ${theme.palette.success[200]}`;
    default:
      return ` ${color}`;
  }
};

export const Link: FC<RouterLinkProps> = ({ children, ...props }) => <StyledLink {...props}>{children}</StyledLink>;
