import { memo, useCallback, useEffect } from 'react';
import styled from '@emotion/styled';

import { useDispatch, useSelector } from '@/store';
import { modalsActions, modalsSelectors } from '@/store/slices/modals';
import { useIsOpenControl } from '@/hooks';
import { Modal, getScrollStyles, getSizeRem } from '../Core';

import { ReportModalContent } from './ReportModalContent';
import { HeartbeatModalContent } from './HeartbeatModalContent';

export const Modals = memo(function Modals() {
  const dispatch = useDispatch();
  const reportId = useSelector(modalsSelectors.getReportId);
  const heartbeatId = useSelector(modalsSelectors.getHeartbeatId);

  const { isOpen, open, close } = useIsOpenControl();

  const handleClose = useCallback(() => {
    close();
    dispatch(modalsActions.clear());
  }, [close, dispatch]);

  useEffect(() => {
    if (reportId || heartbeatId) {
      open();
    }
  }, [reportId, heartbeatId, open]);

  return (
    <Modal isOpen={isOpen} close={handleClose}>
      <Wrapper>
        {reportId && <ReportModalContent report_id={reportId} close={handleClose} />}
        {heartbeatId && <HeartbeatModalContent heartbeat_id={heartbeatId} close={handleClose} />}
      </Wrapper>
    </Modal>
  );
});

const Wrapper = styled.div(
  ({ theme }) => `
        height: 90dvh;
        width: 600px;
        max-width: 100%;
        background-color: ${theme.palette.white};
        padding-block: ${getSizeRem(theme.sizes.xxl)};
        padding-inline: ${getSizeRem(theme.sizes.m)};
        max-height: 90dvh;
        overflow-y: scroll;
        overflow-x: hidden;

        ${getScrollStyles(theme)};
    `
);
