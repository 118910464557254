import styled from '@emotion/styled';
import { MouseEventHandler, useCallback, useEffect, useRef, useState, MouseEvent, TouchEvent } from 'react';
import throttle from 'lodash.throttle';
import { captureException } from '@sentry/react';
import { css } from '@emotion/css';

import { Icon } from '../Icon';
import { useAppTheme } from '@/hooks';
import { getSizeRem } from '../Theme';
import { api } from '@/api';
import { useDispatch } from '@/store';
import { toastActions } from '@/store/slices/toast';
import { ToastVariantBasic } from '../Toasts';
import { checkPosition } from '@/utils/checkPosition';

const StyledWrapper = styled.div(
  ({ theme }) => `
    padding-block: ${getSizeRem(theme.sizes.s / 2)};
    padding-inline: ${getSizeRem(theme.sizes.xs)};
    border: 1px solid ${theme.palette.primary[25]};
    border-radius: ${getSizeRem(theme.sizes.xxl)};
    display: flex;
    align-items: center;
    gap: ${getSizeRem(theme.sizes.m)};
    background-color: ${theme.palette.white};
  `
);

const StyledButton = styled.button(
  ({ theme }) => `
  height: ${getSizeRem(theme.sizes.base)};
  width: ${getSizeRem(theme.sizes.base)};
  display: inline-flex;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`
);

const StyledDownload = styled(StyledButton)(
  ({ theme }) => `
    border-bottom: 1px solid ${theme.palette.primary[200]};
  `
);

const AudioContainer = styled.div(
  ({ theme }) => `
  display: flex;
  align-items: center;
  min-width: 0;
  justify-content: space-between;
  width: 100%;
  gap: ${getSizeRem(theme.sizes.m)};
`
);

const Track = styled.div<{
  type: 'active' | 'disabled';
  right?: number;
}>(
  ({ type, right = 100, theme }) => `
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    border-radius: 2px;
    height: 4px;
    background: ${type === 'active' ? theme.palette.primary[200] : theme.palette.grey[100]};
    left: 0px;
    right: ${100 - right}%;
`
);

const TrackContainer = styled.div`
  flex: 1 1 100%;
  position: relative;
  cursor: pointer;
  height: 14px;
`;
const TouchableZone = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const download = (url: string, name: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = name;
  link.click();
};

const loaderClass = css`
  @keyframes loading {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  animation: loading 0.8s linear infinite;
`;

export const Audio = ({ report_id, full }: { report_id: number; full?: boolean }) => {
  const dispatch = useDispatch();

  const { palette } = useAppTheme();

  const frameAnimation = useRef<number | null>(null);
  const audio = useRef(document.createElement('audio'));
  const containerRef = useRef<HTMLDivElement | null>(null);
  const touchableZoneRef = useRef<HTMLDivElement | null>(null);
  const drag = useRef<boolean>(false);
  const range = useRef<{ position: number; width: number } | null>(null);

  const [status, setStatus] = useState<'play' | 'pause' | 'stop'>('stop');
  const [isMute, setMute] = useState(false);
  const [isLoadingDownload, setLoadingDownload] = useState(false);
  const [duration, setDuration] = useState(0);

  const fetchAudio = useCallback(
    async (callback: (url: string, name: string) => void) => {
      try {
        const { data, headers } = await api.getAudio({ report_id, full });
        const filename = headers['content-disposition'].split('filename=')[1];
        const url = URL.createObjectURL(data);

        callback(url, filename);
      } catch (error) {
        captureException(error);
        dispatch(
          toastActions.create({
            type: ToastVariantBasic.UNKNOWN_ERROR,
          })
        );
      }
    },
    [report_id, full, dispatch]
  );

  const handleDownload = useCallback(async () => {
    if (!audio.current.src) {
      try {
        setLoadingDownload(true);
        await fetchAudio(download);
      } finally {
        setLoadingDownload(false);
      }
    } else {
      const filename = audio.current.getAttribute('name');
      if (filename) download(audio.current.src, filename);
    }
  }, [fetchAudio]);

  useEffect(() => {
    const animationId = frameAnimation.current;
    const player = audio.current;
    return () => {
      if (animationId) {
        cancelAnimationFrame(animationId);
      }
      if (!player.paused) {
        player.pause();
      }
    };
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateDuration = useCallback(
    throttle(() => {
      const value = (audio.current.currentTime / audio.current.duration) * 100;
      setDuration(Math.floor(value));
    }, 128),
    []
  );

  const onStart = () => {
    const animate = () => {
      if (containerRef.current && containerRef.current.offsetWidth && !range.current) {
        range.current = {
          position: containerRef.current.getBoundingClientRect().x,
          width: containerRef.current.offsetWidth,
        };
      }
      updateDuration();
      frameAnimation.current = requestAnimationFrame(animate);
    };

    animate();
  };

  const onEnd = () => {
    if (frameAnimation.current) cancelAnimationFrame(frameAnimation.current);
    setStatus('pause');
  };

  const handlePlay = () => {
    setStatus('play');

    if (!audio.current.src) {
      const callback = (url: string, name: string) => {
        audio.current.src = url;
        audio.current.setAttribute('name', name);
        audio.current.play();
        audio.current.onplaying = onStart;
        audio.current.onpause = onEnd;
      };
      fetchAudio(callback);
    } else {
      audio.current.play();
    }
  };

  const handlePause: MouseEventHandler<HTMLButtonElement> = () => {
    audio.current.pause();
  };

  const toggleMute = () => setMute((prev) => (audio.current.muted = !prev));

  const handleClick = (e: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => {
    if (containerRef.current) {
      const rangePosition = containerRef.current.getBoundingClientRect().x;
      const rangeWidth = containerRef.current.offsetWidth;
      if (!range.current || rangePosition !== range.current.position || rangeWidth !== range.current.width) {
        range.current = { position: rangePosition, width: rangeWidth };
      }
      const clientX =
        'changedTouches' in e
          ? (e as TouchEvent<HTMLDivElement>).changedTouches[0].clientX
          : (e as MouseEvent<HTMLDivElement>).clientX;
      const relativePosition = checkPosition((clientX - rangePosition) / rangeWidth);
      const newDuration = Math.floor(relativePosition * 100);
      if (audio.current.src) {
        audio.current.currentTime = relativePosition * audio.current.duration;
        setDuration(newDuration);
      } else {
        const initAudio = (url: string, name: string) => {
          audio.current.src = url;
          audio.current.setAttribute('name', name);
          audio.current.onplaying = onStart;
          audio.current.onpause = onEnd;
          audio.current.onloadeddata = () => {
            audio.current.currentTime = relativePosition * audio.current.duration;
            setDuration(newDuration);
          };
        };
        fetchAudio(initAudio);
      }
    }
  };

  const handleMove = (e: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => {
    if (range.current && drag.current && audio.current.src) {
      const clientX =
        'touches' in e
          ? (e as TouchEvent<HTMLDivElement>).changedTouches[0].clientX
          : (e as MouseEvent<HTMLDivElement>).clientX;
      const relativePosition = checkPosition((clientX - range.current.position) / range.current.width);
      const newDuration = Math.floor(relativePosition * 100);
      audio.current.currentTime = relativePosition * audio.current.duration;
      setDuration(newDuration);
    }
  };

  const handleStartDrag = () => {
    if (touchableZoneRef.current) {
      touchableZoneRef.current.style.width = '100vw';
      touchableZoneRef.current.style.height = '100vh';
    }
    drag.current = true;
  };

  const handleEndDrag = () => {
    if (touchableZoneRef.current) {
      touchableZoneRef.current.style.width = '0';
      touchableZoneRef.current.style.height = '0';
    }
    drag.current = false;
  };

  return (
    <StyledWrapper>
      <AudioContainer onTouchStart={(e) => e.stopPropagation()} onMouseDown={(e) => e.stopPropagation()}>
        {status === 'play' ? (
          <StyledButton onClick={handlePause}>
            <Icon type="pause" size="s" color={palette.primary[200]} />
          </StyledButton>
        ) : (
          <StyledButton onClick={handlePlay}>
            <Icon type="play" size="s" color={palette.primary[200]} />
          </StyledButton>
        )}
        <TrackContainer
          ref={containerRef}
          onClick={handleClick}
          onMouseMove={handleMove}
          onMouseDown={handleStartDrag}
          onMouseUp={handleEndDrag}
          onTouchMove={handleMove}
          onTouchStart={handleStartDrag}
          onTouchEnd={handleEndDrag}
        >
          <TouchableZone
            ref={touchableZoneRef}
            onMouseMove={handleMove}
            onTouchMove={handleMove}
            onMouseUp={handleEndDrag}
            onTouchEnd={handleEndDrag}
          />
          <Track type="disabled" onTouchStart={handleClick} />
          <Track type="active" right={duration} onTouchStart={handleClick} />
        </TrackContainer>
        <StyledButton onClick={toggleMute}>
          <Icon type={isMute ? 'volume-off' : 'volume-high'} color={palette.primary[200]} />
        </StyledButton>
      </AudioContainer>
      <StyledDownload disabled={isLoadingDownload} onClick={handleDownload}>
        <Icon
          className={isLoadingDownload ? loaderClass : ''}
          type={isLoadingDownload ? 'loading' : 'arrow-down'}
          size="m"
          color={palette.primary[200]}
        />
      </StyledDownload>
    </StyledWrapper>
  );
};
