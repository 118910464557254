import { FC } from 'react';
import styled from '@emotion/styled';

import { FontSizes, Spacing, getSizeRem } from '../../Theme';
import { Button } from '@/components/Core';

export interface MultiSelectControllerProps {
  title: string;
  onClick: () => void;
  isOpen: boolean;
  px?: Spacing;
  py?: Spacing;
  textSize?: FontSizes;
}

const getOpenStyles = (isOpen: boolean) => {
  if (isOpen) {
    return `
    border-bottom: none; 
    border-bottom-left-radius: 0; 
    border-bottom-right-radius: 0;
    svg {
      transform: rotate(180deg);
    }
    `;
  }
};

const StyledWrapper = styled.div<{ isOpen: boolean }>(
  ({ theme, isOpen }) => `
  & button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    color: ${theme.palette.grey[300]};
    padding-inline: ${getSizeRem(theme.sizes.s)};
    ${getOpenStyles(isOpen)}
  }
  `
);

export const MultiSelectController: FC<MultiSelectControllerProps> = ({ title, onClick, isOpen, ...props }) => {
  return (
    <StyledWrapper isOpen={isOpen}>
      <Button
        variant="filled"
        color="secondary"
        type="button"
        icon="menu-down"
        iconAlign="right"
        onClick={onClick}
        {...props}
      >
        {title}
      </Button>
    </StyledWrapper>
  );
};
