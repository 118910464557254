import styled from '@emotion/styled';
import { CSSProperties, FC, PropsWithChildren, createElement } from 'react';

import { Spacing, getSizeRem } from '../Theme';

import { hasProperty } from './util';
import { Border } from './FlexGroup';

interface FlexItemProps extends PropsWithChildren {
  element?: 'span' | 'div';
  grow?: CSSProperties['flexGrow'];
  shrink?: CSSProperties['flexShrink'];
  basis?: CSSProperties['flexBasis'];
  self?: CSSProperties['alignSelf'];
  maxwidth?: CSSProperties['maxWidth'];
  border?: Border;
  borderradius?: Spacing;
  className?: string;
  growOnMobile?: boolean;
}

const Basic: FC<FlexItemProps> = ({ element = 'div', ...props }) => createElement(element, props);

const StyledItem = styled(Basic)<FlexItemProps>(
  ({ grow = 1, shrink, basis, self, maxwidth, border, borderradius, theme, growOnMobile }) => `
        flex-grow: ${grow};
        ${theme.mediaRules.down(theme.breakpoints.sm)} {
          ${growOnMobile ? 'flex-grow: 1;' : ''}
        }
        ${hasProperty(shrink, `flex-shrink: ${shrink};`)}
        ${hasProperty(basis, `flex-basis: ${basis};`)}
        ${hasProperty(self, `align-self: ${self};`)}
        ${hasProperty(maxwidth, `max-width: ${maxwidth};`)}
        ${hasProperty(border, `${border}: 1px solid ${theme.palette.primary[25]};`)}
        ${hasProperty(borderradius, `border-radius: ${getSizeRem(theme.sizes[borderradius as Spacing])};`)}
    `
);

export const FlexItem: FC<FlexItemProps> = (props) => {
  return <StyledItem {...props} />;
};
