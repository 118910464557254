import { FC, PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { CSSProperties } from '@emotion/serialize';

import { ColorVariant, Theme, getSizeRem } from '../Theme';
import { Icon, IconType } from '../Icon';
import { FlexGroup } from '../Flex';
import { Button } from '../Button';
import { useAppTheme } from '@/hooks';
interface ToastCardProps extends PropsWithChildren {
  icon: IconType;
  color: ColorVariant;
  align?: CSSProperties['alignItems'];
  onClose: null | (() => void);
}

export const ToastCard: FC<ToastCardProps> = ({ icon, children, color, align = '', onClose }) => {
  const theme = useAppTheme();
  return (
    <Container color={color} align={align as string} gap="xs">
      <Icon type={icon} size="m" color={getIconColor(color, theme)} />
      <div>{children}</div>
      {onClose && <Button py="none" onClick={onClose} icon="close" variant="empty" />}
    </Container>
  );
};

const Container = styled(FlexGroup)<{
  color: ColorVariant;
}>(
  ({ color, theme }) => `
    animation: blinkToast 0.2s linear;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    border-radius: ${getSizeRem(theme.sizes.xs)};
    padding: ${getSizeRem(theme.sizes.l)};
    width: 280px;
    border: 1px solid;
    opacity: 0.5;
    ${getColorContainerStyles(color, theme)}

    & div {
        color: ${theme.palette.grey[900]};
        flex: 1 1 100%;
    }

    @keyframes blinkToast {
      from: {
        opacity: 0.5;
      }
      to {
        opacity: 1;
      }
    }
`
);

const getColorContainerStyles = (color: ColorVariant, theme: Theme) => {
  switch (color) {
    case 'error':
      return `
            background-color: ${theme.palette.error[50]};
            border-color: ${theme.palette.error[500]};
        `;
    case 'warning':
      return `
            background-color: ${theme.palette.warning[25]};
            border-color: ${theme.palette.warning[500]};
        `;
    case 'success':
      return `
            background-color: ${theme.palette.success[25]};
            border-color: ${theme.palette.success[500]};
        `;

    case 'default':
    case 'secondary':
    case 'primary':
      return `
            background-color: ${theme.palette.white};
            border-color: ${theme.palette.grey[50]};
        `;
  }
};
const getIconColor = (color: ColorVariant, theme: Theme) => {
  switch (color) {
    case 'error':
      return theme.palette.error[500];
    case 'warning':
      return theme.palette.warning[500];
    case 'success':
      return theme.palette.success[500];
    case 'default':
    case 'secondary':
    case 'primary':
    default:
      return theme.palette.grey[900];
  }
};
