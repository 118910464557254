import { RefObject, useEffect, createContext, useContext } from 'react';
interface ClickOutsideContextData {
  element: HTMLElement | null;
}

export const ClickOutsideContext = createContext<ClickOutsideContextData>({
  element: null,
});

export const useOnClickOutside = <T extends HTMLElement>(ref: RefObject<T>, handleClick: () => void) => {
  const { element } = useContext(ClickOutsideContext);

  useEffect(() => {
    const onOutsideClick = (event: MouseEvent | TouchEvent) => {
      if (ref.current && ref.current.contains(event.target as Node)) {
        return;
      }
      handleClick();
    };
    document.addEventListener('click', onOutsideClick);
    document.addEventListener('touchstart', onOutsideClick);
    if (element) {
      element.addEventListener('click', onOutsideClick);
      element.addEventListener('touchstart', onOutsideClick);
    }
    return () => {
      document.removeEventListener('click', onOutsideClick);
      document.removeEventListener('touchstart', onOutsideClick);
      if (element) {
        element.removeEventListener('click', onOutsideClick);
        element.removeEventListener('touchstart', onOutsideClick);
      }
    };
  }, [handleClick, ref, element]);
};
