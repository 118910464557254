import { ChangeEvent, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import styled from '@emotion/styled';
import { css } from '@emotion/css';

import { useIsOpenControl } from '@/hooks';
import { TextField } from '../Fields';
import { POPPER_PLACEMENT, Popper } from '../Popper';
import { ScrollContainer } from '../ScrollContainer';
import { Button, ButtonProps } from '../Button';
import { getSizeRem } from '../Theme';
import { FlexGroup } from '../Flex';
import { formatDate } from '../DatePicker/utils';

import { getItems } from './utils';

const StyledWrapper = styled.div(
  ({ theme }) => `
  display: flex;
  justify-content: start;
  ${theme.mediaRules.up(theme.breakpoints.xs)} {
    justify-content: space-around;
  }
  background-color: ${theme.palette.white};
  border: 1px solid ${theme.palette.primary[50]};
  border-radius: ${getSizeRem(theme.sizes.xxs)};
  max-height: 15rem;
  
`
);

const StyledButton = styled(Button)<Omit<ButtonProps, 'onClick'> & { isSelected: boolean }>(
  ({ theme, isSelected }) => `
  ${isSelected ? `background-color: ${theme.palette.primary[100]}` : ''}
  `
);

const MAX_MINUTES = 59;
const MAX_HOURS = 23;
const HOURS = getItems(MAX_HOURS);
const MINUTES = getItems(MAX_MINUTES);

export interface TimePickerProps {
  value?: string; //hh:mm
  onChange: (value: string) => void;
  placeholder?: string;
  isError?: boolean;
}

export const TimePicker = ({ value, onChange, placeholder = '--:--', isError }: TimePickerProps) => {
  const [input, setInput] = useState<string | undefined>('');
  const [selectHour, setSelectHour] = useState<string | undefined>('');
  const [selectMinutes, setSelectMinutes] = useState<string | undefined>('');
  const { isOpen, close, open } = useIsOpenControl();

  useEffect(() => {
    if (value) {
      const [hours, minutes] = value.split(':');
      setSelectHour(hours);
      setSelectMinutes(minutes);
      setInput(value);
    } else {
      setSelectHour('');
      setSelectMinutes('');
      setInput('');
    }
  }, [value]);

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === placeholder) onChange('');
    const [hh, mm] = value.split(':');
    const hourFirstChart = Number(hh?.charAt(0));
    const hours = Number(hh);
    const minutes = Number(mm);

    if (hourFirstChart > 2 || hours > MAX_HOURS) {
      const validHours = hourFirstChart > 2 ? formatDate(hourFirstChart) : `${MAX_HOURS}`;
      const validMinutes = !isNaN(minutes) ? mm : '';
      const time = `${formatDate(hourFirstChart)}:${validMinutes}`;
      setInput(time);
      if (validMinutes) {
        onChange(time);
      } else {
        setSelectHour(validHours);
      }
    } else if (minutes > MAX_MINUTES) {
      setInput(`${hh}:${MAX_MINUTES}`);
      if (isNaN(hours)) {
        setSelectMinutes(`${MAX_MINUTES}`);
      } else {
        onChange(`${hh}:${MAX_MINUTES}`);
      }
    } else {
      setInput(value);
      if (!isNaN(hours) && !isNaN(minutes)) {
        onChange(value);
      } else {
        if (!isNaN(hours)) setSelectHour(hh);
        if (!isNaN(minutes)) setSelectMinutes(mm);
      }
    }
  };

  const onHoursClick = (hour: string) => {
    const time = hour + ':' + (selectMinutes || '00');
    onChange(time);
  };

  const onMinutesClick = (minute: string) => {
    const time = (selectHour || '00') + ':' + minute;
    onChange(time);
  };

  return (
    <Popper
      anchor={
        <InputMask mask="99:99" onFocus={open} onChange={handleInput} value={input} maskPlaceholder={placeholder}>
          <TextField
            placeholder={placeholder}
            px="m"
            py="s"
            fontSize="s"
            className={css`
              max-width: 5.5rem;
            `}
            isError={isError}
          />
        </InputMask>
      }
      isOpen={isOpen}
      placement={POPPER_PLACEMENT.B_START}
      close={close}
    >
      <StyledWrapper>
        <ScrollContainer>
          <FlexGroup direction="column">
            {HOURS.map((hour) => (
              <StyledButton
                key={`hour-${hour}`}
                isSelected={hour === selectHour}
                variant="filled"
                color="secondary"
                px="s"
                py="xs"
                border="none"
                onClick={() => onHoursClick(hour)}
              >
                {hour}
              </StyledButton>
            ))}
          </FlexGroup>
        </ScrollContainer>
        <ScrollContainer>
          <FlexGroup direction="column">
            {MINUTES.map((minute) => (
              <StyledButton
                key={`minute-${minute}`}
                isSelected={minute === selectMinutes}
                variant="filled"
                color="secondary"
                px="s"
                py="xs"
                border="none"
                onClick={() => onMinutesClick(minute)}
              >
                {minute}
              </StyledButton>
            ))}
          </FlexGroup>
        </ScrollContainer>
      </StyledWrapper>
    </Popper>
  );
};
