import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';

export const PageCenteredContent = ({ children }: PropsWithChildren) => <StyledContainer>{children}</StyledContainer>;

const StyledContainer = styled.section`
  flex: 1 1 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
