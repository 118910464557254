import { FC, Fragment, ReactNode, useMemo } from 'react';
import styled from '@emotion/styled';

import { TableProps } from './types';
import { Cell, HeadCell } from './Cell';
import { Row } from './Row';

export const Table: FC<TableProps> = ({
  columns,
  rowCount,
  renderCell,
  renderHeaderCell,
  rowsColors = [],
  cellProps,
}) => {
  const cellCount = columns.length;

  const data = useMemo(() => {
    const items: ReactNode[][] = [];
    for (let i = 0; i < rowCount; i++) {
      items[i] = [];
      for (let j = 0; j < cellCount; j++) {
        items[i][j] = renderCell({
          rowIndex: i,
          cellIndex: j,
          columnId: columns[j].id,
        });
      }
    }
    return items;
  }, [rowCount, cellCount, columns, renderCell]);

  return (
    <StyledTable>
      <thead>
        <Row>
          {columns.map(({ id, title, width }) => (
            <HeadCell
              key={id}
              style={{
                width,
              }}
              paddingInline={cellProps?.hpx}
              paddingBlock={cellProps?.hpy}
            >
              {renderHeaderCell({ title, columnId: id })}
            </HeadCell>
          ))}
        </Row>
      </thead>
      <tbody>
        {data.map((cells, rowIndex) => (
          <Fragment key={`row-${rowIndex}`}>
            <Row isEven={rowIndex % 2 !== 0} bg={rowsColors[rowIndex]}>
              {cells.map((cell, cellIndex) => (
                <Cell
                  className={columns[cellIndex]?.className}
                  paddingInline={cellProps?.px}
                  paddingBlock={cellProps?.py}
                  key={`cell-${cellIndex}`}
                >
                  {cell}
                </Cell>
              ))}
            </Row>
          </Fragment>
        ))}
      </tbody>
    </StyledTable>
  );
};

const StyledTable = styled.table(
  ({ theme }) => `
    border-radius: ${theme.sizes.xxs}px;
    width: 100%;
    border-spacing: 0;
    border: 1px solid ${theme.palette.primary[50]};
  `
);
