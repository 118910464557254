import { useCallback, useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { Mutex } from 'async-mutex';

import { useDispatch, useSelector } from '@/store';
import { toastActions, toastSelectors } from '@/store/slices/toast';
import { ToastVariantBasic } from '../Core';

export const semverGreaterThan = (versionA: string, versionB: string) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());

    if (a === b) continue;

    return a > b || isNaN(b);
  }
  return false;
};

const mutex = new Mutex();

export const CacheChecker = () => {
  const dispatch = useDispatch();
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const toastVersion = useSelector(toastSelectors.getByType(ToastVariantBasic.NEW_VERSION));

  const bootstrap = useCallback(async () => {
    if (mutex.isLocked()) {
      mutex.cancel();
    }
    const release = await mutex.acquire();
    try {
      const meta: AxiosResponse<{
        version: string;
      }> = await axios.get('/meta.json', {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        },
      });

      if (semverGreaterThan(meta.data.version, process.env.REACT_APP_VERSION_NUMBER as string)) setShouldRefresh(true);
    } catch (e: any) {
      console.warn('Cannot check version: ' + e?.message);
    } finally {
      release();
    }
  }, []);

  useEffect(() => {
    bootstrap();
    const timeoutId = setInterval(
      () => {
        bootstrap();
      },
      60 * 10 * 1000
    );

    return () => {
      clearInterval(timeoutId);
    };
  }, [bootstrap]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') addEventListener('focus', bootstrap);

    return () => {
      if (process.env.NODE_ENV !== 'development') removeEventListener('focus', bootstrap);
    };
  }, [bootstrap]);

  useEffect(() => {
    if (shouldRefresh && !toastVersion)
      dispatch(
        toastActions.create({
          type: ToastVariantBasic.NEW_VERSION,
        })
      );
  }, [dispatch, shouldRefresh, toastVersion]);

  return null;
};
