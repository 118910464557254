import { CSSProperties } from 'react';

export const WEIGHT_VARIANTS = {
  regular: 400,
  bolder: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
};
export const BREAKPOINTS = {
  xs: 575,
  sm: 768,
  md: 991,
  lg: 1180,
};
export type FontWeightsVariant = keyof typeof WEIGHT_VARIANTS;
export type BreakpointsVariant = keyof typeof BREAKPOINTS;

export const FIELD_BORDER_VARIANTS = ['outlined', 'filled'] as const;
export type FieldBorderVariant = (typeof FIELD_BORDER_VARIANTS)[number];

export const COLOR_VARIANTS = ['default', 'secondary', 'primary', 'error', 'warning', 'success'] as const;

type ColorSystem = 'grey' | 'primary' | 'error' | 'warning' | 'success';
export type ColorLevel = 25 | 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
export type ColorVariant = (typeof COLOR_VARIANTS)[number];

export const SPACING = ['none', 'xxs', 'xs', 's', 'base', 'm', 'l', 'xl', 'xxl'] as const;
export type Spacing = (typeof SPACING)[number];

export const FONT_SIZES = ['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl', 'xxxl'] as const;
export type FontSizes = (typeof FONT_SIZES)[number];
export interface Theme {
  palette: Record<ColorSystem, Record<ColorLevel, CSSProperties['color']>> &
    Record<'white', CSSProperties['color']> &
    Record<'purple', CSSProperties['color']>;
  sizes: Record<Spacing, number>;
  fontSizes: Record<FontSizes, number>;
  fontWeights: Record<FontWeightsVariant, number>;
  transitionOptions: string;
  breakpoints: Record<BreakpointsVariant, number>;
  mediaRules: {
    up: (bp: number, vertical?: boolean) => string;
    down: (bp: number, vertical?: boolean) => string;
    between: (bpMin: number, bpMax: number, vertical?: boolean) => string;
  };
}
