import { api } from '@/api';
import { createAppAsyncThunk } from '@/store/createAppAsyncThunk';

export const getEventsList = createAppAsyncThunk('event-statistic/getEventsList', async (_, thunkApi) => {
  try {
    const { data } = await api.getPointsHcEventsList(thunkApi.signal);
    return data.event_list;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error);
  }
});
