import { DetailedHTMLProps, FC, ReactNode, TextareaHTMLAttributes } from 'react';
import styled from '@emotion/styled';

import { FieldBorderVariant, getBorderStylesByVariant, getSizeRem } from '../../Theme';

export interface TextAriaProps
  extends DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  label?: ReactNode;
  variant?: FieldBorderVariant;
  isError?: boolean;
}

const StyledTextWrapper = styled.label<{ variant: FieldBorderVariant; isError: boolean }>(
  ({ theme, variant, isError }) => `
    display: flex;
    flex-direction: column;
    gap: ${getSizeRem(theme.sizes.s)};
    cursor: pointer;
    font-size: ${getSizeRem(theme.fontSizes.m)};
    font-weight: 800;
    font-family: inherit;
    line-height: inherit;
    color: ${isError ? theme.palette.error[300] : theme.palette.primary[600]};

    & textarea {
        resize: none;
        outline: none;
        border: none;
        flex-grow: 1;
        border-radius: ${getSizeRem(theme.sizes.xxs)};
        padding-block: ${getSizeRem(theme.sizes.base / 2)};
        padding-inline: ${getSizeRem(theme.sizes.s)};
        font-size: inherit;
        line-height: inherit;
        font-weight: 400;
        font-family: inherit;
        color: ${isError ? theme.palette.error[300] : theme.palette.grey[900]};
        transition: box-shadow ${theme.transitionOptions}, border-color ${theme.transitionOptions};
        ${getBorderStylesByVariant(variant, theme, false, isError)}

        &::placeholder {
          color: ${theme.palette.grey[200]};
      }

        &:disabled {
          border-color: ${theme.palette.grey[100]};
          background-color: ${theme.palette.white};
      }
      
    }
`
);

export const TextAria: FC<TextAriaProps> = ({ label, variant = 'outlined', isError = false, ...props }) => {
  return (
    <StyledTextWrapper variant={variant} isError={isError}>
      {label}
      <textarea {...props} />
    </StyledTextWrapper>
  );
};
