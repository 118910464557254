import { createSlice } from '@reduxjs/toolkit';

import { State } from './types';
import {
  editById,
  getById,
  getItems,
  getUserManagedDevices,
  getUserInvitedUsers,
  addManagedDevices,
  deleteManagedDevices,
} from './thunks';

const initialState: State = {
  items: [],
  pages: 0,
  total: 0,
  userDetails: null,
  isLoading: true,
  managedDevices: null,
  invitedUsers: null,
  userRegions: [],
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    clear: (state) => {
      state.items = [];
      state.isLoading = true;
    },
    clearUserDetails: (state) => {
      state.userDetails = null;
      state.managedDevices = null;
      state.invitedUsers = null;
      state.userRegions = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getItems.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getItems.fulfilled, (state, action) => {
        const {
          payload: { users, pages, total },
        } = action;
        state.isLoading = false;
        state.items = users;
        state.pages = pages;
        state.total = total;
      });

    builder.addCase(editById.fulfilled, (state, action) => {
      const { userDetails, userRegionsData } = action.payload;
      state.userDetails = userDetails;
      state.userRegions = userRegionsData.regions;
    });

    builder
      .addCase(getById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getById.fulfilled, (state, action) => {
        const { userDetails, invitedUsersData, managedDevicesData, userRegionsData } = action.payload;
        state.isLoading = false;
        state.userDetails = userDetails;
        state.invitedUsers = invitedUsersData;
        state.managedDevices = managedDevicesData;
        state.userRegions = userRegionsData.regions;
      })
      .addCase(getById.rejected, (state) => {
        state.isLoading = false;
      });

    builder.addCase(getUserManagedDevices.fulfilled, (state, action) => {
      state.managedDevices = action.payload;
    });

    builder.addCase(getUserInvitedUsers.fulfilled, (state, action) => {
      state.invitedUsers = action.payload;
    });
    builder.addCase(deleteManagedDevices.fulfilled, (state, action) => {
      state.managedDevices = action.payload;
    });
  },
});

export const actions = {
  ...slice.actions,
  getItems,
  editById,
  getById,
  getUserManagedDevices,
  getUserInvitedUsers,
  addManagedDevices,
  deleteManagedDevices,
};

export default slice.reducer;
