import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useTitle = (title: string, name?: string) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const previewsTitle = document.title;
    document.title = t(title, { name });

    return () => {
      document.title = previewsTitle;
    };
  }, [title, t, name]);

  return {
    t,
    i18n,
  };
};
