import { api } from '@/api';
import { createAppAsyncThunk } from '@/store/createAppAsyncThunk';

export const getRoles = createAppAsyncThunk('roles/getRoles', async (_, thunkApi) => {
  try {
    const { data } = await api.getRoles();
    return data.data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error);
  }
});
