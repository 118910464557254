import { DetailedHTMLProps, FC, InputHTMLAttributes, ReactNode } from 'react';
import styled from '@emotion/styled';

import { FieldBorderVariant, FontSizes, Spacing, getBorderStylesByVariant, getSizeRem } from '../../Theme';

export interface TextFieldProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: ReactNode;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  variant?: FieldBorderVariant;
  px?: Spacing;
  py?: Spacing;
  fontSize?: FontSizes;
  isError?: boolean;
  fullWidth?: boolean;
}

const StyledTextWrapper = styled.label<{
  variant: FieldBorderVariant;
  px?: Spacing;
  py?: Spacing;
  fontSize?: FontSizes;
  isError?: boolean;
  fullWidth?: boolean;
}>(
  ({ theme, variant, px = 's', py = 'base', fontSize = 'm', isError = false, fullWidth = false }) => `
    position: relative;
    display: flex;
    flex-direction: column;
    gap: ${getSizeRem(theme.sizes.s)};
    cursor: pointer;
    font-size: ${getSizeRem(theme.fontSizes[fontSize])};
    font-weight: 800;
    font-family: inherit;
    color: ${isError ? theme.palette.error[300] : theme.palette.primary[600]};
   

    & > div {
      display: flex;
      align-items: center;
      transition: box-shadow ${theme.transitionOptions}, border-color ${theme.transitionOptions};
      ${getBorderStylesByVariant(variant, theme, true, isError)}
    }
    &:focus-within {
      svg{
        fill: ${theme.palette.grey[700]};
      }
    }

    & input {
      outline: none;
      border: none;
      flex-grow: 1;
      border-radius: ${getSizeRem(theme.sizes.xxs)};
      padding-block: ${getSizeRem(theme.sizes[py] / 2)};
      padding-inline: ${getSizeRem(theme.sizes[px])};
      font-size: inherit;
      font-weight: 400;
      font-family: inherit;
      ${fullWidth && 'width: 100%;'}
      color: ${isError ? theme.palette.error[300] : theme.palette.grey[900]};
      :hover, :focus {
        color: ${theme.palette.grey[700]};
      }
      &::placeholder {
        color: ${theme.palette.grey[200]};
      }

      &:disabled {
        border-color: ${theme.palette.grey[100]};
        background-color: ${theme.palette.white};
      }

    }
`
);

export const TextField: FC<TextFieldProps> = ({
  label,
  startAdornment,
  endAdornment,
  variant = 'outlined',
  px,
  py,
  fontSize,
  isError,
  fullWidth,
  ...props
}) => {
  return (
    <StyledTextWrapper variant={variant} px={px} py={py} fontSize={fontSize} isError={isError} fullWidth={fullWidth}>
      {label}
      <div>
        {startAdornment}
        <input {...props} />
        {endAdornment}
      </div>
    </StyledTextWrapper>
  );
};
