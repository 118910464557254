import { captureException } from '@sentry/react';
import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';

import { ErrorFallback } from './ErrorFallback';

export const ErrorElement = () => {
  const error = useRouteError();

  useEffect(() => {
    captureException(error);
  }, [error]);

  return <ErrorFallback />;
};
