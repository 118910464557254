import { CSSProperties, FC, FunctionComponent, SVGProps } from 'react';
import styled from '@emotion/styled';

import { icons } from '@/assets/icons';
import { ColorVariant, Spacing, Theme, getSizeRem } from '../Theme';

export type IconType = keyof typeof icons;
type IconColorVariant = ColorVariant | 'primary200' | 'grey300' | 'white';
export interface IconProps {
  color?: IconColorVariant | CSSProperties['fill'];
  size?: Spacing;
  type: IconType;
  className?: string;
}

const getColors = (color: IconColorVariant | CSSProperties['fill'], theme: Theme): CSSProperties['fill'] => {
  switch (color) {
    case 'primary':
      return theme.palette.primary[600];
    case 'primary200':
      return theme.palette.primary[200];
    case 'error':
      return theme.palette.error[500];
    case 'success':
      return theme.palette.success[200];
    case 'warning':
      return theme.palette.warning[300];
    case 'grey300':
      return theme.palette.grey[300];
    case 'white':
      return theme.palette.white;
    case 'default':
    case 'secondary':
      return theme.palette.grey[900];
    default:
      return color;
  }
};

const getSize = (size: Spacing, theme: Theme) => getSizeRem(theme.sizes[size]);

const IconStyled = (
  Icon: FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >
) =>
  styled(Icon)<{ color: IconColorVariant | CSSProperties['fill']; size: Spacing }>(
    ({ theme, color, size }) => `
  fill: ${getColors(color, theme)};
  width: ${getSize(size, theme)};
  height: ${getSize(size, theme)};
  pointer-events: none;
`
  );

export const Icon: FC<IconProps> = ({ color = 'default', size = 'base', type, className }) => {
  const Component = IconStyled(icons[type]);
  return <Component color={color} size={size} className={className} />;
};
