import { FC, PropsWithChildren } from 'react';
import styled from '@emotion/styled';

import { getScrollStyles } from '../Theme/utils';

export interface ContainerProps extends PropsWithChildren {
  className?: string;
}

const StyledContainer = styled.div(
  ({ theme }) => `
  overflow: auto;

  ${getScrollStyles(theme)}
`
);

export const ScrollContainer: FC<ContainerProps> = (props) => {
  return <StyledContainer {...props} />;
};
