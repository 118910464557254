import { api } from '@/api';
import { createAppAsyncThunk } from '@/store/createAppAsyncThunk';

export const getReport = createAppAsyncThunk(
  'modals/getReport',
  async (payload: Paths.PointsReport$ReportId.Get.PathParameters, thunkApi) => {
    try {
      const {
        data: { data },
      } = await api.getReportById(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getHeartbeat = createAppAsyncThunk(
  'modals/getHeartbeat',
  async (payload: Paths.PointsHeartbeat$HeartbeatId.Get.PathParameters, thunkApi) => {
    try {
      const {
        data: { data },
      } = await api.getHeartbeatById(payload);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
