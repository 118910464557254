/* eslint-disable @typescript-eslint/ban-ts-comment */
import { captureException } from '@sentry/react';
import { FC, PropsWithChildren, useLayoutEffect } from 'react';

import { semverGreaterThan } from '../Layouts/CacheChecker';
import { setLocalStorageValue } from '@/utils/localStorage';
import { DEFAULT_MAP_CONFIG, MapConfigForm, TrackConfig } from '../Map/MapConfig/MapConfigContext';

const STORAGE_VERSION_CHECKER_KEY = 'STORAGE_VERSION';
let lastVersion = localStorage.getItem(STORAGE_VERSION_CHECKER_KEY);

const migrations: Record<string, () => void> = {
  '1': () => {
    const settings: MapConfigForm = DEFAULT_MAP_CONFIG;

    const track: [string, keyof TrackConfig][] = [
      ['SHOW_PATH_TRAJECTORIES', 'isShowPathTrajectories'],
      ['ONLY_LIVE_TRAJECTORIES', 'isOnlyLiveTrajectories'],
      ['trajectories-label', 'isShowedLabel'],
      ['sigma-moving-average-object', 'sigmaMovingAverage'],
      ['connection-distance', 'connectionDistance'],
      ['not-audio-points', 'isShowNotAudioPoint'],
      ['audio-points', 'isShowAudioPoint'],
      ['track-icon-size', 'trackIconSize'],
      ['altitude-level', 'altitudeLevel'],
      ['use-filter-spy-drones', 'isUseFilterSpyDrones'],
    ];

    track.forEach(([storageKey, valueKey]) => {
      const savedValue = getStorageParsedValue(storageKey);
      if (savedValue !== null) {
        //@ts-ignore
        settings.track[valueKey] = savedValue;
        localStorage.removeItem(storageKey);
      }
    });

    const heartbeat = getStorageParsedValue('heartbeat-point-config');
    if (heartbeat) {
      settings.heartbeat = { ...settings.heartbeat, ...heartbeat };
      localStorage.removeItem('heartbeat-point-config');
    }
    setLocalStorageValue('settings', JSON.stringify(settings));
  },
};

const getStorageParsedValue = (key: string) => {
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : null;
};

export const StorageUpdater: FC<PropsWithChildren> = ({ children }) => {
  useLayoutEffect(() => {
    Object.entries(migrations).forEach(([version, update]) => {
      if (!lastVersion || semverGreaterThan(version, lastVersion)) {
        try {
          update();
          console.log(`update local storage ${version}`);
        } catch (e) {
          captureException(e);
        }
        lastVersion = version;
        localStorage.setItem(STORAGE_VERSION_CHECKER_KEY, version);
      }
    });
  }, []);

  return <>{children}</>;
};
