import {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from '@emotion/styled';

import { Spacing, getSizeRem } from '../Theme';
import { useAppTheme, useOnClickOutside } from '@/hooks';

export enum POPPER_PLACEMENT {
  B_START = 'bottom-start',
  B_END = 'bottom-end',
  B_CENTER = 'bottom-center',
  T_START = 'top-start',
  T_END = 'top-end',
  T_CENTER = 'top-center',
  L_START = 'left-start',
  L_END = 'left-end',
  L_CENTER = 'left-center',
  R_START = 'right-start',
  R_END = 'right-end',
  R_CENTER = 'right-center',
}

type PositionType = { top?: number; left?: number; right?: number; bottom?: number };

type Placement = (typeof POPPER_PLACEMENT)[keyof typeof POPPER_PLACEMENT];
export interface PopperProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  isOpen: boolean;
  placement: Placement;
  gap?: Spacing;
  anchor: ReactNode;
  children: ReactNode;
  close: () => void;
}

export const Popper: FC<PropsWithChildren<PopperProps>> = ({
  anchor,
  children,
  placement,
  gap = 'none',
  isOpen,
  close,
  ...props
}) => {
  const [position, setPosition] = useState<PositionType>({});
  const refWrapper = useRef<HTMLDivElement>(null);
  const refContent = useRef<HTMLDivElement>(null);
  const theme = useAppTheme();
  const space = theme.sizes[gap];

  useOnClickOutside(refWrapper, close);

  useEffect(() => {
    const content = refContent.current;
    const wrapper = refWrapper.current;
    if (content && wrapper && isOpen) {
      const wrapperRect = wrapper.getBoundingClientRect();
      content.style.minWidth = `${wrapperRect.width}px`;
      const contentRect = content.getBoundingClientRect();

      setPosition(countPosition(wrapperRect, contentRect, placement, space));
    }
  }, [placement, space, isOpen]);

  return (
    <Wrapper ref={refWrapper} {...props}>
      {anchor}
      {isOpen && (
        <ContentWrapper {...position} ref={refContent}>
          {children}
        </ContentWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const ContentWrapper = styled.div<PositionType>(
  ({ top, bottom, left, right }) => `
  position: fixed;
  top: ${typeof top !== 'undefined' ? getSizeRem(top) : ''};
  left: ${typeof left !== 'undefined' ? getSizeRem(left) : ''};
  right: ${typeof right !== 'undefined' ? getSizeRem(right) : ''};
  bottom: ${typeof bottom !== 'undefined' ? getSizeRem(bottom) : ''};
  pointer-events: all;
  z-index: 200000;
`
);

const countPosition = (
  anchorRect: DOMRect,
  popperRect: DOMRect,
  placement: Placement,
  gap: number
): { top?: number; bottom?: number; left?: number; right?: number } => {
  switch (placement) {
    case POPPER_PLACEMENT.B_START:
      return { top: anchorRect.bottom + gap, left: anchorRect.left };
    case POPPER_PLACEMENT.B_END:
      return { top: anchorRect.bottom + gap, left: anchorRect.right - popperRect.width };
    case POPPER_PLACEMENT.B_CENTER:
      return { top: anchorRect.bottom + gap, left: anchorRect.left + (anchorRect.width - popperRect.width) / 2 };
    case POPPER_PLACEMENT.T_START:
      return { top: anchorRect.top - popperRect.height - gap, left: anchorRect.left };
    case POPPER_PLACEMENT.T_END:
      return { top: anchorRect.top - popperRect.height - gap, left: anchorRect.right - popperRect.width };
    case POPPER_PLACEMENT.T_CENTER:
      return {
        top: anchorRect.top - popperRect.height - gap,
        left: anchorRect.left + (anchorRect.width - popperRect.width) / 2,
      };
    case POPPER_PLACEMENT.L_START:
      return { top: anchorRect.top, left: anchorRect.left - popperRect.width - gap };
    case POPPER_PLACEMENT.L_END:
      return { top: anchorRect.bottom - popperRect.height, left: anchorRect.left - popperRect.width - gap };
    case POPPER_PLACEMENT.L_CENTER:
      return {
        top: anchorRect.top + anchorRect.height / 2 - popperRect.height / 2,
        left: anchorRect.left - popperRect.width - gap,
      };
    case POPPER_PLACEMENT.R_START:
      return { top: anchorRect.top, left: anchorRect.right + gap };
    case POPPER_PLACEMENT.R_END:
      return { top: anchorRect.bottom - popperRect.height, left: anchorRect.right + gap };
    case POPPER_PLACEMENT.R_CENTER:
      return { top: anchorRect.top + anchorRect.height / 2 - popperRect.height / 2, left: anchorRect.right + gap };
    default:
      return { top: anchorRect.bottom + gap, left: anchorRect.left };
  }
};
