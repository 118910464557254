import { ErrorBoundary } from '@sentry/react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import '@/i18next';

import { router } from './routes';
import { reduxStore } from './store';
import { GlobalStyle } from './components/GlobalStyle';
import { theme } from './components/Core/Theme/constant';
import { CacheChecker } from './components/Layouts/CacheChecker';
import { ToastProvider } from './components/Core';
import { Modals } from './components/Modals';
import { StorageUpdater } from './components/StorageUpdater';

function App() {
  return (
    <StorageUpdater>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID as string}>
          <ErrorBoundary fallback={() => <span>Error</span>}>
            <Provider store={reduxStore}>
              <RouterProvider router={router} />
              <Modals />
              <CacheChecker />
              <ToastProvider />
            </Provider>
          </ErrorBoundary>
        </GoogleOAuthProvider>
      </ThemeProvider>
    </StorageUpdater>
  );
}

export default App;
