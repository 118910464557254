import { createSlice } from '@reduxjs/toolkit';

import { State } from './types';
import {
  getItems,
  getById,
  editById,
  getDeviceManagers,
  getDeviceReports,
  getDeviceHeartbeats,
  getDeviceUserAgents,
} from './thunks';

const initialState: State = {
  items: [],
  pages: 0,
  total: 0,
  isLoading: true,
  deviceDetails: null,
  deviceManagers: [],
  deviceReports: null,
  deviceUserAgents: null,
  deviceHeartbeats: null,
};

const slice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    clear: (state) => {
      state.items = [];
      state.isLoading = true;
    },
    clearDeviceDetails: (state) => {
      state.deviceDetails = null;
      state.deviceManagers = [];
      state.deviceReports = null;
      state.deviceUserAgents = null;
      state.deviceHeartbeats = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getItems.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getItems.fulfilled, (state, action) => {
        const {
          payload: { devices, pages, total },
        } = action;
        state.isLoading = false;
        state.items = devices;
        state.pages = pages;
        state.total = total;
      });
    builder.addCase(editById.fulfilled, (state, action) => {
      state.deviceDetails = action.payload;
    });
    builder
      .addCase(getById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getById.fulfilled, (state, action) => {
        const { deviceDetails, reportsData, heartbeatsData, userAgentsData } = action.payload;
        state.isLoading = false;
        state.deviceDetails = deviceDetails;
        state.deviceHeartbeats = heartbeatsData;
        state.deviceReports = reportsData;
        state.deviceUserAgents = userAgentsData;
      })
      .addCase(getById.rejected, (state) => {
        state.isLoading = false;
      });
    builder.addCase(getDeviceManagers.fulfilled, (state, action) => {
      state.deviceManagers = action.payload;
    });
    builder.addCase(getDeviceReports.fulfilled, (state, action) => {
      state.deviceReports = action.payload;
    });
    builder.addCase(getDeviceHeartbeats.fulfilled, (state, action) => {
      state.deviceHeartbeats = action.payload;
    });
    builder.addCase(getDeviceUserAgents.fulfilled, (state, action) => {
      state.deviceUserAgents = action.payload;
    });
  },
});

export const actions = {
  ...slice.actions,
  getItems,
  getById,
  editById,
  getDeviceManagers,
  getDeviceReports,
  getDeviceHeartbeats,
  getDeviceUserAgents,
};

export default slice.reducer;
