import { isAxiosError } from 'axios';

import { api } from '@/api';
import { createAppAsyncThunk } from '@/store/createAppAsyncThunk';

import { realtimeActions } from '.';

export const getPoints = createAppAsyncThunk(
  'realtime-map/getPoints',
  async (payload: Paths.PointsRealtimeMap.Get.QueryParameters, thunkApi) => {
    try {
      const {
        data: { data },
      } = await api.getPointsRealtimeMap(payload, thunkApi.signal);
      if (!payload.date && !payload.time)
        thunkApi.dispatch(
          realtimeActions.updateSocketParams({
            ...payload,
            since_id: data?.autoupdate_params?.since_id,
            moderation_updates_since_id: data?.autoupdate_params?.moderation_updates_since_id,
          })
        );
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getDevices = createAppAsyncThunk(
  'realtime-map/getDevices',
  async (payload: Paths.PointsRealtimeHeartbeats.Get.QueryParameters, thunkApi) => {
    try {
      const {
        data: { data },
      } = await api.getDevicesRealtimeMap(payload, thunkApi.signal);

      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateEventStatus = createAppAsyncThunk(
  'realtime-map/updateEventStatus',
  async (
    payload: Paths.PointsModeration$ReportId.Post.BodyParameters & Paths.PointsModeration$ReportId.Post.PathParameters,
    thunkApi
  ) => {
    try {
      const {
        data: { data },
      } = await api.pointModeration(payload);
      return data;
    } catch (error: any) {
      if (isAxiosError(error)) {
        return thunkApi.rejectWithValue(error.response?.data);
      }
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateTrackStatus = createAppAsyncThunk(
  'realtime-map/updateTrackStatus',
  async (payload: Paths.PointsModerationEllipse$TrackLabel.Post.PathParameters, thunkApi) => {
    try {
      const { data } = await api.trackModeration(payload);
      return data;
    } catch (error: any) {
      if (isAxiosError(error)) {
        return thunkApi.rejectWithValue(error.response?.data);
      }
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const resetModerationEvent = createAppAsyncThunk(
  'realtime-map/resetModerationEvent',
  async (payload: Paths.PointsSendForModeration$ReportId.Post.PathParameters, thunkApi) => {
    try {
      const {
        data: { data },
      } = await api.sendPointForModeration(payload);
      return data;
    } catch (error: any) {
      if (isAxiosError(error)) {
        return thunkApi.rejectWithValue(error.response?.data);
      }
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const pointsTrajectoriesRecalculate = createAppAsyncThunk(
  'realtime-map/pointsTrajectoriesRecalculate',
  async (payload: Paths.PointsTrajectoriesRecalculate.Post.Parameters.Body, thunkApi) => {
    try {
      await api.pointsTrajectoriesRecalculate(payload);
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const setTrackStatus = createAppAsyncThunk(
  'realtime-map/setTrackStatus',
  async (
    payload: Paths.PointsTrackStatus$TrackLabel.Post.PathParameters &
      Paths.PointsTrackStatus$TrackLabel.Post.BodyParameters,
    thunkApi
  ) => {
    try {
      const {
        data: { track_status },
      } = await api.setTrackStatus(payload);
      return track_status;
    } catch (error: any) {
      if (isAxiosError(error)) {
        return thunkApi.rejectWithValue(error.response?.data);
      }
      return thunkApi.rejectWithValue(error);
    }
  }
);
