import { Theme } from '@emotion/react';
import styled from '@emotion/styled';

import { Spacing, getSizeRem } from '../Theme';

const getBaseStyles = (theme: Theme, paddingInline?: Spacing, paddingBlock?: Spacing) => `
  padding-inline: ${getSizeRem(theme.sizes[paddingInline || 'xs'])};
  padding-block: ${getSizeRem(theme.sizes[paddingBlock || 'xs'])};
  color: ${theme.palette.grey[900]};
  text-align: left;
  ${theme.mediaRules.up(theme.breakpoints.lg)} {
    padding-inline: ${getSizeRem(theme.sizes[paddingInline || 's'])};
    padding-block: ${getSizeRem(theme.sizes[paddingBlock || 's'])};
  }
`;

export const Cell = styled.td<{ paddingInline?: Spacing; paddingBlock?: Spacing }>(
  ({ theme, paddingInline, paddingBlock }) => `
  ${getBaseStyles(theme, paddingInline, paddingBlock)}
`
);

export const HeadCell = styled.th<{ paddingInline?: Spacing; paddingBlock?: Spacing }>(
  ({ theme, paddingInline, paddingBlock }) => `
  ${getBaseStyles(theme, paddingInline, paddingBlock)}
  background: ${theme.palette.primary[25]};
  font-weight: ${theme.fontWeights.extrabold};
  font-size: ${getSizeRem(theme.fontSizes.m)};
  text-wrap: nowrap;
`
);
