export enum Routes {
  HOME = '/',
  SIGN_IN = '/sign-in',
  SIGN_UP = `:inviteToken`,
  SIGN_OUT = '/sign-out',
  MAP = '/map',
  DEVICES_MAP = '/heartbeat-map',
  DEVICES = '/devices',
  REGIONS = '/regions',
  USERS = '/users',
  DEMO = '/demo',
  USER_DETAILS = '/users/:id',
  DEVICE_DETAILS = '/devices/:id',
  INVITES = '/invites',
  DEVICE_MANAGERS = '/device-managers',
  PREDICTIONS = '/predictions-map',
}
