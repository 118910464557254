import { PropsWithChildren, Suspense, memo, useCallback, useMemo, useState } from 'react';
import { Link, generatePath, useLinkClickHandler } from 'react-router-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Button,
  FlexGroup,
  FlexItem,
  Icon,
  NavMenu,
  NavMenuItemProps,
  NavMenuItems,
  POPPER_PLACEMENT,
  PageLoader,
  SideBar,
  getSizeRem,
} from '../Core';
import { Routes } from '@/routes';
import { Role, usePermission } from '@/hooks/usePermission';
import { userSelectors } from '@/store';

const accountMenuButtonStyles = css`
  justify-content: flex-start;
`;

export const PageLayout = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();

  const currentUserId = useSelector(userSelectors.getCurrentId) as number;

  const handleClickAccount = useLinkClickHandler(
    generatePath(Routes.USER_DETAILS, {
      id: `${currentUserId}`,
    })
  );
  const handleClickLogout = useLinkClickHandler(Routes.SIGN_OUT);

  const [collapsed, setCollapsed] = useState(false);

  const { validateAccess } = usePermission();

  const handleCollapse = useCallback(() => {
    setCollapsed((state) => !state);
  }, []);

  const menu = useMemo<NavMenuItems>(() => {
    const firstSection: NavMenuItemProps[] = [
      {
        to: Routes.HOME,
        icon: 'home',
      },
      {
        to: Routes.MAP,
        icon: 'map-clock',
        access: [Role.ADMIN, Role.REALTIME, Role.MODERATOR, Role.SUB_MODERATOR, Role.VERIFICATOR],
      },
      {
        to: Routes.PREDICTIONS,
        icon: 'road',
        access: [Role.ADMIN, Role.COMMAND_POST],
      },
      {
        to: Routes.DEVICES_MAP,
        icon: 'cellphone-marker',
        access: [Role.ADMIN, Role.SOUND_MASTER, Role.NETWORK],
      },
      {
        to: Routes.DEVICES,
        icon: 'devices',
        access: [Role.ADMIN, Role.SOUND_MASTER, Role.NETWORK],
      },
      {
        to: Routes.REGIONS,
        icon: 'regions-map',
        access: [Role.ADMIN],
      },
      {
        to: Routes.USERS,
        icon: 'account-group',
        access: [Role.ADMIN],
      },
      {
        to: Routes.DEVICE_MANAGERS,
        icon: 'magic',
        access: [Role.ADMIN, Role.NETWORK],
      },
      {
        to: Routes.INVITES,
        icon: 'invite-group',
      },
      {
        to: Routes.DEMO,
        icon: 'beta',
        access: [Role.ADMIN, Role.DEMO],
      },
    ];

    const thirdSection: NavMenuItemProps[] = [
      {
        placement: POPPER_PLACEMENT.R_START,
        icon: 'account',
        children: (
          <FlexGroup direction="column" align="flex-start">
            <Button
              icon="account-round"
              fullWidth
              className={accountMenuButtonStyles}
              element="a"
              onClick={handleClickAccount}
            >
              {t('menu.my_account')}
            </Button>
            <Button icon="exit" fullWidth className={accountMenuButtonStyles} element="a" onClick={handleClickLogout}>
              {t('menu.exit')}
            </Button>
          </FlexGroup>
        ),
      },
    ];

    return [firstSection, thirdSection]
      .map((section) => section.filter(({ access }) => !access || validateAccess(access)))
      .filter((section) => section.length !== 0);
  }, [handleClickAccount, handleClickLogout, t, validateAccess]);

  const menuBottom = useMemo<NavMenuItems>(
    () => [
      [
        {
          icon: 'frame',
          className: css`
            transform: rotateY(180deg);
          `,
          onClick: () => {
            handleCollapse();
          },
        },
      ],
    ],
    [handleCollapse]
  );

  return (
    <>
      <SideBar align="left" collapsed={collapsed} onCollapse={handleCollapse} buttonContent={<Icon type="frame" />}>
        <FlexItem
          shrink={0}
          className={css`
            margin-bottom: 2.5rem;
          `}
        >
          <Link to={Routes.HOME}>
            <Icon type="round-logo" size="xl" />
          </Link>
        </FlexItem>
        <NavMenu items={menu} />
        <FlexGroup
          direction="column"
          className={css`
            height: 100%;
          `}
          justify="end"
        >
          <NavMenu items={menuBottom} />
        </FlexGroup>
      </SideBar>
      <StyledPageContainer $collapsed={collapsed} $align="left">
        <Page>{children}</Page>
      </StyledPageContainer>
    </>
  );
};

const Page = memo<PropsWithChildren>(function Page({ children }) {
  return <Suspense fallback={<PageLoader />}>{children}</Suspense>;
});

export const StyledPageContainer = styled.div<{ $collapsed?: boolean; $align?: 'left' | 'right' }>(
  ({ $collapsed, $align }) => `
    ${
      !$collapsed &&
      $align &&
      `& {
        padding-${$align}: ${getSizeRem(64)};
      }`
    }
  `
);
