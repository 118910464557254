import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';

import { Routes } from '@/routes';
import { useDispatch, useSelector, userActions, userSelectors } from '@/store';
import { PageLoader } from '@/components/Core';
import { useHideLoader } from '@/hooks';

export default function SignOut() {
  useHideLoader();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(userSelectors.getAuthenticate);

  useEffect(() => {
    if (isAuthenticated) dispatch(userActions.logoutAsync());
  }, [dispatch, isAuthenticated]);

  return isAuthenticated ? <PageLoader /> : <Navigate to={Routes.HOME} replace />;
}
