import { useEffect, useMemo, useState } from 'react';

import { BREAKPOINTS } from '@/components/Core';

type windowSize = {
  width: number;
  height: number;
};

export const useWindowSize = (): windowSize => {
  const [windowSize, setWindowSize] = useState<windowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [setWindowSize]);
  return windowSize;
};

export const useIsTablet = (): boolean => {
  const { width } = useWindowSize();
  const isTablet = useMemo(() => width <= BREAKPOINTS.lg, [width]);
  return isTablet;
};

export const useIsMobile = (): boolean => {
  const { width } = useWindowSize();
  const isMobile = useMemo(() => width <= BREAKPOINTS.sm, [width]);
  return isMobile;
};
