import { ChangeEvent, FC } from 'react';
import styled from '@emotion/styled';

import { Spacing, getScrollStyles, getSizeRem } from '../../Theme';
import { CheckboxField, Toggle } from '@/components/Core';

type Option = { id: string | number; name: string };
export interface MultiSelectOptionsProps {
  selectedValue: string[];
  options: Option[];
  hasSelectAll?: boolean;
  handleToggle?: (value: boolean) => void;
  isAllSelected: boolean;
  handleCheck: (event: ChangeEvent<HTMLInputElement>, option: Option) => void;
  py: Spacing;
  maxHeight?: number;
  pyLabel?: Spacing;
}

const StyledWrapper = styled.div(
  ({ theme }) => `
  & p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: ${getSizeRem(theme.sizes.m)};
    padding-right: ${getSizeRem(theme.sizes.l)};
    padding-bottom: ${getSizeRem(theme.sizes.base / 2)};
    font-size: ${getSizeRem(theme.fontSizes.m)};
  }
  `
);

const StyledList = styled.ul<{ hasSelectAll: boolean; $py: Spacing; $maxHeight?: number; $pyLabel?: Spacing }>(
  ({ $py, theme, hasSelectAll, $maxHeight, $pyLabel = 's' }) => `
    list-style: none;
    padding-block: ${getSizeRem(theme.sizes[$py])};
    border-bottom: ${hasSelectAll ? `1px solid ${theme.palette.primary[50]}` : 'none'};
    ${
      $maxHeight
        ? `max-height: ${getSizeRem($maxHeight)};
    overflow: auto;`
        : ''
    }
    ${getScrollStyles(theme)}
    label {
      padding-block: ${getSizeRem(theme.sizes[$pyLabel] / 2)};
      line-height: inherit;
    }
  `
);

export const MultiSelectOptions: FC<MultiSelectOptionsProps> = ({
  selectedValue,
  options,
  handleCheck,
  hasSelectAll = false,
  handleToggle,
  isAllSelected = false,
  py,
  maxHeight,
  pyLabel,
}) => {
  return (
    <StyledWrapper>
      <StyledList hasSelectAll={hasSelectAll} $py={py} $maxHeight={maxHeight} $pyLabel={pyLabel}>
        {options.map(({ id, name }) => (
          <li key={id}>
            <CheckboxField
              value={id}
              onChange={(event) => handleCheck(event, { id, name })}
              checked={selectedValue.includes(id.toString())}
            >
              {name}
            </CheckboxField>
          </li>
        ))}
      </StyledList>
      {hasSelectAll && !!options.length && (
        <p>
          {isAllSelected ? 'Відмінити всі' : 'Обрати всі'}
          <Toggle value={isAllSelected} onChange={handleToggle} />
        </p>
      )}
    </StyledWrapper>
  );
};
