import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { State } from './types';
import { getPoints } from './thunks';

const initialState: State = {
  points: [],
  items: [],
  page: 1,
  limit: 100,
  isLoading: true,
};

const getItems = (points: Types.EventPoint[], page: number, limit: number) =>
  points.slice((page - 1) * limit, page * limit);

const slice = createSlice({
  name: 'demo',
  initialState,
  reducers: {
    clear: () => initialState,
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
      state.items = getItems(state.points, action.payload, state.limit);
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
      state.items = getItems(state.points, state.page, action.payload);
    },
    updateItems: (state) => {
      const total = Math.ceil(state.points.length / state.limit);
      if (total < state.page) {
        state.page = Math.max(state.page - 1, 1);
      }
      state.items = getItems(state.points, state.page, state.limit);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPoints.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPoints.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          if (action.payload.points) {
            state.points = action.payload.points.reverse();
            state.items = getItems(state.points, state.page, state.limit);
          }
        }
      })
      .addCase(getPoints.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  getPoints,
};

export default slice.reducer;
