import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { LoginPayload, State } from './types';
import { getCurrent, loginAsync, logoutAsync } from './thunks';

const initialId = localStorage.getItem('user_id');

const initialState: State = {
  token: localStorage.getItem('token'),
  id: initialId ? Number(initialId) : null,
  user: null,
  isLoading: true,
  error: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<LoginPayload>) => {
      state.token = action.payload.token;
      state.id = action.payload.id;
    },
    logout: (state) => {
      state.token = null;
      state.id = null;
      state.user = null;
      state.isLoading = true;
    },
    update: (state, action: PayloadAction<Types.User>) => {
      state.user = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.id = action.payload.user_id;
        state.token = action.payload.token;
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.error = action.payload;
      });

    builder
      .addCase(getCurrent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCurrent.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLoading = false;
      })
      .addCase(getCurrent.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  loginAsync,
  logoutAsync,
  getCurrent,
};

export default slice.reducer;
