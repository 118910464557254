import { Text } from '@/components/Core';
import { PageCenteredContent } from '@/components/Layouts/PageCenteredContent';
import { WelcomeLayout } from '@/components/Layouts/WelcomeLayout';
import { useHideLoader, useTitle } from '@/hooks';
import { useSelector, userSelectors } from '@/store';

export default function NotFound() {
  useHideLoader();
  const isAuthenticated = useSelector(userSelectors.getAuthenticate);
  if (isAuthenticated) {
    return <AuthenticatedContent />;
  }
  return (
    <WelcomeLayout>
      <Content />
    </WelcomeLayout>
  );
}

const AuthenticatedContent = () => {
  return (
    <PageCenteredContent>
      <Content />
    </PageCenteredContent>
  );
};

const Content = () => {
  const { t } = useTitle('page.notFound.title');
  return (
    <Text weight="bold" size="xl">
      {t('page.notFound.title')}
    </Text>
  );
};
