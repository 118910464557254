import styled from '@emotion/styled';

import { NavMenuProps } from './types';
import { NavMenuGroup } from './NavMenuGroup';

export const NavMenu = ({ items: groups }: NavMenuProps) => {
  return (
    <StyledContainer>
      {groups.map((items, index) => (
        <NavMenuGroup items={items} key={`nav-group-${index}`} />
      ))}
    </StyledContainer>
  );
};

const StyledContainer = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
